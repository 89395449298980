import React, { useState, useEffect } from "react";
import NavItems from "./NavItem";

const NavList = ({ gmarkers}) => {
  const [actived, setActived] = useState(true);
  const [navClassName, setNavClassName] = useState("nav");
  const [type1, setType1] = useState(false);
  const [type2, setType2] = useState(false);
  const [type3, setType3] = useState(false);
  const [type4, setType4] = useState(false);
  const [type6, setType6] = useState(true);
  const [type7, setType7] = useState(false);
  const [type0, setType0] = useState(true)

  useEffect(() => { 
    markerHandler();
  }, [type1, type2, type3, type4, type6, type7]);

  const markerHandler = () => {
    gmarkers.map((marker) => {
      if (showMarkerCategory(marker.category)) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
      return null;
    });
  };

  const showMarkerCategory = (category) => {
    switch (category) {
      case 1:
        return type1;
      case 2:
        return type2;
      case 3:
        return type3;
      case 4:
        return type4;
      case 6:
        return type6;
      case 7:
        return type7;
      default:
        return true;
    }
  };

  function changeHandler(e) {
    e.preventDefault();
    setActived(!actived);
    if (actived) {
      setNavClassName("nav actived");
    } else {
      setNavClassName("nav");
    }
  }
  const boxClicked = (category) => {
    setCategories(category);
  };

  const setCategories = (category) => {
    switch (category) {
      case "1":
        return setType1(!type1);
      case "2":
        return setType2(!type2);
      case "3":
        return setType3(!type3);
      case "4":
        return setType4(!type4);
      case "6":
        return setType6(!type6);
      case "7":
        return setType7(!type7);
      default:
    }
  };

  return (
    <nav className={navClassName} style={{ top: "20%" }}>
      <a
        href="#"
        title="篩選"
        className="btn map-btn"
        onClick={changeHandler}
      />
      <div>
        <ul className="reset">
          <NavItems
            className="g-checkbox mg-0 filter f-1"
            defaultCheck={true}
            name={"電梯大樓 / 華廈"}
            functionHandler={boxClicked}
            category="6"
          />
          <NavItems
            className="g-checkbox mg-0 filter f-2"
            defaultCheck={false}
            name={"無電梯公寓"}
            functionHandler={boxClicked}
            category="1"
          />
          <NavItems
            className="g-checkbox mg-0 filter f-3"
            defaultCheck={false}
            name={"套房"}
            functionHandler={boxClicked}
            category="7"
          />
          <NavItems
            className="g-checkbox mg-0 filter f-4"
            defaultCheck={false}
            name={"別墅 / 透天厝"}
            functionHandler={boxClicked}
            category="2"
          />
          <NavItems
            className="g-checkbox mg-0 filter f-5"
            defaultCheck={false}
            name={"店面 (店鋪)"}
            functionHandler={boxClicked}
            category="3"
          />
          <NavItems
            className="g-checkbox mg-0 filter f-6"
            defaultCheck={false}
            name={"辦公商業大樓"}
            functionHandler={boxClicked}
            category="4"
          />
        </ul>
      </div>
    </nav>
  );
};

export default NavList;
