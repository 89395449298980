import React, { useState, useEffect } from "react";
import AddrTwZipCode from "../../Utils/AddTwZipCode/AddrTwZipCode";
import { getLocation } from "../Fetch/GetLocation";
import Captcha from "../../Utils/Captcha";
import getLatLng from "../Fetch/GetLatLng";
import AddrWarning from "../AdvanceEstimate/AddrWarning";
import { fetchCaptcha, validateCaptchaCode } from "../Fetch/fetchBackend";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";
const Container1 = ({ data, dataHandler, setMapCenter, setLoading }) => {
  const [captcha, setCaptcha] = useState();
  const [code, setCode] = useState("");
  const [alert, setAlert] = useState({
    zipcode: false,
    addr: false,
    code: false,
  });
  const [addrWariningOpen, setAddrWarning] = useState(false);
  const warning = () => {
    setAddrWarning(!addrWariningOpen);
    setLoading(false);
  };
  const { trackPageView } = useMatomo();
  useEffect(() => {
    fetchCaptcha(setCaptcha);
    trackPageView({
      documentTitle: "填寫實價登錄資訊", // optional
      href: hashReplacer(window.document.location.href), // optional
    })
  }, []);

  const captchaHandler = () => {
    fetchCaptcha(setCaptcha);
  };

  const alertCheck = (alertItem) => {
    const alertList = { ...alert };

    alertList.zipcode = data.zipcode === "" ? true : false;
    alertList.addr = data.addr === "" || data.addr === undefined ? true : false;
    alertList.code = code === "" || code.length < 6;

    if (alertItem !== undefined) alertList[alertItem] = true;
    setAlert(alertList);

    return alertList.addr || alertList.zipcode || alertList.code;
  };
  const locationHandler = () => {
    getLocation(addrHandler);
  };
  const requestHandler = async () => {
    const isAlerted = alertCheck();
    if (isAlerted) {

    } else {
      var resultString = await validateCaptchaCode(code, setCaptcha);
      setCode("");

      var result = resultString === "true";
      if (result) {
        setLoading(true);
        getLatLng(data, setMapCenter, warning);
      } else {
        alertCheck("code");
      }
    }
  };
  const codeHandler = (e) => {
    const code = e.target.value;
    setCode(code);
  };

  const addrHandler = (data) => {
    const e = { target: {} };
    e.target.name = "addr";
    e.target.value = { ...data };

    dataHandler(e);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h4 className="h4">請點選我的位置 或 輸入地址</h4>
          <div className="form-group">
            <button
              type="button"
              className="btn nbtn-myAddress"
              onClick={locationHandler}
            >
              <i className="fas fa-map-marker-alt"></i> 我的位置
            </button>
            <AddrTwZipCode setData={addrHandler} data={data} alert={alert} />
          </div>
        </div>
        <Captcha
          captcha={captcha}
          captchaHandler={captchaHandler}
          code={code}
          setCode={codeHandler}
          alert={alert}
        />
      </div>
      <div className="btn-group">
        <a
          id="target-submit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            requestHandler();
          }}
        >
          <div className="btn btn-search a-btn btn-track">查詢</div>
        </a>
      </div>
      {addrWariningOpen && <AddrWarning closeWarning={warning} />}
    </>
  );
};

export default Container1;
