import React, { useState, useEffect } from "react";
import Map, { Marker } from "../../../Utils/GoogleMap/Map2";
import NavList from "./NavItems/NavList";
import BulidingPhoto from "../../TypePhoto";
import { point, TrueMap } from "../../../../StaticResource";
import {
  fetchEstimatemegabranch,
  fetchAdvancePoi,
} from "../../Fetch/fetchBackend";
import { formatFilter, ObjFilterHandler } from "../../../Utils/Filter/Filter";
const MapContainer = ({ addrData, data, mapCenter, advanceEstimateData, counts }) => {

  const [gmarkers, setGmarkers] = useState([]);
  const [estBranch, setEstBranch] = useState([]);
  const [advancePoi, setAdvancePoi] = useState([]);

  useEffect(() => {
    fetchEstimatemegabranch(mapCenter, setEstBranch);
    fetchAdvancePoi(mapCenter, setAdvancePoi);
  }, []);
  if (advanceEstimateData === [] || estBranch === [] || advancePoi === [])
    return <></>;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="g-map map-new" style={{ height: "700px" }}>
          <Map
            id="map"
            options={options(mapCenter)}
            gmarkers={gmarkers}
            mapCenter={mapCenter}
            showCenter={true}
            style={mapStyle}
            showInfoWindow={true}
            addr={addrData.county + addrData.district + addrData.addr}
          >
            {advanceEstimateData.map((point, i) => {
              const category = point.B_TYPE_INT === 5 ? 6 : point.B_TYPE_INT;
              const visible =
                category === Number(data.B_TYPE_INT) ? true : false;
              return (
                <Marker
                  key={i}
                  lat={point.LATITUDE}
                  lng={point.LONGITUDE}
                  category={category}
                  title={point.B_TYPE}
                  visible={visible}
                  image={BulidingPhoto(point.B_TYPE_INT)}
                  setInfowindow={true}
                  distance={point.distance}
                  infoContent={infowindowFormat(point)}
                />
              );
            })}

            {advancePoi.map((point, i) => {
              const category = getCategory(point.ITEM);
              if (category) {
                return (
                  <Marker
                    key={i}
                    lat={point.LAT}
                    lng={point.LON}
                    category={category}
                    visible={true}
                    image={BulidingPhoto(category)}
                    setInfowindow={true}
                    infoContent={poiInfoWindow(point)}
                    distance={point.distance}
                  />
                );
              } else {
                return <div key={i}></div>;
              }
            })}
            {estBranch.map((data, i) => {
              return (
                <Marker
                  key={i}
                  lat={data.latitude}
                  lng={data.longitude}
                  title={data.B_TYPE}
                  category={0}
                  visible={true}
                  image={BulidingPhoto("branch")}
                  setInfowindow={true}
                  infoContent={branchInfoWindow(data)}
                  distance={0}
                />
              );
            })}
          </Map>
          <NavList gmarkers={gmarkers} data={data} counts={counts} />
        </div>
      </div>
    </div>
  );
};

export default MapContainer;

const mapStyle = {
  width: "100%",
  height: "90%",
  position: "relative",
  overflow: "hidden",
};

const options = (mapCenter) => {
  if (mapCenter) {
    return {
      center: TrueMap
        ? { lat: mapCenter.lat(), lng: mapCenter.lng() }
        : { lat: point.lat, lng: point.lng },
      mapTypeControl: false,
      scaleControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      },
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP,
      },
      streetViewControl: false,
      panControl: false,
      zoom: 18,
      //zoom: 16 比例尺為 100 m，地圖全長 2500 m，半徑顯示為 1250 m
      //zoom: 17 比例尺為  50 m，地圖全長 1250 m，半徑顯示為  625 m
      //zoom: 18 比例尺為  20 m，地圖全長  500 m
    };
  }
};

const infowindowFormat = (data) => {
  return (
    '<h3 class="popover-header">' +
    data.B_TYPE +
    '</h3><div class="popover-body"><ul class="reset"><li>單價：' +
    formatFilter(data.UNIT_PRICE_P, "housePrice") +
    "萬元/坪</li><li>總坪數：" +
    data.B_AREA_P +
    "坪</li><li>屋齡：" +
    data.B_AGE +
    "年</li><li>移轉樓層：" +
    data.LEVEL +
    "</li><li>總樓層數：" +
    data.FLOORS +
    "</li><li>交易日期：" +
    data.TRANS_YMD +
    "</li></ul></div>"
  );
};

const branchInfoWindow = (data) => {
  return (
    '<div class=""><div class="arrow"></div><h3 class="popover-header">' +
    data.branch_name +
    '</h3><div class="popover-body"><ul class="reset"><li>Tel：' +
    data.fax +
    "</li></ul></div></div>"
  );
};

const getCategory = (Item) => {
  switch (Item) {
    case "市場":
      return 11;
    case "托嬰中心":
    case "幼教":
      return 9;

    case "殯葬用品店":
    case "殯葬設施":
    case "宗教場所":
    case "寺廟神壇":
    case "垃圾場":
    case "焚化爐":
    case "發電廠":
    case "變電所":
      return 18;
    default:
      return false;
  }
};

const poiInfoWindow = (data) => {
  return (
    '<div class=""><div class="arrow"></div><h3 class="popover-header">' +
    data.ITEM +
    '</h3><div class="popover-body"><ul class="reset"><li>' +
    data.NAME +
    "</li></ul></div></div>"
  );
};

const getSubmitObj = (data, mapCenter) => {
  return ObjFilterHandler({
    lat: mapCenter.lat(),
    lon: mapCenter.lng(),
    B_TYPE_INT: data.B_TYPE_INT,
    B_AGE: data.B_AGE,
    LEVEL: data.LEVEL,
  });
};
