import React from "react";
import ConditionUnit from "./ConditionUnit";
import { scrollToDestMore } from "../../Utils/Scroll/ScrollUtils";
const ConditionContainer = ({ data, obj, setObj, calculator }) => {
  const loanObj = {
    loan: {
      name: "loan",
      unit: "萬",
      step: 1,
      min: 1,
      max: data.loan,
      base: obj.loan,
      title: "貸款金額",
      fixed: "0",
    },
    loan_years: {
      name: "loan_years",
      unit: "年",
      step: 1,
      min: 1,
      max: 30,
      base: obj.loan_years,
      title: "貸款期限",
      fixed: "0",
    },
    time: {
      name: "time",
      unit: "年",
      step: 1,
      min: 0,
      max: 3,
      base: obj.time,
      title: "寬限期",
      fixed: "0",
    },
    rate: {
      name: "rate", 
      unit: "%",
      step: 0.001,
      min: 0.100,
      max: 15.000,
      //202208 Vulnerability Project Modify -- begin
      base: !isNaN(obj.rate) ? new Number(obj.rate).toFixed(3) : obj.rate,
      title: "利率", 
      //Change to 3 decimal places
      //fixed: "2",
      fixed: "3",
      //202208 Vulnerability Project Modify -- end
    },
  };

  return (
    <div className="container count-area">
      <div className="row">
        <ConditionUnit obj={obj} item={loanObj.loan} setObj={setObj} />
        <ConditionUnit obj={obj} item={loanObj.loan_years} setObj={setObj} />
        <ConditionUnit obj={obj} item={loanObj.time} setObj={setObj} />
        <ConditionUnit obj={obj} item={loanObj.rate} setObj={setObj} />
      </div>
      <div className="btn-group">
        <a
          href="//#endregion"
          title="我要試算"
          className="btn btn-transform btnHight"
          name="target"
          onClick={(e) => {
            e.preventDefault();
            calculator();
            scrollToDestMore("target",1300);
          }}
        >
          我要試算
        </a>
      </div>
    </div>
  );
};

export default ConditionContainer;
