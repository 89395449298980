export const formatFilter = (data, type) => {
  return data === "" ? "" : filterFormat(data, type);
};

const filterFormat = (data, type) => {
  let finalData;
  let value = Number(data);
  switch (type) {
    case "addr":
      finalData = data.substring(0, 100);
      break;
    case "housePrice":
      finalData = thousandFormatter(value.toFixed(1));
      break;
    case "age":
      finalData = value >= 100 ? 99 : value < 0 ? 1 : value.toFixed(0);
      break;
    case "area":
      finalData =
        value >= 1000000
          ? 999999.99
          : value < 0
          ? 1
          : Math.round(value * 100) / 100;
      break;
    case "level":
    case "parkingNum":
      finalData = value >= 100 ? 99 : value < 0 ? 1 : value.toFixed(0);
      break;
    case "income":
    case "loan":
    case "totalPrice":
      finalData = value >= 1000000000 ? 999999999 : value<0? 1: value.toFixed(0);
      break;
    case "childNum":
      finalData = value >= 1000 ? 999 : value<0? 0: value.toFixed(0);
      break;
    case "name":
      finalData = data.substring(0, 10);
      break;
    case "rate":
      //202208 Vulnerability Project Modify -- begin 
      // finalData = value >= 15 ? 14.99 : value<0? 1: Math.round(value * 100) / 100;
      finalData = value >= 15 ? 14.999 : value< 0 ? 1.000: (Math.round(value * 1000) / 1000).toFixed(3);
      //202208 Vulnerability Project Modify -- end
      break;
     case "normalPay":
      //202208 Vulnerability Project Modify -- begin  
      finalData = Number(value).toFixed(2);
      //202208 Vulnerability Project Modify -- end
      break;
    case "thousand":
      finalData = thousandFormatter(value);
      break;
    case "cleanThousand":
      finalData = thousandFormatter(value.toFixed(0));
      break;
    default:
      return console.log("未設定");
  }

  return finalData === "NaN" ? 0 : finalData;
};

export const ObjFilterHandler = (object) => {
  const newObj = {};
  Object.keys(object).map((item) => {
    if (item === "B_AGE" || item === "user_age") {
      newObj[item] = formatFilter(object[item], "age");
    } else if (item === "B_AREA_P" || item === "parking_p") {
      newObj[item] = formatFilter(object[item], "area");
    } else if (item === "FLOORS" || item === "LEVEL") {
      newObj[item] = formatFilter(object[item], "level");
    } else if (item === "parking_qty") {
      newObj[item] = formatFilter(object[item], "parkingNum");
    } else if (item === "user_children") {
      newObj[item] = formatFilter(object[item], "childNum");
    } else if (item === "user_income") {
      newObj[item] = formatFilter(object[item], "income");
    } else if (item === "estimate_total_price_av" || item === "total_price") {
      newObj[item] = formatFilter(object[item], "totalPrice");
    } else if (item === "loan_balance" || item === "top_loan_credit") {
      newObj[item] = formatFilter(object[item], "loan");
    } else if (item === "c_name") {
      newObj[item] = formatFilter(object[item], "name");
    } else if (item === "addr") {
      newObj[item] = formatFilter(object[item], "addr");
    } else if (item === "UNIT_PRICE_P") {
      newObj[item] = formatFilter(object[item], "housePrice");
    } else {
      newObj[item] = object[item];
    }
  });

  return newObj;
};

export const eventDataFiltedHandler = (name, value) => {
  const obj = {};
  obj[name] = value;
  return ObjFilterHandler(obj);
};

const thousandFormatter = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
