import { SpringBootServer, point, TrueMap } from "../../../StaticResource";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";
import { formatFilter } from "../../Utils/Filter/Filter";
function fetchAdvancePrice(submitObj, callBack, setStatus) {
  var url = `${SpringBootServer}/estimate/advance/result`;
  const _token = getItemFromSessionStorage("_token");

  submitObj._token = _token;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(submitObj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res.json();
      }
    })
    .then((data) => {
      storeItemInSessionStorage(
        "estimateTotalPriceAv",
        formatFilter(Number({ ...data }.av750)*Number({ ...data }.bAreaP), "totalPrice")
      );
      callBack(data);
    })
    .catch((err) => {
      alert("驗證過期，請重新領取驗證碼並再次驗證");
      setStatus("1");
    });
}

function fetchAdvanceEstimateData(submitObj, setMarkerArray, setCounts) {
  const _token = getItemFromSessionStorage("_token");
  var url = TrueMap
    ? `${SpringBootServer}/estimatedata/advance?lat=${submitObj.lat}&lon=${submitObj.lon}&B_TYPE_INT=${submitObj.B_TYPE_INT}&B_AGE=${submitObj.B_AGE}&LEVEL=${submitObj.LEVEL}`
    : `${SpringBootServer}/estimatedata/advance?lat=${point.lat}&lon=${point.lng}&B_TYPE_INT=5&B_AGE=15&LEVEL=5`;

  url += `&_token=${_token}`;

  fetch(encodeURI(url))
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    })
    .then((data) => {
      setMarkerArray({ ...data }.data);
      setCounts({ ...data }.count);
    })
    // .catch((err) => alert(err));

  return null;
}

const fetchAdvancePoi = (mapCenter, callBack) => {
  const url = TrueMap
    ? `${SpringBootServer}/estimatedata/advancepoi?lat=${mapCenter.lat()}&lon=${mapCenter.lng()}`
    : `${SpringBootServer}/estimatedata/advancepoi?lat=25.0323588&lon=121.5219754`;
  fetch(url)
    .then((res) => res.json())
    .then((datas) => callBack(datas));
};

const fetchUserRecord = (callBack) => {
  // 廢止不使用
  // const _token = getItemFromSessionStorage("_token");
  // const url = `${SpringBootServer}/estimate/record4?_token=${_token}`;

  const userData = getItemFromSessionStorage("userData");
  const url = `${SpringBootServer}/estimate/record4s?phone_or_email=${userData}`;

  fetch(url)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    })
    .then((datas) => callBack(datas))
    .catch((err) => {
      console.log(err);
    });
};

export {
  fetchAdvanceEstimateData,
  fetchAdvancePoi,
  fetchAdvancePrice,
  fetchUserRecord,
};
