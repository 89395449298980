import React, { useState, useEffect } from "react";

import DataRow from "./DataRow";
import DataTable from "./DataTable";
import FootNote from "../FootNote/FootNote";
import BottomLink from "./BottomLink";
import { fetchUserRecord } from "../Fetch/fetchAdvance";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";
const CalculationTable = ({ applyHandler, contactOpenHandler }) => {
  const [tabledata, setTableData] = useState([]);
  const { trackPageView } = useMatomo();
  useEffect(() => {
    fetchUserRecord(setTableData);
    trackPageView({
      documentTitle: "查看一周試算結果", // optional
      href: `${hashReplacer(window.document.location.href)}/result`, // optional
    })
  }, []);

  if (tabledata.length <= 0) return <></>;

  return (
    <>
      <main className="main-wrap">
        <div className="main-content">
          <div className="title-trial-calculation title-inner">
            <h2>為您呈現一週的試算結果</h2>
          </div>
          <div className="container inner-content">
            <div className="row calculation-content">
              <p className="red">
                本網站預估價格係採平均估計值，僅為參考價格，實際貸款成數與金額仍以本行實地勘估所作之估價報告書及最終審核結果為準。
              </p>
              <div className="desktop-table table-block">
                <table>
                  <tbody>
                    <tr>
                      <th rowSpan="2" width="10%">
                        查詢日期
                      </th>
                      <th rowSpan="2" width="17%">
                        房屋類型
                      </th>
                      <th rowSpan="2" width="28%">
                        地址
                      </th>
                      <th rowSpan="2" width="10%">
                        坪數
                      </th>
                      <th colSpan="3" width="25%">平均每坪單價(萬)</th>
                      <th rowSpan="2" width="20%">
                        預估總價(萬)
                        <br />
                        不含車位
                      </th>
                    </tr>
                    <tr>
                      <th className="subth">
                        500公尺內
                        <br />
                        實價登錄
                      </th>
                      <th className="subth">
                        1000公尺
                        <br />
                        內實價登錄
                      </th>
                      <th className="subth">
                        預估單價
                        <br />
                        不含車位
                      </th>
                    </tr>
                    <DataRow datas={tabledata} />
                  </tbody>
                </table>
                <font style={{paddingLeft:"5px"}}>表格內*號表示「近一年查無實價登錄資訊」</font>
              </div>
              <div className="mobile-table table-block">
                <DataTable datas={tabledata} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      >
        <BottomLink />
      </FootNote>
    </>
  );
};

export default CalculationTable;
