export const validater = (type) => {
  switch (type) {
    case "phone":
      return /^09\d{8}$/;
    case "email":
      return /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/;
    case "code":
      return /^\d$/;
    //202208 Vulnerability Project Modify -- begin   
    case "verification_code": 
      return /^\d$/;
    //202208 Vulnerability Project Modify -- end   
    default:
      return;
  }
};

export const validateData = (data, type) => {
  return data.match(validater(type));
};	