import { SpringBootServer } from "../../../StaticResource";
import getLatLng from "./GetLatLng";
const fetchContact = async (submitObj, setAlertType) => {
  const callFetch = (mapCenter) => {
    submitObj.lat = mapCenter !== undefined ? mapCenter.lat() : 0.0;
    submitObj.lon = mapCenter !== undefined ? mapCenter.lng() : 0.0;

    fetchdata(submitObj, setAlertType);
  };

  getLatLng(submitObj, callFetch);
};

const fetchdata = (submitObj, setAlertType) => {
  const url = `${SpringBootServer}/customer/add`;
  fetch(url, {
    method: "POST",
    body: JSON.stringify(submitObj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  }).then((res) => {
    if (res.ok) {
      setAlertType("thankWindow");
    }
  });
};

export { fetchContact };
