import React, { useState, useEffect } from "react";

import iconApply1 from "../../img/icon-apply1.svg";
import iconApply2 from "../../img/icon-apply2.svg";
import iconApply3 from "../../img/icon-apply3.svg";
import { fetchPuw } from "../MainSwitch/Fetch/fetchBackend";
import MarkDown from "../Utils/MarkDownUtils/MarkDown";
import { contactEmail } from "../../StaticResource";

const ApplyPage = ({ setClose }) => {
  const [puw, setPuw] = useState();

  useEffect(() => {
    fetchPuw(setPuw);
  }, []);

  if (!puw) return <></>;
  const applyPage = { ...puw }.applyPage;
  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden", marginTop:"65px" }}
      >
        <div
          id="Apply"
          className="mfp-container mfp-s-ready mfp-inline-holder"
          style={{ zIndex: "4" }}
        >
          <div className="mfp-content">
            <div
              id="Apply"
              className="popup a-popup-group survey-group mfp-with-zoom As"
              style={{ marginTop:"65px" }}
            >
              <h2>我是兆豐房貸客戶</h2>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="As-width">
                    <div className="As-imgMiddle">
                      <img src={iconApply1} alt="我是兆豐網路銀行會員" />
                    </div>
                    <h5 className="mb-3">我是兆豐網路銀行會員</h5>

                    <p style={{height:"80px", marginLeft:"-10px"}}>
                      <MarkDown content={applyPage.isMemberString}>{applyPage.isMemberString}</MarkDown>
                    </p>

                    <div className="btn-group">
                      <a
                        href="https://ebank.megabank.com.tw/iibcontent.jsp"
                        title="我要申請"
                        className="btn btn-search sm btnHight"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        我要申請
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 d-none d-lg-block"
                  id="As-hidden"
                >
                  <div className="As-width">
                    <div className="As-imgMiddle">
                      <img src={iconApply2} alt="我有兆豐晶片金融卡" />
                    </div>
                    <h5 className="mb-3">
                      我有兆豐晶片金融卡
                      <br />
                      (登入網路ATM)
                    </h5>
                    <p style={{height:"80px", marginLeft:"-10px"}}>
                      <MarkDown content={applyPage.hasCreditCardString}>{applyPage.hasCreditCardString}</MarkDown>
                    </p>
                    <div className="btn-group">
                      <a
                        href="https://ebank.megabank.com.tw/global2/as/as04/PAS40_Login.faces"
                        title="我要申請"
                        className="btn btn-search sm btnHight"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        我要申請
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="As-width">
                    <div className="As-imgMiddle">
                      <img src={iconApply3} alt="請專人與我聯絡" />
                    </div>
                    <h5 className="mb-3">請專人與我聯絡</h5>
                    <p style={{height:"80px", marginLeft:"-10px"}}>
                      <MarkDown content={applyPage.contactString1}>{applyPage.contactString1}</MarkDown>
                    </p>
                    <div className="btn-group">
                      <a
                        href={contactEmail}
                        title="與我聯絡"
                        className="btn btn-search sm btnHight"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        與我聯絡
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h2 className="mt-5">我還不是兆豐房貸客戶</h2>
              <div className="row">
                <div className="col-lg-12">
                  <h5 className="mt-4 mb-1">請專人與我聯絡</h5>
                  <p className="text-center">
                    {applyPage.contactString2}
                  </p>
                  <a
                    href={contactEmail}
                    title="與我聯絡"
                    className="btn btn-search sm btnHight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    與我聯絡
                  </a>
                </div>
              </div>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={() => {
                  setClose();
                }}
                style={{ color: "black" }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyPage;
