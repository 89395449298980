export const service = {
  name: "線上客服",
  title: "兆豐線上客服(另開視窗)",
  url:
    "https://megarobot.megabank.com.tw/?chatwebId=20210727135730361&traceTag=W6",
};
export const footer = [
  {
    name: "服務據點",
    href: "https://www.megabank.com.tw/about/mega-intro/locations",
  },
  {
    name: "隱私權保護聲明",
    href:
      "https://www.megabank.com.tw/about/announcement/data-protection/privacy",
  },
  {
    name: "Cookies聲明",
    href:
      "https://www.megabank.com.tw/about/announcement/web-security/cookies-notice",
  },
  {
    name: "引用本行資訊說明",
    href: "https://www.megabank.com.tw/about/announcement/web-security/ref",
  },
];

export const useOnlineLoanPage = true;
export const onlineLoanPageUrl = "https://onlineloan.megabank.com.tw/#/houseloan";

const address = window.location.hostname;
const port = window.location.port;
let ip;
let protocol = window.location.protocol;
if (address === "localhost" && (port === "3000" || port === "5000")) {
  ip = `address:8081`;
} else {
  ip = `${address}:${port}`;
}

export const contextPath = window.location.pathname.substring(
  0,
  window.location.pathname.indexOf("/", 2)
);

export const SpringBootServer = `${protocol}//${ip}/Mgnt`;
export const MegabankServer = "https://estimation.megabank.com.tw/";

export const point = { lat: 25.0323588, lng: 121.5219753999999 };

export const skipVerify = false;
export const TrueMap = true;

export const marqueeTime = 4000;
export const testMode = false;

export const validateTimes = 6;

export const show_other_building = true;

const day = 0;
const hours = 3;
const mins = 0;
const seconds = 0;
export const storageTimes = ((day * 24 + hours) * 60 + mins) * 60 + seconds;
export const localStoreByDate = false;

export const contactEmail =
  "https://www.megabank.com.tw/about/customer-service/contact?category=personal&service=house_loan_online_apply";


export const matomoHost =
  address === "estimation.megabank.com.tw"
    ? "https://webdba.megabank.com.tw"
    : "http://webdbacmstest.megabank.com.tw";
export const matomoPhp = `${matomoHost}/piwik/piwik.php`;
export const matomoTraceUrl = `${matomoHost}/piwik/piwik_event.js`;
