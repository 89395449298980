import React, { useEffect, useState } from "react";
import MapContainer from "./MapContainer";
import PriceBlock from "./PriceBlock";
import { fetchEstimateJson } from "../Fetch/fetchBackend";
import Warn from "../AdvanceEstimate/Warning";
import { scrollToDestMore } from "../../Utils/Scroll/ScrollUtils";
import { formatFilter } from "../../Utils/Filter/Filter";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";
const Container2 = ({
  addrData,
  mapCenter,
  resultOpen,
  resultHandler,
  setLoading,
}) => {
  const [data, setDatas] = useState();
  const [warning, setWarning] = useState(false);
  const { trackPageView } = useMatomo();
  useEffect(() => {
    fetchEstimateJson(getSubmitObj(addrData, mapCenter), priceHandler);
    resultHandler(false);
    setLoading(false);
    trackPageView({
      documentTitle: "查看實價登錄結果", // optional
      href: `${hashReplacer(window.document.location.href)}/result`, // optional
    })
  }, [mapCenter]);

  useEffect(() => {
    scrollToDestMore("hr", 1300);
  }, [data]);

  const priceHandler = (datas) => {
    const dataNames = Object.keys(datas);
    let openWarning = true;
    dataNames.map((name) => {
      const minValue = formatFilter(datas[name].min, "housePrice");
      if (minValue > 0) {
        openWarning = false;
        resultHandler(true);
        setDatas(datas);
        return null;
      }
    });
    if (openWarning) {
      resultHandler(false);
      setWarning(true);
    }
  };

  const closeWarning = () => {
    setWarning(!warning);
  };
  return (
    <>
      {resultOpen ? (
        <>
          <hr className="hr" name="hr" />
          <h4 className="h4">所選位置附近1000公尺實價登錄單價</h4>
          <div className="container">
            <PriceBlock datas={data} />
            <div className="result" style={{ display: "block" }}>
              <MapContainer
                mapCenter={mapCenter}
                addr={addrData.county + addrData.district + addrData.addr}
              />
            </div>
          </div>
        </>
      ) : warning ? (
        <Warn closeWarning={closeWarning} />
      ) : (
        ""
      )}
    </>
  );
};

export default Container2;

const getSubmitObj = (addrData, mapCenter) => {
  return {
    county: addrData.county,
    district: addrData.district,
    addr: addrData.addr,
    lat: mapCenter.lat(),
    lng: mapCenter.lng(),
  };
};
