import { SpringBootServer } from "../../../StaticResource";
import {
  getItemFromLocalStorage,
  storeItemInLocalStorage,
} from "../../Utils/BrowserStorageUtils";

const fetchPuw = async (callBack) => {
  let puw = getItemFromLocalStorage("puw");
  if (!puw) {
    const url = `${SpringBootServer}/puw/frontendget`;

    return await fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        storeItemInLocalStorage("puw", data);
        puw = { ...data };
        callBack(puw);
      });
  } else {
    callBack(puw);
  }
};

export { fetchPuw };
