import React, { useState, useEffect } from "react";
import AddrTwZipCode from "../../Utils/AddTwZipCode/AddrTwZipCode";
import { getLocation } from "../Fetch/GetLocation";
import Form from "./Form";
import getLatLng from "../Fetch/GetLatLng";
import AddrWarning from "./AddrWarning";
import { getItemFromSessionStorage } from "../../Utils/BrowserStorageUtils";
import { validateTimes } from "../../../StaticResource";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";


const Container1 = ({
  setAddr,
  data,
  addrData,
  dataHandler,
  setMapCenter,
  resultOpen,
  resultOpenHandler,
  setLoading,
}) => {
  const [alert, setFormAlert] = useState({
    zipcode: false,
    addr: false,
    B_AGE: false,
    B_AREA_P: false,
    FLOORS: false,
    LEVEL: false,
    parking_qty: false,
    parking_p: false,
  });

  const { trackPageView } = useMatomo();
  useEffect(()=>{
    trackPageView({
      documentTitle: "填寫房屋估價表單", // optional
      href: `${hashReplacer(window.document.location.href)}/form`, // optional
    });
  },[])
  const [addrWariningOpen, setAddrWarning] = useState(false);
  const warning = () => {
    setAddrWarning(!addrWariningOpen);
    setLoading(false);
  };
  const addrHandler = (address) => {
    let e = { target: { name: "addr", value: { ...address } } };
    setAddr(e);
  };
  const alertCheck = () => {
    let alertList = { ...alert };

    alertList.B_AGE = data.B_AGE === "" ? true : false;
    alertList.B_AREA_P = data.B_AREA_P === "" ? true : false;
    alertList.FLOORS = data.FLOORS === "" ? true : false;
    alertList.LEVEL =
      data.LEVEL_SELECT === "1" &&
      (data.LEVEL === "" || Number(data.LEVEL) > Number(data.FLOORS))
        ? data.B_TYPE_INT === "2"
          ? false
          : true
        : false;

    alertList.parking_qty =
      !(data.PARKING === "1") && data.parking_qty === "" ? true : false;
    alertList.parking_p =
      !(data.PARKING === "1") && data.parking_p === "" ? true : false;
    alertList.zipcode = addrData.zipcode === "" ? true : false;
    alertList.addr =
      addrData.addr === "" || addrData.addr === undefined ? true : false;

    setFormAlert(alertList);

    return (
      alertList.addr ||
      alertList.zipcode ||
      alertList.B_AGE ||
      alertList.B_AREA_P ||
      alertList.FLOORS ||
      alertList.LEVEL ||
      alertList.parking_p ||
      alertList.parking_qty
    );
  };

  const requestHandler = () => {
    const isAlerted = alertCheck();
    if (isAlerted) {
      return;
    } else if (getItemFromSessionStorage("queryTimes") >= validateTimes) {
      resultOpenHandler(false);
    } else {
      setLoading(true);
      getLatLng(addrData, setMapCenter, warning);
    }
  };

  const middleHandler = (e) => {
    const name = e.target.name;
    let alertList = { ...alert };
    alertList[name] = e.target.value === "";
    setFormAlert(alertList);
    dataHandler(e);
  };

  const locationHandler = () => {
    getLocation(addrHandler);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h4 className="h4">房屋區域查詢</h4>
          <div className="form-group">
            <button
              className="btn nbtn-myAddress"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                locationHandler();
              }}
            >
              <i className="fas fa-map-marker-alt"></i>&nbsp;我的位置
            </button>
            <AddrTwZipCode
              setData={addrHandler}
              data={addrData}
              alert={alert}
            />
          </div>
        </div>

        <div className="col-md-12">
          <Form
            data={data}
            dataHandler={(e) => {
              middleHandler(e);
            }}
            alert={alert}
            resultOpen={resultOpen}
          />
        </div>
      </div>
      <div className="btn-group">
        {resultOpen ? (
          <div
            className="btn btn-return"
            style={{ lineHeight: "35px" }}
            onClick={(e) => {
              e.preventDefault();
              resultOpenHandler(false);
            }}
          >
            重新查詢
          </div>
        ) : (
          <div
            className="btn btn-search"
            style={{ lineHeight: "35px" }}
            data-title="查詢"
            onClick={requestHandler}
          >
            查詢
          </div>
        )}
      </div>
      {addrWariningOpen && <AddrWarning closeWarning={warning} />}
    </>
  );
};

export default Container1;
