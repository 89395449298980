import { SpringBootServer, testMode } from "../../../StaticResource";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";
// import { MatotoTraceAction } from "../../Utils/Matomo/MatomoAction";

const getCode = async (submitObj) => {
  const path = "/verification/code"
  const url = `${SpringBootServer}${path}`;
  // MatotoTraceAction("#"+path, "getValidateCode");
  const _token = getItemFromSessionStorage("_token");
  submitObj._token = _token;
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(submitObj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((res) => {
      console.log(res);
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    })
    .then((data) => {
      console.log(data);
      storeItemInSessionStorage("_token", data._token);

      if (testMode) {
        alert(data.code);
      }
      storeItemInSessionStorage("userData", submitObj.data);

      return data.count;
    })
    .catch((err) => {
      alert(err);
    });
};

const validateCode = async (code, setStatus) => {
  const path = "/verification/session/verify";
  const url = `${SpringBootServer}${path}`;
  // MatotoTraceAction("#"+path, "verifyCode");
  const _token = getItemFromSessionStorage("_token");
  const submitObj = {
    code: code,
    _token,
  };

  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(submitObj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      //確認有無更換 token
      const tokenStatus = _token === data._token;
      if (!tokenStatus) {
        storeItemInSessionStorage("_token", data._token);
        alert("驗證已逾時，請重新輸入資訊並取得驗證碼");
        setStatus("1");
      }

      //確認回傳結果
      var result = data.verifyStatus;

      return result;
    });
};

export { getCode, validateCode };
