import React, { useState } from "react";
import { formatFilter } from "../../Utils/Filter/Filter";
import { getBTypeIntTitle } from "./DataRow";

const SingleTable = ({ data }) => {
  const [open, setOpen] = useState(false);

  const openHandler = () => {
    setOpen(!open);
  };
  const oriDate = data.updatedAt.split(" ")[0];
  const date = oriDate.split("-").join("/");
  return (
    <div className={open ? "table-row open" : "table-row"}>
      <div className="ti-wrap">
        <table>
          <tbody>
            <tr>
              <th width="40%">查詢日期</th>
              <td width="60%">{date}</td>
            </tr>
            <tr>
              <th width="40%">房屋類型</th>
              <td width="60%">{getBTypeIntTitle(data.bTypeInt)}</td>
            </tr>
            <tr>
              <th>地址</th>
              <td>{data.addr}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th width="40%">坪數</th>
              <td width="60%">{formatFilter(data.bAreaP, "thousand")}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th colSpan="2">平均每坪單價(萬)</th>
            </tr>
            <tr>
              <th className="subth" width="40%">
                500公尺內實價登錄
              </th>
              <td width="60%">
                {data.av500 ? formatFilter(data.av500, "housePrice") : "*"}
              </td>
            </tr>
            <tr>
              <th className="subth">1000公尺內實價登錄</th>
              <td>{data.av500 ? formatFilter(data.av1000, "housePrice"):"*"}</td>
            </tr>
            <tr>
              <th className="subth">預估單價(不含車位)</th>
              <td>{formatFilter(data.av750, "housePrice")}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th width="40%">預估總價(萬)<br />
              不含車位</th>
              <td width="60%">
                {formatFilter(
                  (Number(data.bAreaP) * Number(data.av750)).toFixed(2),
                  "thousand"
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <font style={{paddingLeft:"5px"}}>表格內*號表示「近一年查無實價登錄資訊」</font>
      </div>
      <a
        className={open ? "table-close table-btn" : "table-open table-btn"}
        href="//#endregion"
        onClick={(e) => {
          e.preventDefault();
          openHandler();
        }}
      >
        {open ? (
          <i className="fas fa-angle-up" />
        ) : (
          <i className="fas fa-angle-down" />
        )}
      </a>
    </div>
  );
};

const DataTable = ({ datas }) => {
  return datas.map((data, i) => {
    return <SingleTable data={data} key={i} />;
  });
};

export default DataTable;
