import React from "react";
import { Link } from "react-router-dom";
import MarkDown from "../../../Utils/MarkDownUtils/MarkDown";
import { formatFilter } from "../../../Utils/Filter/Filter";

const AdContainer = ({ data }) => {

  const Recommends = { ...data }["recommend"]
    .sort(function (a, b) {
      return getRate(data, a) - getRate(data, b);
    })
    .map((product, index) => {
      return <Product key={index} product={product} data={data} />;
    });
  return <>{Recommends}</>;
};

export default AdContainer;

const Product = ({ product, data }) => {
  return (
    <div className="col-lg-4 col-md-12">
      <Link
        to={`/productPage/${product.order}`}
        target="_blank"
        className="ritem"
      >
        <div className="rwrap">
          <img src={product.product_image} alt="" />
        </div>
        <h6 className="nbrown">{product.product_name}</h6>
        <div className="rs-txt">
          <strong>適用對象</strong>
          <p style={{height:"130px"}}>
            <MarkDown content={product.product_to}>{product.product_to}</MarkDown>
          </p>
          <strong>優惠利率</strong>
          <p>
            享最低利率
            <font style={{ color: "#E00000", fontSize: "18px" }}>
              {formatFilter(getRate(data, product),"rate")}％
            </font>
            起
          </p>
        </div>
        <div className="mobile">享有利率<em className="txtR">{formatFilter(getRate(data, product),"rate")}％</em>起</div>
        <span>
          了解更多 <i className="fas fa-angle-right"></i>
        </span>
      </Link>
    </div>
  );
};

const getRate = (data, product) => {
  const priority = data.priority;
  const reason = data.house; //1. 我要購買房屋 2. 我要周轉投資(同 MainSwitch house)
  const condition = getCondition(reason, priority);

  return product[condition];
};

const getCondition = (reason, priority) => {
  if (reason === "1") {
    return priority === "AA" ? "condition_house_2a" : "condition_house_a";
  } else {
    return priority === "AA" ? "condition_invest_2a" : "condition_invest_a";
  }
};
