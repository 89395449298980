export const getPlaceHolder = (type) => {
  switch (type) {
    case "addr":
      return "完整地址(最多100字)";
    case "captcha":
      return "輸入驗證碼(數字)";
    case "phone":
      return "請輸入手機號碼";
    case "email":
      return "請輸入E-mail";
    case "house_age":
      return "屋齡(0-99)";
    case "area":
    case "carArea":
      return "坪數(最大999,999.99)";
    case "floor":
      return "總樓層(最大99)";
    case "level":
      return "樓層(最大99)";
    case "carNum":
      return "車位(最大99)";
    case "age":
      return "年齡(最大99)";
    case "childNum":
      return "扶養數(最大999)";
    case "yearSalary":
      return "年收入額度(最大999,999,999)";
    case "housePrice":
      return "成交價格(最大999,999,999)";
    case "name":
      return "姓名(10個中文字)";
    case "name_fly":
      return "姓名";
    case "loan":
      return "貸款額度(最多999,999,999)";
    case "loan_fly":
      return "貸款額度";
    default:
      return "";
  }
};
