import React, { useState } from "react";
import Container1 from "./Container1";
import Container2 from "./Container2";
import FootNote from "../FootNote/FootNote";
import { TrueMap, validateTimes } from "../../../StaticResource";
import BottomLink from "./BottomLink";
import QueryOverAlert from "../AlertPage/QueryOverAlert";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";
import Loading from "../Loading";
import { scrollToDestMore } from "../../Utils/Scroll/ScrollUtils";
const AdvanceBody = ({
  setAddr,
  data,
  addrData,
  dataHandler,
  status,
  applyHandler,
  contactOpenHandler,
  setStatus,
  cleanData
}) => {
  const [mapCenter, setMapCenter] = useState(TrueMap ? false : true);
  const [resultOpen, setResultOpen] = useState(TrueMap ? false : true);
  const [overQueryOpen, setOverQueryOpen] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const loadingHandler = (event) => {
    setIsLoading(event);
  };

  const queryOpenHandler = () => {
    setOverQueryOpen(!overQueryOpen);
  };

  const resultOpenHandler = (result) => {
    const queryTimes = getItemFromSessionStorage("queryTimes");
    if (result === true) {
      setResultOpen(result);
    } else if (result === false) {
      if (queryTimes >= validateTimes) {
        queryOpenHandler();
      } else {
        setResultOpen(result);
        setMapCenter(false);
        cleanData();
        scrollToDestMore("h4", 1000);
      }
    }
  };

  const setQueryTimeHandler = (value) => {
    sessionStorage.removeItem("queryTimes");
    storeItemInSessionStorage("queryTimes", value);
  };

  return (
    <>
      <main className="main-wrap">
        <div className="main-content">
          <div className="title-house-valuation title-inner">
            <h2>您家可能成交總價與周遭生活機能</h2>
          </div>
          <div className="container inner-content">
            <Container1
              setMapCenter={setMapCenter}
              setAddr={setAddr}
              data={data}
              addrData={addrData}
              dataHandler={dataHandler}
              status={status}
              resultOpenHandler={resultOpenHandler}
              resultOpen={resultOpen}
              setLoading={loadingHandler}
            />
            {mapCenter && (
              <Container2
                data={data}
                addrData={addrData}
                mapCenter={mapCenter}
                setMapCenter={setMapCenter}
                resultOpen={resultOpen}
                resultOpenHandler={resultOpenHandler}
                setQueryTimeHandler={setQueryTimeHandler}
                setStatus={setStatus}
                setLoading={loadingHandler}
              />
            )}
            {overQueryOpen && (
              <QueryOverAlert
                setClose={queryOpenHandler}
                setStatus={setStatus}
              />
            )}
          </div>
        </div>
        <Loading isloading={isloading} />
      </main>
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      >
        {resultOpen && <BottomLink queryOpenHandler={queryOpenHandler} />}
      </FootNote>
    </>
  );
};

export default AdvanceBody;
