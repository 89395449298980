import React from "react";
import { getIcons } from "./Icons";
import MarkDown from "../../Utils/MarkDownUtils/MarkDown";
// 202401 vulnerability fix --begin
// const mediaBody = ({ item }) => {
const mediaBody = (props) => {
  let item = props.item;
// 202401 vulnerability fix --end

  if (
    item === undefined ||
    item["content"] === "" ||
    item["content"] === undefined
  )
    return <></>;

  return (
    <div className="col-lg-6 col-md-12 col-sm-12 s-bottom mt-4">
      <div className="media">
        <img
          width={64}
          height={64}
          className="align-self-start mr-3"
          src={getIcons(item.img)}
          alt={item.title}
            // 202401 vulnerability fix :: change close tag from '></img>'  to '/>'
        />
        <div className="media-body">
          <h6 className="brown mb-3">{item.title}</h6>
          <div>
            <MarkDown content={item.content}>{item.content}</MarkDown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mediaBody;
