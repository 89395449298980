import React, { useState } from "react";
import MortgageForm from "./MortgageForm";

import MortgageResult from "./MortgageResult";
import { TrueMap, validateTimes } from "../../../StaticResource";
import FootNote from "../FootNote/FootNote";
import BottomLink from "./BottomLink";
import QueryOverAlert from "../AlertPage/QueryOverAlert";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";
import Loading from "../Loading";
import { scrollToDestMore } from "../../Utils/Scroll/ScrollUtils";
import { ObjFilterHandler } from "../../Utils/Filter/Filter";

const Mortgage = ({
  setAddr,
  addrData,
  data,
  dataHandler,
  applyHandler,
  contactOpenHandler,
  setStatus,
  cleanData,
}) => {
  const [resultOpen, setResultOpen] = useState(TrueMap ? false : true);
  const [mortgageData, setMortgageData] = useState({
    rate: "",
    top_loan_credit: "",
    loan_time: "",
  });
  const [isloading, setIsLoading] = useState(false);
  const loadingHandler = (event) => {
    setIsLoading(event);
  };
  const [overQueryOpen, setOverQueryOpen] = useState(false);

  const queryOpenHandler = () => {
    setOverQueryOpen(!overQueryOpen);
  };
  const resultOpenHandler = (event) => {
    const queryTimes = getItemFromSessionStorage("queryTimes");
    if (event === true) {
      setResultOpen(event);
    } else if (event === false) {
      if (queryTimes >= validateTimes) {
        return queryOpenHandler();
      }
      scrollToDestMore("form", 1000);
      cleanData();
      setResultOpen(event);
    }
  };

  const mortgageDataHandler = (data) => {
    setMortgageData(ObjFilterHandler(data));
    storeItemInSessionStorage("queryTimes", { ...data }.estimateCount);
    loadingHandler(false);
  };

  return (
    <>
      <main className="main-wrap">
        <div className="main-content">
          <div className="title-quota-count title-inner" id="#">
            <h2>您家可能成交總價與周遭生活機能</h2>
          </div>
          <div className="container inner-content">
            <MortgageForm
              addrData={addrData}
              setAddr={setAddr}
              dataHandler={dataHandler}
              data={data}
              resultOpen={resultOpen}
              resultOpenHandler={resultOpenHandler}
              setMortgageData={mortgageDataHandler}
              setStatus={setStatus}
              setLoading={loadingHandler}
            />
          </div>
        </div>

        {resultOpen && <MortgageResult data={mortgageData} />}
        {overQueryOpen && (
          <QueryOverAlert setClose={queryOpenHandler} setStatus={setStatus} />
        )}
        <Loading isloading={isloading} />
      </main>
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      >
        {resultOpen && (
          <BottomLink
            queryOpenHandler={queryOpenHandler}
            resultOpenHandler={resultOpenHandler}
          />
        )}
      </FootNote>
    </>
  );
};

export default Mortgage;
