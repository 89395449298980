import React, { useEffect, useState } from "react";
import { fetchPuw } from "../Fetch/fetchBackend";
import { contactEmail } from "../../../StaticResource";
const LimitAlert = ({ setClose }) => {
  const [puw, setPuw] = useState();

  useEffect(() => {
    fetchPuw(setPuw);
  }, []);

  if (!puw) return <></>;
  const experienceLimit = puw.experienceLimit;
  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden", marginTop:"65px" }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div
              id="popup-2-2-1"
              className="popup g-popup-group smscode-group mfp-with-zoom"
              style={{ marginTop:"65px" }}
            >
              <div className="icon mg-25"></div>
              <h3 className="h3 mg-25">抱歉!您的線上體驗次數已滿5次/月</h3>
              <p className="text mg-25">
                {experienceLimit.limitStringCombined}
              </p>
              <div className="msg-note">
                {experienceLimit.limitString2}
                <a
                  href="https://wwwfile.megabank.com.tw/about/about04.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  服務據點
                </a>
              </div>
              <div className="msg-note">
                或填寫
                <a
                  href={contactEmail}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  聯絡資料
                </a>
                {experienceLimit.limitString3}
              </div>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={(e) => {
                  e.preventDefault();
                  setClose();
                }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LimitAlert;
