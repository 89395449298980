import React, { useState, useEffect } from "react";
import Photos from "../../Photo";
import ApplyPage from "./Apply";
import NavDeskTop from "./NavDeskTop";
import { Link } from "react-router-dom";
import Contact from "./Contact";
import FBShareButton from "./FBShareButton";
import LineShareButton from "./LineShareButton";
import { fetchItem } from "../MainSwitch/Fetch/fetchWhenStart";

const Header = ({
  applyOpen,
  applyHandler,
  contactOpen,
  contactOpenHandler,
  addrData,
  setAddr,
}) => {
  const [fbObj, setFBObj] = useState();

  useEffect(() => {
    fetchItem("socialMedia",socialMediaHandler);
  }, []);

  const socialMediaHandler = (returnObj) =>{
    const dataObj = { ...returnObj };
    setFBObj({ url: dataObj["fbUrl"], message: dataObj["fbMessage"] });
  }

  if (fbObj === undefined) return <></>;
  return (
    <header style={{ zIndex: 99998 }}>
      <div className="container">
        <div className="top-bar row">
          <div className="logo-new">
            <span className="main-logo-new">
              <a
                href="https://www.megabank.com.tw/index.asp"
                title="兆豐銀行"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Photos.newLogo} alt="兆豐銀行" />
              </a>
            </span>
            <span className="nav-line">
              <img src={Photos.HeaderLine} alt="*" />
            </span>
            <h1 className="nav-title">
              <Link to={"/"}>房貸e把兆</Link>
            </h1>
          </div>
          <nav className="main-nav" style={{ zIndex: "999" }}>
            <NavDeskTop
              applyOpenHandler={applyHandler}
              contactOpenHandler={contactOpenHandler}
              fbObj={fbObj}
            />
          </nav>
          <div className="mobile-social">
            <FBShareButton obj={fbObj} type={"mobile"} />
            <LineShareButton obj={fbObj} type={"moblie"} />
          </div>
        </div>
      </div>
      {applyOpen && <ApplyPage setClose={applyHandler} />}
      {contactOpen && (
        <Contact
          setClose={contactOpenHandler}
          addrData={addrData}
          setAddr={setAddr}
        />
      )}
    </header>
  );
};

export default Header;
