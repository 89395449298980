import React from "react";

const ReadingConfirm = ({confirmReading}) => {
  return (
    <>
      
      <h3 className="h3 mg-25">請詳閱告知事項</h3>
      <p className="text mg-25">{`如了解並同意，請勾選「${confirmReading}」，再按確定送出`}</p>
    </>
  );
};

export default ReadingConfirm;
