export const data = {
    "不選擇城市": {
        "不選擇區域": {
            "不設定分行": "無"
        }
    },
    "臺北市": {
        "中正區": {
            "城中分行": "臺北市中正區許昌街42號",
            "金控總部分行": "臺北市中正區忠孝東路二段123號",
            "衡陽分行": "臺北市中正區衡陽路91號",
            "南台北分行": "臺北市中正區羅斯福路二段9-1號"
        },
        "大同區": {
            "中山分行": "臺北市大同區承德路一段70號1樓、2樓及地下1樓",
            "大同分行": "臺北市大同區南京西路113號",
            "大稻埕分行": "臺北市大同區西寧北路62之5號"
        },
        "中山區": {
            "圓山分行": "臺北市中山區中山北路2段133號",
            "南京東路分行": "臺北市中山區南京東路二段53號",
            "台北復興分行": "臺北市中山區南京東路三段198號"
        },
        "松山區": {
            "敦化分行": "臺北市松山區敦化北路88之1號",
            "城東分行": "臺北市松山區民生東路四段52號",
            "民生分行": "臺北市松山區民生東路三段128號",
            "松南分行": "臺北市松山區南京東路五段234號",
            "松山機場分行": "臺北市松山區敦化北路340-9號松山機場航站大廈"
        },
        "大安區": {
            "安和分行": "臺北市大安區安和路二段62號",
            "大安分行": "臺北市大安區信義路3段182號",
            "敦南分行": "臺北市大安區敦化南路二段62號",
            "忠孝分行": "臺北市大安區忠孝東路四段233號"
        },
        "信義區": {
            "台北分行": "臺北市信義區忠孝東路四段550號",
            "世貿分行": "臺北市信義區基隆路一段333號",
            "信義分行": "臺北市信義區基隆路二段65號"
        },
        "士林區": {
            "蘭雅分行": "臺北市士林區中山北路六段126號",
            "天母分行": "臺北市士林區中山北路七段193號"
        },
        "內湖區": {
            "內湖分行": "臺北市內湖區成功路四段68號",
            "東內湖分行": "臺北市內湖區港墘路202號",
            "內湖科學園區分行": "臺北市內湖區瑞光路472號"
        },
        "南港區": {
            "南港分行": "臺北市南港區忠孝東路六段21之1號"
        }
    },
    "基隆市": {
        "仁愛區": {
            "基隆分行": "基隆市仁愛區南榮路24號"
        }
    },
    "新北市": {
        "板橋區": {
            "板橋分行": "新北市板橋區文化路一段51號",
            "板南分行": "新北市板橋區南雅南路二段148號"
        },
        "新店區": {
            "新店分行": "新北市新店區北新路二段173號"
        },
        "永和區": {
            "雙和分行": "新北市永和區永和路一段67號",
            "永和分行": "新北市永和區福和路201號"
        },
        "中和區": {
            "中和分行": "新北市中和區中山路二段124號"
        },
        "土城區": {
            "土城分行": "新北市土城區中央路二段276號"
        },
        "三重區": {
            "三重分行": "新北市三重區重陽路三段99號",
            "南三重分行": "新北市三重區重新路三段128號"
        },
        "新莊區": {
            "思源分行": "新北市新莊區思源路169號",
            "新莊分行": "新北市新莊區思源路421號"
        }
    },
    "宜蘭縣": {
        "宜蘭市": {
            "宜蘭分行": "宜蘭縣宜蘭市民族路338號"
        },
        "羅東鎮": {
            "羅東分行": "宜蘭縣羅東鎮純精路二段195號"
        }
    },
    "桃園市": {
        "中壢區": {
            "北中壢分行": "桃園市中壢區環北路406號",
            "中壢分行": "桃園市中壢區復興路46號"
        },
        "桃園區": {
            "桃園分行": "桃園市桃園區成功路二段2號",
            "桃興分行": "桃園市桃園區復興路180號"
        },
        "八德區": {
            "八德分行": "桃園市八德區大智路19號"
        },
		"龜山區": {
            "林口分行": "桃園市龜山區復興一路199號"
		},
        "大園區": {
            "桃園國際機場分行": "桃園市大園區航站南路15號"
        },
        "蘆竹區": {
            "南崁分行": "桃園市蘆竹區中正路33號"
        }
    },
    "新竹市": {
        "東區": {
            "新竹分行": "新竹市東區公道五路二段417、419號1、2樓",
            "北新竹分行": "新竹市中正路129號",
            "竹科新安分行": "新竹市科學工業園區新安路1號",
            "竹科竹村分行": "新竹市東區科學工業園區竹村七路21號"
        }
    },
    "新竹縣": {
        "竹北市": {
            "竹北分行": "新竹縣竹北市光明一路155號"
        }
    },
    "苗栗縣": {
        "竹南鎮": {
            "竹南科學園區分行": "苗栗縣竹南鎮科研路36號1樓105室"
        },
        "頭份市": {
            "頭份分行": "苗栗縣頭份鎮中華路916號"
        }
    },
    "臺中市": {
        "中區": {
            "台中分行": "臺中市中區民權路216號"
        },
        "北區": {
            "東台中分行": "臺中市北區進化北路330號"
        },
        "西區": {
            "中台中分行": "臺中市西區三民路一段194號",
            "南台中分行": "臺中市西區五權西路一段257號"
        },
        "西屯區": {
            "寶成分行": "臺中市臺灣大道四段600號",
            "北台中分行": "臺中市西屯區臺灣大道三段96號"
        },
		 "南屯區": {
            "向上分行": "臺中市南屯區向上路三段111號"
        },
        "太平區": {
            "太平分行": "臺中市太平區中興東路152號"
        },
        "大里區": {
            "大里分行": "臺中市大里區爽文路600號"
        },
        "豐原區": {
            "豐原分行": "臺中市豐原區中正路519號"
        },
        "后里區": {
            "后里分行": "臺中市后里區甲后路一段665號"
        },
        "潭子區": {
            "潭子分行": "臺中市潭子區臺中加工出口區南二路3號"
        },
        "大雅區": {
            "中科分行": "臺中市大雅區科雅路28號2樓"
        },
        "沙鹿區": {
            "沙鹿分行": "臺中市沙鹿區中山路533號"
        },
        "大甲區": {
            "大甲分行": "臺中市大甲區中山路一段1033號"
        }
    },
    "彰化縣": {
        "彰化市": {
            "北彰化分行": "彰化縣彰化市光復路39號",
            "南彰化分行": "彰化縣彰化市中山路一段401號"
        },
        "鹿港鎮": {
            "鹿港分行": "彰化縣鹿港鎮中山路254號"
        },
        "員林市": {
            "員林分行": "彰化縣員林鎮大同路一段338號"
        }
    },
    "南投縣": {
        "南投市": {
            "南投分行": "南投縣南投市文昌街45號"
        }
    },
    "嘉義市": {
        "東區": {
            "嘉興分行": "嘉義市東區吳鳳北路379號",
            "嘉義分行": "嘉義市文化路259號"
        }
    },
    "雲林縣": {
        "斗六市": {
            "斗六分行": "雲林縣斗六市上海路1號"
        }
    },
    "臺南市": {
        "中西區": {
            "台南分行": "臺南市中西區忠義路二段14號",
            "府城分行": "臺南市中西區中山路90號"
        },
        "東區": {
            "東台南分行": "臺南市東區長榮路一段225號"
        },
        "永康區": {
            "永康分行": "臺南市永康區中山路180號"
        },
        "新市區": {
            "台南科學園區分行": "臺南市新市區南科三路13號"
        }
    },
    "高雄市": {
        "新興區": {
            "新興分行": "高雄市新興區中山一路308號",
            "五福分行": "高雄市新興區五福二路82號"
        },
        "前金區": {
            "港都分行": "高雄市前金區中正四路253號",
            "高雄分行": "高雄市前金區中正四路235號"
        },
        "苓雅區": {
            "三多分行": "高雄市苓雅區三多二路93號",
            "苓雅分行": "高雄市苓雅區四維四路8號"
        },
        "鼓山區": {
            "三民分行": "高雄市鼓山區中華一路225號"
        },
        "前鎮區": {
            "高雄科技園區分行": "高雄市前鎮區中一路3-1號、3-1號3樓"
        },
        "三民區": {
            "東高雄分行": "高雄市三民區大順二路419號",
            "北高雄分行": "高雄市三民區九如二路532號"
        },
        "楠梓區": {
            "楠梓分行": "高雄市楠梓區楠梓加工出口區加昌路600之1號"
        },
        "小港區": {
            "高雄國際機場分行": "高雄市小港區中山四路2號高雄國際航空站新國際航廈"
        },
        "仁武區": {
            "仁武分行": "高雄市仁武區中正路2號"
        },
        "岡山區": {
            "岡山分行": "高雄市岡山區中山北路138號"
        },
        "鳳山區": {
            "鳳山分行": "高雄市鳳山區中山西路248號"
        }
    },
    "屏東縣": {
        "屏東市": {
            "屏東分行": "屏東縣屏東市民族路213號"
        }
    },
    "花蓮縣": {
        "花蓮市": {
            "花蓮分行": "花蓮縣花蓮市公園路26號"
        }
    },
    "金門縣": {
        "金城鎮": {
            "金門分行": "金門縣金城鎮民生路37-5號"
        }
    }
}