import React, { useState, useEffect } from "react";
import Photos from "../../../Photo";
import Banner from "../Banner";
import LimitAlert from "./LimitAlert";
import { getCode } from "../Fetch/fetchBackend";
import { storeItemInSessionStorage } from "../../Utils/BrowserStorageUtils";
import { getPlaceHolder } from "../../Utils/DefaultSetting/PlaceHolder";
import { validateData } from "../../Utils/DefaultSetting/validater";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";
const Verify = ({ setStatus, data, setData }) => {
  const [limitOpen, setLimitOpen] = useState(false);
  
  const { trackPageView } = useMatomo();
  useEffect(()=>{
    trackPageView({
      documentTitle: "取得驗證碼", // optional
      href: `${hashReplacer(window.document.location.href)}/verify`, // optional
    });
  },[])

  const dataHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    let updateData = { ...data };

    //name == type 代表使用者在 SMS/Email當中切換
    //切換後驗證 state 存的資料 (phone/email) 是否符合規範
    if (name === "type") {
      updateData.type = value;
      const validataType = updateData.type === "SMS" ? "phone" : "email";
      value = updateData.type === "SMS" ? updateData.phone : updateData.email;
      updateData.isValidAlert = !validateData(value, validataType);
    } else {
      //如果 name 不是 type，代表使用者在輸入資料
      const validataType = name === "SMS" ? "phone" : "email";
      updateData.isValidAlert = !validateData(value, validataType);

      updateData.type === "SMS"
        ? (updateData.phone = value)
        : (updateData.email = value);
    }

    setData(updateData);
  };
  const alertHandler = () => {
    setLimitOpen(!limitOpen);
  };
  const sendVerify = async () => {
    let value = data.type === "SMS" ? data.phone : data.email;

    const submitObj = {
      type: data.type,
      data: value,
    };
    // const result =true;
    const result = await getCode(submitObj);
    if (result <= 5) {
      storeItemInSessionStorage("type", data.type);
      setStatus("2");
    } else {
      alertHandler();
    }
  };

  return (
    <>
      <div
        id="smscode"
        className="g-grid none page-1"
        style={{ paddingTop: "66px" }}
      >
        <Banner
          text={"取得驗證碼，體驗線上量身服務"}
          photo={Photos.banner_smscode}
        />
        <div className="container sm w-sm">
          <div
            id="estimate-verify-form"
            className="g-form wrap clearfix"
            autoComplete="off"
          >
            <div className="item-group">
              <div className="item mg-20">
                <div className="c">
                  <h3 className="h3">驗證碼</h3>
                  <ul className="nav nav-tabs">
                    <li>
                      <a
                        className={data.type === "SMS" ? "active" : ""}
                        href="//#endregion"
                        title="簡訊驗證"
                        name="type"
                        value="SMS"
                        data-toggle="tab"
                        onClick={(e) => {
                          e.preventDefault();
                          const obj = {
                            target: { name: "type", value: "SMS" },
                          };
                          dataHandler(obj);
                        }}
                      >
                        簡訊驗證
                      </a>
                    </li>
                    <li>
                      <a
                        className={data.type === "SMS" ? "" : "active"}
                        href="//#endregion"
                        title="E-mail驗證"
                        data-toggle="tab"
                        name="type"
                        value="email"
                        onClick={(e) => {
                          e.preventDefault();
                          const obj = {
                            target: { name: "type", value: "email" },
                          };
                          dataHandler(obj);
                        }}
                      >
                        E-mail驗證
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id={data.type === "SMS" ? "phone-tab" : "email-tab"}
                      className="tab-pane fade in active"
                    >
                      <div className="form-group mg-20">
                        <input
                          id={data.type === "SMS" ? "mobile-phone" : "email"}
                          className="form-control"
                          type={data.type === "SMS" ? "number" : "email"}
                          name={data.type === "SMS" ? "SMS" : "email"}
                          value={data.type === "SMS" ? data.phone : data.email}
                          placeholder={
                            data.type === "SMS"
                              ? getPlaceHolder("phone")
                              : getPlaceHolder("email")
                          }
                          onChange={(e) => {
                            dataHandler(e);
                          }}
                        />
                      </div>
                      {data.isValidAlert && (
                        <div
                          className="error-msg small"
                          style={{ color: "red" }}
                        >
                          請輸入正確資訊
                        </div>
                      )}
                      <span className="msg-note mg-20">
                        *系統驗證碼將以
                        {data.type === "SMS"
                          ? "簡訊方式傳至您的手機"
                          : "E-mail方式傳至您的信箱"}
                      </span>
                    </div>
                  </div>

                  <button
                    id="pw-submit"
                    className="btn btn-search sms"
                    disabled={
                      data.isValidAlert ||
                      (data.type === "SMS" && data.phone === "") ||
                      (data.type === "email" && data.email === "")
                    }
                    onClick={sendVerify}
                  >
                    領取驗證碼
                  </button>
                </div>
              </div>
              <p className="msg-note brown">
                提醒您！本服務一個月有5次體驗機會，請多加利用！
              </p>
            </div>
            <input type="hidden" id="channel" name="channel" />
          </div>
        </div>
      </div>
      {limitOpen && <LimitAlert setClose={alertHandler} />}
    </>
  );
};

export default Verify;
