import React, { useState, useEffect } from "react";
import { fetchPuw } from "../Fetch/fetchBackend";

const ReadingAlert = ({ setClose, open }) => {
  const [puw, setPuw] = useState();

  useEffect(() => {
    fetchPuw(setPuw);
  }, []);
  if (!open) return <></>;
  if (!puw) return <></>;
  const confirmReading = puw.confirmReading;

  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden",marginTop:"65px" }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div
              id="popup-2-2-2"
              className="popup g-popup-group smscode-group mfp-with-zoom"
              style={{ marginTop:"65px" }}
            >
              <div className="icon mg-25"></div>
              <h3 className="h3 mg-25">請詳閱告知事項</h3>
              <p className="text mg-25">{`如了解並同意，請勾選「${confirmReading}」，再按下一步`}</p>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={(e) => {
                  e.preventDefault();
                  setClose();
                }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadingAlert;
