import React, { useState, useRef, useEffect } from "react";
import Photos from "../../../Photo";
import Banner from "../Banner";
import ReadingAlert from "./ReadingAlert";
import { validateCode, fetchPuw } from "../Fetch/fetchBackend";
import {
  storeItemInSessionStorage,
  getItemFromSessionStorage,
} from "../../Utils/BrowserStorageUtils";
//202208 Vulnerability Project Modify -- begin 
import { validateData } from "../../Utils/DefaultSetting/validater";
//202208 Vulnerability Project Modify -- end 
import { pdf } from "../../../pdf";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";

const Validation = ({ setStatus, data }) => {
  useEffect(() => {
    storeItemInSessionStorage("inValidation", "true");
  });
  const type = getItemFromSessionStorage("type");
  const [time, setTime] = useState(type === "SMS" ? 100 : 300);
  const [code, setData] = useState({
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
    n6: "",
  });
  const [puw, setPuw] = useState();
  const focusN1 = useRef();
  const focusN2 = useRef();
  const focusN3 = useRef();
  const focusN4 = useRef();
  const focusN5 = useRef();
  const focusN6 = useRef();
  
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "填寫驗證碼", // optional
      href: `${hashReplacer(window.document.location.href)}/validation`, // optional
    });
  }, []);

  useEffect(() => {
    autoFocus();
    if (!puw) fetchPuw(setPuw);
  }, [code.n1, code.n2, code.n3, code.n4, code.n5]);

  const autoFocus = () => {
    if (code.n5) return focusN6.current.focus();
    if (code.n4) return focusN5.current.focus();
    if (code.n3) return focusN4.current.focus();
    if (code.n2) return focusN3.current.focus();
    if (code.n1) return focusN2.current.focus();
    if (focusN1.current) return focusN1.current.focus();
  };
  const [readingAlertOpen, setReadingAlertOpen] = useState(false);

  const alertHandler = () => {
    setReadingAlertOpen(!readingAlertOpen);
  };

//202208 Vulnerability Project Modify -- begin 

  // const codeHandler = (e) => {
  //   const name = e.target.name;

  //   const value = e.target.value;
  //   if (!validateData(value, "code") && value !== "") return;
  //   let thisCode = { ...code };
  //   thisCode[name] = value;

  //   setData(thisCode);
  // };  
  	
  const codeHandler = (e) => {
	console.log("first..");
	e.stopPropagation();
    e.preventDefault();
    const name = e.target.name; 
    var value  = e.target.value; 
    
    //Check value length
    if (!/^\d{1}$/.test(value)) {
	  	value = value.slice(0, 1);
      	e.target.value = value;
    }

    //Convert double byte charater to single byte
    var ascii = '';
    for(var i=0, l=value.length; i<l; i++) {
        var c = value[i].charCodeAt(0);
        if (c >= 0xFF00 && c <= 0xFFEF) {
            c = 0xFF & (c + 0x20);
        }
        ascii += String.fromCharCode(c);
    }
    value = ascii;  //Converted value   
    
    if (!validateData(value, "verification_code") && value !== "") {
		e.target.value=''; //Invalid data, reset it 
      	return;
    } 
    
    e.target.value = value; //Set the converted value

    let thisCode = { ...code };
    thisCode[name] = value;

    setData(thisCode);
    
  };

//202208 Vulnerability Project Modify -- end

  const [defaultClick, setClick] = useState(false);
  const setClickHandler = () => {
    setClick(!defaultClick);
  };

  setTimeout(() => {
    const value = time;
    if (value > 0) {
      setTime(value - 1);
    } else {
    }
  }, 1000);

  const submitHandler = async () => {
    const finalCode = code.n1 + code.n2 + code.n3 + code.n4 + code.n5 + code.n6;
    if (finalCode.length !== 6) {
      return alert("請輸入驗證碼");
    } else if (!defaultClick) {
      return alertHandler();
    } else if (time <= 0) {
      alert("簡訊驗證碼逾時，請重新填寫資訊並取得驗證碼");
      setStatus("1");
    } else {
      const validate = await validateCode(finalCode, setStatus);

      if (validate) {
        setStatus("3");

        storeItemInSessionStorage("queryTimes", 0);
      } else {
        return alert("請確認填入之驗證碼並再次送出");
      }
    }
  };
  if (!puw) return <></>;
  const confirmReading = puw.confirmReading;
  return (
    <div id="smscode" className="g-grid page-2" style={{ paddingTop: "66px" }}>
      <Banner
        text={"取得驗證碼，體驗線上量身服務"}
        photo={Photos.banner_smscode}
      />

      <div className="container xs w-md">
        <div id="estimate-resend-form" className="g-form" autoComplete="off">
          <h3 className="h3">驗證碼</h3>
          <p className="msg-note brown text">請輸入驗證碼</p>

          <div className="smscode-input">
            <input
              name="n1"
              className="verify-code-input"
              type="number" 
              value={code.n1}
              onInput={(e) => codeHandler(e)}  
              ref={focusN1}
            />
            <input
              name="n2"
              className="verify-code-input"
              type="number" 
              value={code.n2}
              onInput={(e) => codeHandler(e)} 
              ref={focusN2}
            />
            <input
              name="n3"
              className="verify-code-input"
              type="number" 
              value={code.n3}
              onInput={(e) => codeHandler(e)} 
              ref={focusN3}
            />
            <input
              name="n4"
              className="verify-code-input"
              type="number" 
              value={code.n4}
              onInput={(e) => codeHandler(e)}  
              ref={focusN4}
            />
            <input
              name="n5"
              className="verify-code-input"
              type="number" 
              value={code.n5}
              onInput={(e) => codeHandler(e)}  
              ref={focusN5}
            />
            <input
              name="n6"
              className="verify-code-input"
              type="number" 
              value={code.n6}
              onInput={(e) => codeHandler(e)}  
              ref={focusN6}
            />
          </div>

          <p className="msg-note" id="smsTime">
            驗證碼有效時間剩餘 {time} 秒
          </p>
          {time > 0 && (
            <div className="btn-reset-color">
              <button className="btn reset mg-25" disabled>
                重新領取驗證碼
              </button>
            </div>
          )}
          {time === 0 && (
            <div className="btn-reset-color">
              <button
                className="btn reset mg-25 active"
                onClick={(e) => setStatus("1")}
              >
                重新領取驗證碼
              </button>
            </div>
          )}

          <p className="msg-notice f-xs mg-25 brown">
            {data.type === "SMS"
              ? `驗證碼已發送至您的手機號碼 ${data.phone}。如 100秒內未收到簡訊，請點選「重新領取驗證碼」。`
              : `驗證碼已發送至您的E-mail ${data.email}。如 5分鐘 內未收到E-mail，請點選「重新領取驗證碼」。`}
            <br />
            {data.type === "SMS"
              ? "提醒您：本服務一個月有 5次驗證機會，且每次驗證後有 6次查詢功能使用次數限制，請多加利用！"
              : "提醒您：本服務提供同一 E-mail 當月 5次驗證機會，且每次驗證後有 6次查詢功能使用次數限制，請多加利用！"}
          </p>

          <h4 className="h4">兆豐國際商業銀行 履行個人資料保護法告知義務</h4>
          <div className="form-group">
            <div className="g-agreement mg-5">
              <div>
                親愛的客戶您好,由於個人資料之蒐集,涉及
                臺端的隱私權益,兆豐國際商業銀行股份有限公司(以下稱本行)向
                臺端蒐集個人資料時,依據個人資料保護法(以下稱個資法)第八條第一項規定,應明確告知
                臺端下列事項:(一)蒐集者名稱(即兆豐國際商業銀行)(二)蒐集之目的(三)個人資料之類別(四)個人資料利用之期間、地區、對象及方式(五)當事人依個資法第三條規定得行使之權利及方式(六)當事人得自由選擇提供個人資料時,不提供將對其權益之影響。
                <br />
                一、有關本行蒐集
                臺端個人資料之目的、個人資料類別及個人資料利用之期間、地區、對象及方式等內容,請
                臺端詳閱如後
                <a href={pdf} target="_blank" rel="noopener noreferrer">
                  附表(請點選)
                </a>
                或本行網站。
                <br />
                二、依據個資法第三條規定, 臺端就本行保有
                臺端之個人資料得行使下列權利:
                <br />
                (一)除有個資法第十條所規定之例外情形外,得向本行查詢、請求閱覽或請求製給複製本,惟本行依個資法第十四條規定得酌收必要成本費用。
                <br />
                (二)得向本行請求補充或更正,惟依個資法施行細則第十九條規定,臺端應適當釋明其原因及事實。
                <br />
                (三)本行如有違反個資法規定蒐集、處理或利用
                臺端之個人資料,依個資法第十一條第四項規定,臺端得向本行請求停止蒐
                集。
                <br />
                (四)依個資法第十一條第二項規定,個人資料正確性有爭議者,得向本行請求停止處理或利用
                臺端之個人資料。惟依該項但書
                規定,本行因執行業務所必須並註明其爭議或經
                臺端書面同意者,不在此限。
                <br />
                (五)依個資法第十一條第三項規定,個人資料蒐集之特定目的消失或期限屆滿時,得向本行請求刪除、停止處理或利用
                臺端之 個人資料。惟依該項但書規定,本行因執行業務所必須或經
                臺端書面同意者,不在此限。
                <br />
                三、臺端如欲行使上述個資法第三條規定之各項權利,有關如何行使之方式,得向本行客服(0800-016168)詢問或於本行網站(網址:
                https://www.megabank.com.tw/)查詢。
                <br />
                四、臺端得自由選擇是否提供相關個人資料及類別,惟
                臺端所拒絕提供之個人資料及類別,如果是辦理業務審核或作業所需之資料,
                本行可能無法進行必要之業務審核或作業而無法提供
                臺端相關服務或無法提供較佳之服務,敬請見諒。
                <br />
              </div>
            </div>
            <div className="g-radio-group mt-4">
              <div className="el-checkbox el-checkbox-yellow">
                <input
                  type="checkbox"
                  name="check"
                  id="validation-agree"
                  checked={defaultClick}
                  onChange={setClickHandler}
                />
                <label
                  className="el-checkbox-style"
                  htmlFor="validation-agree"
                ></label>
                <span className="checkbox-agree">{confirmReading}</span>
              </div>
            </div>
          </div>
        </div>

        <div id="estimate-verify-input-form" autoComplete="off">
          <input type="hidden" id="code" name="code" />
          {code.n1 && code.n2 && code.n3 && code.n4 && code.n5 && code.n6 ? (
            <div className="btn-group">
              <button className="btn btn-search" onClick={submitHandler}>
                下一步
              </button>
            </div>
          ) : (
            <div className="btn-group">
              <button className="btn btn-search" disabled>
                下一步
              </button>
            </div>
          )}
        </div>
      </div>
      <ReadingAlert setClose={alertHandler} open={readingAlertOpen} />
    </div>
  );
};

export default Validation;
