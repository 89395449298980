import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchItem } from "../MainSwitch/Fetch/fetchWhenStart";

const ProductSet = ({ openMenuHandler }) => {
  const [category, setCategory] = useState({});

  useEffect(() => {
    fetchItem("category", categoryHandler);
  }, []);

  const categoryHandler = (returnObj) => {
    setCategory({ ...returnObj });
  };
  if (category === undefined) return <></>;
  const Product = Object.keys(category).map((setName, index) => {
    const Products = category[setName].map((valueObj, index2) => {
      return (
        <Link
          to={`/productPage/${valueObj.order}`}
          key={index2}
          onClick={openMenuHandler}
        >
          {valueObj.title}
        </Link>
      );
    });
    return (
      <div className="sublink-group" key={index}>
        <Link to={`/products/${setName}`} onClick={openMenuHandler}>
          {setName}
        </Link>
        {Products}
      </div>
    );
  });

  return (
    <li className="link-product">
      <Link
        to={"/products/我要購置房屋"}
        data-toggle="dropdown"
        onClick={openMenuHandler}
      >
        房貸產品
      </Link>
      <div className="link-submenu">{Product}</div>
    </li>
  );
};

export default ProductSet;
