import React, { useEffect, useState } from "react";
import {
  Landmark,
  Apartment,
  Suite,
  Villa,
  Store,
  OfficeBuilding,
} from "../IconPhotos";

const House = ({ B_TYPE_INT, dataHandler }) => {
  const [type, setType] = useState("");

  useEffect(() => {
    setType(B_TYPE_INT);
  }, [B_TYPE_INT]);
  if (type === "") return <></>;

  const getBuilding = (type) => {
    switch (type) {
      case "1":
        return <Apartment />;
      case "2":
        return <Villa />;
      case "3":
        return <Store />;
      case "4":
        return <OfficeBuilding />;
      case "6":
        return <Landmark />;
      case "7":
        return <Suite />;
      default:
        // return alert("error");
    }
  };

  const types = [
    { value: "6", name: "電梯大樓 / 華廈" },
    { value: "1", name: "無電梯公寓" },
    { value: "7", name: "套房" },
    { value: "2", name: "別墅 / 透天厝" },
    { value: "3", name: "店面 (店鋪)" },
    { value: "4", name: "辦公商業大樓" },
  ];

  const contents = types.map((item, i) => {
    return (
      <React.Fragment key={i}>
        <input
          type="radio"
          id={item.value}
          name="B_TYPE_INT"
          onChange={(e) => {
            dataHandler(e);
          }}
          value={item.value}
          checked={type === item.value}
          key={i}
        />
        <label htmlFor={item.value} >
          {getBuilding(item.value)}
          <p>{item.name}</p>
        </label>
      </React.Fragment>
    );
  });

  return (
    <div className="form-group">
      <label>房屋類型</label>
      <div className="abgne-menu">
        {contents}
      </div>
    </div>
  );
};

export default House;
