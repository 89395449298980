import React from "react";
import { zipCodeData } from "./zipCodeData";
import { getPlaceHolder } from "../DefaultSetting/PlaceHolder";

const AddrTwZipCode = ({ data, setData, alert }) => {
  const stateChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    let theCounty = data.county;
    let theDist = data.district;
    let theZipCode = data.zipcode;
    let theAddr = data.addr;
    if (name === "county") {
      theCounty = value;
      let countyObj = zipCodeData[theCounty];
      theDist = Object.keys(countyObj)[0];
      theZipCode = countyObj[Object.keys(countyObj)[0]];
    } else if (name === "district") {
      theDist = value;
      theZipCode = zipCodeData[theCounty][value];
    } else if (name === "addr") {
      theAddr = value;
    }

    const newData = {
      county: theCounty,
      district: theDist,
      zipcode: theZipCode,
      addr: theAddr,
    };
    setData(newData);
  };
  const Counties = Object.keys(zipCodeData);
  let Districts;
  let theCounty;
  let theDist;
  let theAddr = data.addr;
  if (data.county === "") {
    theCounty = Counties[0];
    let countyObj = zipCodeData[theCounty];
    let firstDist = Object.keys(countyObj)[0];
    let firstZipCode = countyObj[firstDist];
    const newData = {
      county: theCounty,
      district: firstDist,
      zipcode: firstZipCode,
      addr: theAddr,
    };
    setData(newData);
  } else {
    theCounty = data.county;
    theDist = data.district;
    Districts = Object.keys(zipCodeData[theCounty]).map((district, index) => (
      <option key={index}>{district}</option>
    ));
  }

  const CountyOption = Counties.map((county, index) => (
    <option key={index}>{county}</option>
  ));

  return (
    <>
      <span className="twzipcode">
        <div className="dropdown mm">
          <select
            id="county"
            name={"county"}
            onChange={stateChangeHandler}
            value={theCounty}
            className={
              alert.zipcode
                ? "StyleDropdown form-control empty-note"
                : "StyleDropdown form-control"
            }
          >
            {CountyOption}
          </select>
          {alert.zipcode && (
            <div
              className="is-invalid small"
              style={{ display: "block", color: "red" }}
            >
              請輸入正確資訊
            </div>
          )}
        </div>
        <div className="dropdown mm">
          <select
            id="area"
            name={"district"}
            onChange={stateChangeHandler}
            value={theDist}
            className={
              alert.zipcode
                ? "StyleDropdown form-control empty-note"
                : "StyleDropdown form-control"
            }
            style={{width:"185px"}}
          >
            {Districts}
          </select>
          {alert.zipcode && (
            <div
              className="is-invalid small"
              style={{ display: "block", color: "red" }}
            >
              請輸入正確資訊
            </div>
          )}
        </div>
      </span>
      <div className="input-width addr-padding" >
        <input
          id="full-address"
          name={"addr"}
          className={alert.addr ? "form-control empty-note" : "form-control"}
          onChange={stateChangeHandler}
          value={theAddr}
          placeholder={getPlaceHolder("addr")}
        />
        {alert.addr && (
          <div
            id="full-address-error"
            className="is-invalid small"
            style={{ color: "red" }}
          >
            請輸入正確資訊
          </div>
        )}
      </div>
    </>
  );
};

export default AddrTwZipCode;
