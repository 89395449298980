import { storageTimes, localStoreByDate } from "../../StaticResource";

const storeItemInLocalStorage = (key, value) => {
  let obj = {
    time: new Date().getTime(),
    value: value,
    expire: storageTimes * 1000,
  };
  //localStorage只能儲存字串，所以要先將物件轉成字串
  let objStr = JSON.stringify(obj);
  localStorage.setItem(key, objStr);
};

const getItemFromLocalStorage = (key) => {
  const storage = localStorage.getItem(key);
  if(storage){
    return checkStorage(storage);
  }else{
    return null
  }
  
};

const storeItemInSessionStorage = (key, value) => {
  let obj = {
    value: value,
  };
  sessionStorage.setItem(key, JSON.stringify(obj));
};

const getItemFromSessionStorage = (key) => {
  var keyItem = sessionStorage.getItem(key);
  if (keyItem) {
    const keyObj = JSON.parse(keyItem);
    return { ...keyObj }.value;
  }
  return null;
};

const checkStorage = (storage) => {
  if (storage) {
    var Obj = JSON.parse(storage);
    const objTime = Obj.time;

    if (localStoreByDate) {
      const date = new Date().getDate();
      return date === new Date(objTime).getDate() ? Obj.value : false;
    } else {
      if (new Date().getTime() - objTime >= Obj.expire) {
        localStorage.removeItem("name");
        return false;
      } else {
        console.log("storage有效");
        return Obj.value;
      }
    }
  } else {
    console.log("storage失效，重取資訊");
    return false;
  }
};

const getItemFromStorage = (key) => {
  const media = getStorageMedia(key);

    return getItemFromLocalStorage(key);

};

const storeItemToStorage = (key, object) => {
  const media = getStorageMedia(key);

    storeItemInLocalStorage(key, object);

};

const getStorageMedia = (key) => {
  return "localStorage";
};

export {
  storeItemInLocalStorage,
  getItemFromLocalStorage,
  storeItemInSessionStorage,
  getItemFromSessionStorage,
  getItemFromStorage,
  storeItemToStorage,
};
