import React, { useState } from "react";
import ButtomLink from "../AdvanceEstimate/BottomLink";
import Rating from "../Rating/Rating";
import Photos from "../../../Photo";
import { Link } from "react-router-dom";
const BottomLink = () => {
  const [ratingOpen, setRatingOpen] = useState(false);
  const ratingHandler = () => {
    setRatingOpen(!ratingOpen);
  };

  return (
    <div className="count-fuction">
      <Link to="/advance" className="cf">
        <img src={Photos.cficon2} alt="" />
        <span>房屋估價</span>
        <strong>提供房價<br/>預估行情</strong>
      </Link>
      <Link to="/mortgage" className="cf">
        <img src={Photos.cficon1} alt="" />
        <span>額度利率試算</span>
        <strong>提供專屬額度<br/>利率方案</strong>
      </Link>
      <a
        className="cf"
        onClick={e => {
          e.preventDefault();
          ratingHandler();
        }}
        style={{ cursor: "pointer" }}
      >
        <img src={Photos.cficon3} alt="" />
        <span>網站使用調查</span>
        <strong>
          誠摯邀請您
          <br />
          參與線上問卷
        </strong>
      </a>
      {ratingOpen && <Rating status="2-1" setOpen={ratingHandler}/>}
    </div>
  );
};

export default BottomLink;
