import React from "react";
import { formatFilter } from "../../Utils/Filter/Filter";

const CountingResult = ({ obj, pay }) => {
  return (
    <div className="row">
      <div id="valuation" className="result">
        <div className="item-group mg-10 c-row refont">
          {Number(pay.time) > 0 && (
            <div className="item" style={{ background: "#FFF" }}>
              <div>
                <h5 className="h5 line nbrown">寬限期內每期應付</h5>
                <p className="text f-sm">
                  &nbsp;&nbsp;新台幣&nbsp;&nbsp;
                  <span className="red">
                    <span className="big" id="money1" name="money1">
                      {formatFilter(pay.extendPay,"thousand")}
                    </span>
                  </span>
                  <span className="red">&nbsp;萬</span>
                </p>
              </div>
            </div>
          )}

          <div className="item" style={{ background: "#FFF" }}>
            <div>
              <h5 className="h5 line brown">寬限期滿/無寬限期者每期應付</h5>
              <p className="text f-sm">
                &nbsp;&nbsp;新台幣&nbsp;&nbsp;
                <span className="red">
                  <span className="big" id="change" name="change"> 
                    {formatFilter(pay.normalPay,"thousand")}
                  </span>
                </span>
                <span className="red">&nbsp;萬</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountingResult;
