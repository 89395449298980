import React from "react";

const Alert = ({AlertItem}) => {
  if (AlertItem) {
    return (
      <span className="keyin-txt">
        請輸入正確資訊
      </span>
    );
  } else {
    return <></>;
  }
};

export default Alert;
