import { scroller } from "react-scroll";

const scrollToDestMore = (target, duration, disMore=-65) => {
  scroller.scrollTo(target, {
    //目標element name
    duration: duration,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: disMore, //目的地: 目標+offset px
  });
};

const scrollTop = () =>{
  window.scrollTo({top: -65, behavior: 'smooth'});
};


export { scrollToDestMore, scrollTop };
