import banner1 from "./images/banner-3.1.jpg";
import banner_search from "./images/banner-search.jpg";
import banner_smscode from "./images/banner-smscode.jpg";

import newLogo from "./images/NewLogo.png";
import HeaderLine from "./images/header-Line.svg";

import mf1 from "./images/mf-1.png";
import mf2 from "./images/mf-2.png";
import mf3 from "./images/mf-3.png";
import cficon1 from "./images/cficon-01.png";
import cficon2 from "./images/cficon-02.png";
import cficon3 from "./images/cficon-03.png";
import IconHouse from "./images/iconHouse.png";
import info from "./images/info.svg";
import loading from "./images/loading.gif";
const Photos = {
  HeaderLine: HeaderLine,

  //商品 banner
  banner1: banner1,
  banner_search: banner_search,
  banner_smscode: banner_smscode,

  newLogo: newLogo,
  mf1: mf1,
  mf2: mf2,
  mf3: mf3,
  cficon1: cficon1,
  cficon2: cficon2,
  cficon3: cficon3,
  IconHouse: IconHouse,
  info: info,
  loading: loading,
};

export default Photos;
