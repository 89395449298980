import React, { useState, useEffect } from "react";
import AdContainer from "./AdContainer/AdContainer";
import ConditionContainer from "./ConditionContainer";
import CountingResult from "./CountingResult";
const Counting = ({ data }) => {
  const [obj, setObj] = useState({
    loan: data.top_loan_credit,
    loan_years: data.loan_time,
    rate: data.rate,
    time: "0"
  });

  const [pay, setPay] = useState({
    time: "0",
    extendPay: "",
    normalPay: ""
  });

  const calculator = () => {
    const loan = Number(obj.loan);
    const loan_years = Number(obj.loan_years);
    const rate = Number(obj.rate);
    const time = Number(obj.time);
    const thisPay = { pay };

    let normalPay =
      loan *
      ((Math.pow(1 + rate / 100 / 12, 12 * (loan_years - time)) *
        (rate / 100 / 12)) /
        (Math.pow(1 + rate / 100 / 12, 12 * (loan_years - time)) - 1));
    thisPay.normalPay = Number(normalPay).toFixed(2);
    thisPay.time = time;
    if (time > 0) {
      thisPay.extendPay = Number(loan * (rate / 100 / 12)).toFixed(2);
    } else {
      thisPay.extendPay = 0;
    }

    setPay(thisPay);
  };

  useEffect(() => {
    calculator();
  }, []);
  return (
    <>
      <div className="g-ccc">
        <div className="container position-relative">
          <h4 className="h4">您的月付金試算</h4>
          <p className="small">*本試算方法係採本息平均攤還法</p>
          <ConditionContainer
            data={data}
            setObj={setObj}
            obj={obj}
            calculator={calculator}
          />

          {pay.normalPay && <CountingResult pay={pay} obj={obj} />}
          <div className="col-md-12">
            <p className="count-text">
              *本網站所提供之試算結果僅供參考，實際鑑估結果仍待
              台端正式申請，經本行人員實地勘估之正式估價報告書為準。實際額度、利率等貸款條件，須視
              台端提供之文件及信用狀況，經本行審核確認後始生效力。本行保有最後核貸與否及產品方案變更之權利。其他未盡事宜及條件變動，悉依本行最新作業規定辦理。
            </p>
          </div>
          <div className="container recommend-some">
            <h4 className="h4 mt-5">為您精選推薦</h4>
            <div className="row">
              <AdContainer data={data}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counting;
