import React from "react";
import {formatFilter} from "../../Utils/Filter/Filter"
const BlockUnit = ({name, avg, min, max}) => {
  return (
    <div className="item">
      <div>
        <h5 className="h5 line nbrown">{name}</h5>
        <p className="text f-sm">每坪單價(萬)&nbsp;&nbsp;</p>
        <p className="big mgt-10b-30">
          <span className="red priceSpan1">{formatFilter(min,"housePrice")}</span>
          <span className="priceSpan2">至</span>
          <span className="red priceSpan3">{formatFilter(max,"housePrice")}</span>
        </p>
        <p className="text f-sm mg-0 lineH-18">平均每坪單價&nbsp;&nbsp;</p>
        <p className="text f-sm mg-0 lineH-18">
          <span className="red">{formatFilter(avg,"housePrice")}</span>
        </p>
      </div>
    </div>
  );
};
export default BlockUnit;
