import React, { useEffect } from "react";
import { scrollToDestMore } from "../../Utils/Scroll/ScrollUtils";
import { formatFilter } from "../../Utils/Filter/Filter";

const RateResult = ({ data }) => {
  useEffect(() => {
    scrollToDestMore("hr", 1300);
  }, [data]);
  return (
    <>
      <hr className="hr" name="hr" />
      <div className="container inner-content" style={{ minHeight: "auto" }}>
        <div className="col-md-12">
          <h4 className="h4">初步試算您的最佳方案</h4>
        </div>
        <div className="item-group mg-10 c-row refont">
          <div className="threeitem">
            <div>
              <h5 className="h5 line nbrown">初估貸款最高額度</h5>
              <p className="text f-sm" style={{ paddingTop: "25px" }}>
                &nbsp;&nbsp;新台幣&nbsp;&nbsp;
                <span className="red">
                  <span id="mynum" className="big">
                    {formatFilter(data.top_loan_credit, "cleanThousand")}
                  </span>
                </span>
                <span className="red">&nbsp;萬</span>
              </p>
            </div>
          </div>

          <div className="threeitem">
            <div>
              <h5 className="h5 line nbrown">初估貸款期限</h5>
              <p className="text f-sm" style={{ paddingTop: "25px" }}>
                <span className="red">
                  <span className="big">{data.loan_time}</span>
                </span>
                <span className="red">&nbsp;年</span>
              </p>
            </div>
          </div>

          <div className="threeitem">
            <div>
              <h5 className="h5 line nbrown">初估最低利率</h5>
              <p className="text f-sm" style={{ paddingTop: "25px" }}>
                <span className="red">
                  <span className="big">{formatFilter(data.rate, "rate")}</span>
                </span>
                <span className="red">&nbsp;%&nbsp;起</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateResult;
