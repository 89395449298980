import { useEffect } from "react";
import createInfoWindow from "./InfoWindow";
const Marker = ({
  lat,
  lng,
  map,
  category,
  title,
  image,
  visible,
  gmarkers,
  setInfowindow,
  infoContent,
  distance
}) => {
  const latlng = new window.google.maps.LatLng(lat, lng);
  useEffect(() => {
    createMarker();
  });

  const createMarker = () => {
    const mark = new window.google.maps.Marker({
      map: map,
      position: latlng,
      icon: image,
      category: category,
      // title: title,
      visible: visible,
      distance:distance
    });
    if (setInfowindow) {
      createInfoWindow(map, mark, infoContent);
    }
    if(gmarkers){
      gmarkers.push(mark);
    }
    return null;
  };
  return null;
};

export default Marker;
