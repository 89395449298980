import React from "react";
import Description from "./Description";

const ProductDescriptions = ({ content }) => {
  console.log("20230203=", content);
  const items = Object.keys(content);
  const contentMap = items.map((itemName, index) => {
    const item = content[itemName];
    return <Description key={index} item={item} />;
  });
  return contentMap;
};

export default ProductDescriptions;
