import React, { useState, useEffect } from "react";
import Header from "./module/Header/Header";
import MainSwitch from "./module/MainSwitch/MainSwitch";
import { HashRouter as Router } from "react-router-dom";
import { ObjFilterHandler } from "./module/Utils/Filter/Filter";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { onlineLoanPageUrl, useOnlineLoanPage } from "./StaticResource";

const App = () => {
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();
  const [applyOpen, setApplyOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);

  const applyHandler = () => {
    if (useOnlineLoanPage) {
      window.open(onlineLoanPageUrl);
    } else {
      setApplyOpen(!applyOpen);
    }
  };
  const contactOpenHandler = () => {
    setContactOpen(!contactOpen);
  };

  const [addrData, setAddrData] = useState();
  useEffect(() => {
    setAddrData(addrTemplate);
  }, []);
  const addrDataHandler = (value) => {
    const thisAddrData = ObjFilterHandler(value);
    setAddrData(thisAddrData);
  };

  return (
    <Router>
      <div className="all-wrap">
        <Header
          applyOpen={applyOpen}
          applyHandler={applyHandler}
          contactOpen={contactOpen}
          contactOpenHandler={contactOpenHandler}
          addrData={addrData}
          setAddr={addrDataHandler}
        />
        <MainSwitch
          applyHandler={applyHandler}
          contactOpenHandler={contactOpenHandler}
          addrData={addrData}
          setAddr={addrDataHandler}
        />
      </div>
    </Router>
  );
};

export default App;

export const addrTemplate = {
  county: "房屋縣市",
  district: "房屋區域",
  zipcode: "",
  addr: "",
};
