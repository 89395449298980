import React, { useEffect } from "react";
import ProductDescriptions from "./Descriptions";
import MsgNote from "./MsgNote";
import Banner from "../Banner";
import FootNote from "../FootNote/FootNote";
import { scrollTop } from "../../Utils/Scroll/ScrollUtils";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";

const Product = ({
  match,
  products,
  applyHandler,
  contactOpenHandler,
  status,
  setStatus,
}) => {
  useEffect(() => {
    if (status === "2") setStatus("1");
  }, []);

  const { trackPageView } = useMatomo();
  if (products === undefined) return <></>;
  const productName = match.params.id;
  const product = { ...products[productName] };

  return (
    <>
      {products && product ? (
        <div
          id="valuation"
          className="g-grid none result"
          style={{ paddingTop: "66px" }}
        >
          <Banner text={"房貸產品"} photo={product.banner} />
          <div className="container position-relative">
            <div className="container">
              <div className="row">
                <div className="col-12 Product">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center">
                        <h5 className="h5 line brown mt-3">{product.title}</h5>
                        <p className="mt-5 p2">{product.ad}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <h4 className="h4 mb-3">產品說明</h4>
                </div>
                <ProductDescriptions content={{ ...product.content }} />
                <MsgNote note={product.note} />
              </div>
            </div>
          </div>
          {scrollTop()}

          {trackPageView({
            documentTitle: `房貸產品-${product.title}`, // optional
            //202208 Vulnerability Project Modify -- begin
            //因非必須資料且為高風險資料，因此mark
           // href: hashReplacer(window.document.location.href), // optional
            //202208 Vulnerability Project Modify -- end
          })}
        </div>
      ) : (
        ""
      )}
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      />
    </>
  );
};

export default Product;
