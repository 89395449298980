import React, { useState, useLayoutEffect } from "react";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const LineShareButton = ({ obj, type }) => {
  const [width, height] = useWindowSize();

  const clickShared = () => {
    var content = "";

    if (width < 800) {
      content =
        obj.url === ""
          ? `http://line.naver.jp/R/msg/text/?${obj.message}`
          : `http://line.naver.jp/R/msg/text/?${obj.message}${obj.url}`;
    } else {
      content = `https://social-plugins.line.me/lineit/share?url=${obj.url}&text=${obj.message}&from=line_scheme`;
    }

    window.open(
      encodeURI(content),
      "線上客服",
      "config=width=400,height=640,toolbar=no,location=no,menubar=no,status=no"
    );
  };

  return (
    <>
      {type === "web" ? (
        <li className="line link-social">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              clickShared();
            }}
          >
            <i className="fab fa-line"></i>
          </a>
        </li>
      ) : (
        <span className="line">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              clickShared();
            }}
          >
            <i className="fab fa-line"></i>
          </a>
        </span>
      )}
    </>
  );
};

export default LineShareButton;
