import React from "react";

const FBShareButton = ({ obj, type }) => {
  const clickShared = () => {
    window.open(
      encodeURI(`https://facebook.com/sharer/sharer.php?u=${obj.url}&quote=${obj.message}`),
      "線上客服",
      "config=width=400,height=640,toolbar=no,location=no,menubar=no,status=no"
    );
  };

  return (
    <>
      {type === "web" ? (
        <li className="facebook link-social">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              clickShared();
            }}
          >
            <i className="fab fa-facebook-square" ></i>
          </a>
        </li>
      ) : (
        <span className="facebook">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              clickShared();
            }}
          >
            <i className="fab fa-facebook-square" style={{"margin":"5px"}}></i>
          </a>
        </span>
      )}
    </>
  );
};

export default FBShareButton;
