import React from "react";
import Photos from "../../Photo";
const Loading = ({isloading=false}) => {

  if(!isloading)return <></>

  return (
    <div className="loading-cover">
      <div className="loading-txt">
        <img src={Photos.loading} alt="" />
        <span>Loading......</span>
      </div>
    </div>
  );
};

export default Loading;
