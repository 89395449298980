import React, { useState, useEffect } from "react";
import Marquee from "./Marquee";
import Content from "./Content";
import Banner from "./Banner/Banner";
import FootNote from "../FootNote/FootNote";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";
const DefaultPage = ({
  applyHandler,
  contactOpenHandler,
  status,
  setStatus,
}) => {
  
  const { trackPageView } = useMatomo()
  useEffect(() => {
    if (status === "2") setStatus("1");
    trackPageView({
      documentTitle: '首頁', // optional
      href: hashReplacer(window.document.location.href), // optional
    })
  }, []);

  const [open, setOpen] = useState(true);

  const openHandler = (result) => {
    setOpen(result);
  };


  return (
    <>
      <main className="main-wrap">
        <div className="main-content">
          {open && <Marquee setOpen={openHandler} />}
          <Banner />
          <Content />
        </div>
      </main>
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      />
    </>
  );
};

export default DefaultPage;
