import React, { useState } from "react";
import { sendSurvey } from "../Fetch/fetchBackend";

const Rating = ({ status, setOpen }) => {
  const [Rating, setRating] = useState({
    step: status,
    star: "5",
    accomplished: "1",
    comment: "",
  });

  const ratingHandler = (e) => {
    let thisRating = { ...Rating };
    thisRating[e.target.name] = e.target.value;

    setRating(thisRating);
  };

  const sendRatingHandler = () => {
    if (Rating.comment.length > 50)
      return alert(
        `請將回饋字數減少至50字，目前字數為${Rating.comment.length}字`
      );

    alert("感謝您填寫此問券");
    sendSurvey(Rating);
    setOpen();
  };

  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden",marginTop:"65px" }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div
              id="survey-popup"
              className="popup g-popup-group survey-group mfp-with-zoom"
              style={{ marginTop:"65px" }}
            >
              <form id="survey-form">
                <p className="text">本次造訪是否達成您的目的？</p>
                <div className="radio-group">
                  <div className="el-radio el-radio-yellow">
                    <input
                      type="radio"
                      name="purpose"
                      id="r1"
                      value="1"
                      checked
                      onChange={(e) => {
                        ratingHandler(e);
                      }}
                    />
                    <label className="el-radio-style" htmlFor="r1"></label>是
                  </div>
                  <div className="el-radio el-radio-yellow">
                    <input
                      type="radio"
                      name="purpose"
                      id="r2"
                      value="2"
                      onChange={(e) => {
                        ratingHandler(e);
                      }}
                    />
                    <label className="el-radio-style" htmlFor="r2"></label>否
                  </div>
                </div>
                <p className="text">本網站使用體驗，您給我幾顆星？</p>
                <div className="g-rating-group">
                  <div className="rating-star clearfix">
                    <input
                      type="radio"
                      id="star5"
                      name="star"
                      value="5"
                      defaultChecked={true}
                      onClick={(e) => ratingHandler(e)}
                    />
                    <label htmlFor="star5"></label>
                    <input
                      type="radio"
                      id="star4"
                      name="star"
                      value="4"
                      onClick={(e) => ratingHandler(e)}
                    />
                    <label htmlFor="star4"></label>
                    <input
                      type="radio"
                      id="star3"
                      name="star"
                      value="3"
                      onClick={(e) => ratingHandler(e)}
                    />
                    <label htmlFor="star3"></label>
                    <input
                      type="radio"
                      id="star2"
                      name="star"
                      value="2"
                      onClick={(e) => ratingHandler(e)}
                    />
                    <label htmlFor="star2"></label>
                    <input
                      type="radio"
                      id="star1"
                      name="star"
                      value="1"
                      onClick={(e) => ratingHandler(e)}
                    />
                    <label htmlFor="star1"></label>
                  </div>
                </div>
                <textarea
                  className="textarea"
                  name="comment"
                  cols="30"
                  rows="3"
                  placeholder="我還有話想說（50字以內）"
                  onChange={(e) => {
                    ratingHandler(e);
                  }}
                ></textarea>
                <input
                  name="step"
                  type="text"
                  style={{ display: "none" }}
                  readOnly="readonly"
                  value={status}
                />
                <div className="btn-group">
                  <a
                    id="survey-form-submit"
                    className="btn btn-search sm btnHight QA"
                    title="送出"
                    onClick={(e) => {
                      e.preventDefault();
                      sendRatingHandler();
                    }}
                  >
                    送出
                  </a>
                </div>
              </form>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={setOpen}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rating;
