
import { zipCodeData } from "../../Utils/AddTwZipCode/zipCodeData";

const getLocation = setData => {
  let lat = "",
    lng = "";
  if (navigator.geolocation) {

    navigator.geolocation.getCurrentPosition(setPosition);

  } else {
    alert("抱歉，您的瀏覽器不支援 Google 定位服務");
  }
  function setPosition(position) {

    lat = position.coords.latitude;
    lng = position.coords.longitude;

    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ latLng: latlng }, function(results, status) {
  
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results) {
          let addressData = results[0];
          let leng = results[0].address_components.length;
          let tnum = leng - 3;
          let tanum = leng - 4;
          let target = addressData.address_components[tnum].long_name; //抓縣市
          let findT = target.split("");
          if (findT[0] === "台") {
            //縣市名稱切換
            findT[0] = "臺";
          }
          const county = findT.join("");
          const district = addressData.address_components[tanum].long_name;
          const addr =
            addressData.address_components[1].long_name +
            addressData.address_components[0].long_name;

          const zipcode = {...zipCodeData[county]}[district];
          const newData = {
            county: county,
            district: district,
            zipcode: zipcode,
            addr: addr
          };
          setData(newData)
        }
      } else {
        console.log("Reverse Geocoding failed because: " + status);
      }
    });
  }
};


export  {getLocation};
