import React from "react";
import House from "./FormUI/House";
import Alert from "../Alert";
import { getPlaceHolder } from "../../Utils/DefaultSetting/PlaceHolder";

const Form = ({ data, dataHandler, alert, resultOpen }) => {
  return (
    <div
      className={
        resultOpen
          ? "ng-search-more ng-form nbrown disable"
          : "ng-search-more ng-form nbrown"
      }
    >
      <h4 className="h4">
        房屋資料<span className="f-xs">*所有欄位均為必填</span>
      </h4>
      <House dataHandler={dataHandler} B_TYPE_INT={data.B_TYPE_INT} />
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="form-group">
            <label>屋齡</label>
            <div className="search-input">
              <input
                type="number"
                className={
                  alert.B_AGE ? "form-control empty-note" : "form-control"
                }
                id="B_AGE"
                name="B_AGE"
                placeholder={getPlaceHolder("house_age")}
                value={data.B_AGE}
                onChange={(e) => {
                  dataHandler(e);
                }}
              />
              <span>年</span>
              <Alert AlertItem={alert.B_AGE} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-group">
            <label>房屋總坪數(含公設、不含車位)</label>
            <div className="search-input">
              <input
                type="number"
                className={
                  alert.B_AREA_P ? "form-control empty-note" : "form-control"
                }
                id="B_AREA_P"
                name="B_AREA_P"
                placeholder={getPlaceHolder("area")}
                onChange={(e) => {
                  dataHandler(e);
                }}
                value={data.B_AREA_P}
              />

              <span>坪</span>
              <Alert AlertItem={alert.B_AREA_P} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="form-group">
            <label>總樓層(不含頂樓加蓋)</label>
            <div className="search-input">
              <input
                type="number"
                className={
                  alert.FLOORS ? "form-control empty-note" : "form-control"
                }
                id="FLOORS"
                name="FLOORS"
                min="1"
                step="1"
                placeholder={getPlaceHolder("floor")}
                onChange={(e) => {
                  dataHandler(e);
                }}
                value={data.FLOORS}
              />
              <span>樓</span>
              <Alert AlertItem={alert.FLOORS} />
            </div>
          </div>
        </div>
        {!(data.B_TYPE_INT === "2") && (
          <div className="col-lg-6 myhouse" style={{ display: "block" }}>
            <div className="form-group floor-group">
              <label htmlFor="" className="setblock">
                所在樓層
              </label>
              <div className="col col-md-7">
                <div
                  className="el-radio el-radio-yellow"
                  style={{ display: "inline-block" }}
                >
                  <input
                    type="radio"
                    name="LEVEL_SELECT"
                    id="4_1"
                    value="1"
                    checked={data.LEVEL_SELECT === "1"}
                    onChange={(e) => {
                      dataHandler(e);
                    }}
                  />
                  <label className="el-radio-style" htmlFor="4_1"></label>
                  <span className="margin-r">地上</span>
                </div>
                <div
                  className="search-input-wrap"
                  style={{ display: "inline-block", marginLeft: "-10px" }}
                >
                  <div className="search-input">
                    <input
                      type="number"
                      className={
                        alert.LEVEL ? "form-control empty-note" : "form-control"
                      }
                      id="floor"
                      name="LEVEL"
                      placeholder={getPlaceHolder("level")}
                      value={data.LEVEL}
                      onChange={(e) => {
                        dataHandler(e);
                      }}
                      disabled={data.LEVEL_SELECT === "2"}
                    />
                    <span>樓</span>
                    <Alert AlertItem={alert.LEVEL} />
                  </div>
                </div>
              </div>
              <div className="col col-md-5">
                <div
                  className="el-radio el-radio-yellow basement"
                  style={{ width: "100%" }}
                >
                  <input
                    type="radio"
                    name="LEVEL_SELECT"
                    id="5"
                    value="2"
                    checked={data.LEVEL_SELECT === "2"}
                    onChange={(e) => {
                      dataHandler(e);
                    }}
                  />
                  <label className="el-radio-style" htmlFor="5"></label>
                  <span className="margin-r">地下樓層</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-12 park-group form-group">
          <label htmlFor="" className="setblock">
            車位
          </label>
          <div className="el-radio el-radio-yellow space">
            <input
              type="radio"
              name="PARKING"
              id="no-parking-space"
              onChange={(e) => {
                dataHandler(e);
              }}
              value="1"
              checked={data.PARKING === "1"}
            />
            <label
              className="el-radio-style"
              htmlFor="no-parking-space"
            ></label>
            <span className="spantop">不含車位</span>
          </div>
          <div className="el-radio el-radio-yellow">
            <input
              type="radio"
              name="PARKING"
              id="mechanical-parking-space"
              onClick={(e) => {
                dataHandler(e);
              }}
              value="2"
            />
            <label
              className="el-radio-style"
              htmlFor="mechanical-parking-space"
            ></label>
            <span className="spantop">機械車位</span>
          </div>
          <div className="el-radio el-radio-yellow">
            <input
              type="radio"
              name="PARKING"
              id="non-mechanical-parking-space"
              onClick={(e) => {
                dataHandler(e);
              }}
              value="3"
            />
            <label
              className="el-radio-style"
              htmlFor="non-mechanical-parking-space"
            ></label>
            <span className="spantop">非機械車位</span>
          </div>
        </div>
        {!(data.PARKING === "1") && (
          <>
            <div className="col-lg-3 col-md-12 form-group">
              <label htmlFor="">車位個數</label>
              <div className="search-input">
                <input
                  type="number"
                  id="parking-space-number"
                  name="parking_qty"
                  className={
                    alert.parking_qty
                      ? "form-control empty-note"
                      : "form-control"
                  }
                  placeholder={getPlaceHolder("carNum")}
                  min="1"
                  step="1"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onChange={(e) => dataHandler(e)}
                  value={data.parking_qty}
                />
                <span>個</span>
                <Alert AlertItem={alert.parking_qty} />
              </div>
            </div>
            <div className="col-lg-3 col-md-12 form-group">
              <label htmlFor="">車位總面積</label>
              <div className="search-input">
                <input
                  type="number"
                  id="parking-space-volume"
                  className={
                    alert.parking_p ? "form-control empty-note" : "form-control"
                  }
                  name="parking_p"
                  placeholder={getPlaceHolder("carArea")}
                  onChange={(e) => dataHandler(e)}
                  value={data.parking_p}
                />
                <span>坪</span>
                <Alert AlertItem={alert.parking_p} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
