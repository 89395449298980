import React from "react";
import BlockUnit from "./BlockUnit";
import { formatFilter } from "../../Utils/Filter/Filter";

const PriceBlock = ({ datas }) => {
  if (datas === undefined) return <></>;
  const blocks = EstimatePriceBlock.map((name, i) => {
    const average = formatFilter(datas[name].av, "housePrice");
    const minValue = formatFilter(datas[name].min, "housePrice");
    const maxValue = formatFilter(datas[name].max, "housePrice");
    if (minValue === "0.0") {
      return null;
    }
    return (
      <BlockUnit
        key={i}
        name={name}
        avg={average}
        min={minValue}
        max={maxValue}
      />
    );
  });

  return (
    <div className="row">
      <div id="valuation" className="result">
        <div className="item-group mg-10 c-row refont">{blocks}</div>
      </div>
    </div>
  );
};

export default PriceBlock;

const EstimatePriceBlock = [
  "電梯大樓 / 華廈",
  "無電梯公寓",
  "套房",
  "別墅 / 透天厝",
];
