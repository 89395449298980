import React from "react";

const FillForm = () => {
  return (
    <>
      <h3 className="h3 mg-25">請填入必要資訊</h3>
      <p className="text mg-25">
        請填入姓名、行動電話、房屋縣市地址，將由鄰近分行專員主動聯繫您
      </p>
    </>
  );
};

export default FillForm;
