import React, { useState } from "react";
import Container1 from "./Container1";
import Container2 from "./Container2";
import FootNote from "../FootNote/FootNote";
import BottomLink from "./BottomLink";
import Loading from "../Loading";

const Estimate = ({ data, dataHandler, applyHandler, contactOpenHandler }) => {
  const [resultOpen, setResultOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const resultHandler = (result) => {
    setResultOpen(result);
  };

  const loadingHandler = (event) => {
    setIsLoading(event);
  };
  if (data === undefined) return <></>;
  return (
    <>
      <main className="main-wrap">
        <div className="main-content">
          <div className="title-price-check title-inner">
            <h2
            // style={{ zIndex: "0" }}
            >
              您家附近實價登錄單價
            </h2>
          </div>
          <div className="container inner-content">
            <Container1
              data={data}
              dataHandler={dataHandler}
              setMapCenter={setMapCenter}
              setLoading={loadingHandler}
            />
            {!mapCenter && <div className="estimate" />}
            {mapCenter && (
              <Container2
                mapCenter={mapCenter}
                resultOpen={resultOpen}
                resultHandler={resultHandler}
                setLoading={loadingHandler}
                addrData={data}
              />
            )}
          </div>
        </div>
        <Loading isloading={isloading} />
      </main>
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      >
        {resultOpen && <BottomLink />}
      </FootNote>
    </>
  );
};

export default Estimate;
