import React from "react";

const Icon = buildingName => {
  switch (buildingName) {
    case "Landmark":
      return;
    case "Apartment":

    default:
      return;
  }
};

const Apartment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="45px"
      viewBox="0 0 50 45"
      version="1.1"
    >
      <g
        id="icon-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M39.1976923,4.79646154 C38.7715385,4.79646154 38.4269231,5.14107692 38.4269231,5.56723077 L38.4269231,15.2233846 L29.6361538,15.2233846 C29.2107692,15.2233846 28.8653846,15.568 28.8653846,15.9941538 L28.8653846,23.9472308 L19.8846154,23.9472308 C19.4584615,23.9472308 19.1138462,24.2926154 19.1138462,24.718 L19.1138462,33.9187692 L10.51,33.9187692 C10.0838462,33.9187692 9.73923077,34.2641538 9.73923077,34.6895385 L9.73923077,43.258 L0.770769231,43.258 C0.344615385,43.258 0,43.6026154 0,44.0287692 C0,44.4549231 0.344615385,44.7995385 0.770769231,44.7995385 L10.51,44.7995385 C10.9361538,44.7995385 11.2807692,44.4549231 11.2807692,44.0287692 L11.2807692,35.4603077 L19.8846154,35.4603077 C20.31,35.4603077 20.6553846,35.1156923 20.6553846,34.6895385 L20.6553846,25.4887692 L29.6361538,25.4887692 C30.0623077,25.4887692 30.4069231,25.1441538 30.4069231,24.718 L30.4069231,16.7649231 L39.1976923,16.7649231 C39.6238462,16.7649231 39.9684615,16.4195385 39.9684615,15.9941538 L39.9684615,6.338 L48.4630769,6.338 C48.8892308,6.338 49.2338462,5.99338462 49.2338462,5.56723077 C49.2338462,5.14107692 48.8892308,4.79646154 48.4630769,4.79646154 L39.1976923,4.79646154 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M6.65614391,18.2738533 L17.4346587,7.49533853 L17.4346587,14.739382 C17.4346587,15.1718509 17.7848604,15.5220527 18.2173293,15.5220527 C18.6497983,15.5220527 19,15.1718509 19,14.739382 L19,5.6824408 C19,5.24997186 18.6497983,4.89977014 18.2173293,4.89977014 L9.16038815,4.89977014 C8.72791921,4.89977014 8.37771748,5.24997186 8.37771748,5.6824408 C8.37771748,6.11490974 8.72791921,6.46511147 9.16038815,6.46511147 L16.4051864,6.46511147 L5.62667159,17.2436262 C5.34213269,17.5274104 5.34213269,17.9893144 5.62591685,18.2730985 C5.76856367,18.4149906 5.95498571,18.4866914 6.14140775,18.4866914 C6.32782979,18.4866914 6.51425183,18.4149906 6.65614391,18.2738533 Z"
          id="Fill-1"
          fill="#A17D58"
        />
      </g>
    </svg>
  );
};
const Landmark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="45px"
      viewBox="0 0 40 45"
      version="1.1"
    >
      <g
        id="icon-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon-1" transform="translate(0.000000, 2.000000)" fill="#A17D58">
          <path
            d="M31.4285714,41.5669604 L31.4285714,9.31679094 C31.4285714,8.88633676 31.1427896,8.59959327 30.713779,8.59959327 L17.8576496,8.59959327 L5.0001689,8.59959327 C4.57183394,8.59959327 4.28605209,8.88633676 4.28605209,9.31679094 L4.28605209,41.5669604 L1.42958484,41.5669604 L1.42958484,1.43303959 L38.5704152,1.43303959 L38.5704152,41.5669604 L31.4285714,41.5669604 Z M18.5717664,41.5669604 L30.0003378,41.5669604 L30.0003378,10.0326329 L18.5717664,10.0326329 L18.5717664,41.5669604 Z M5.71428571,41.5669604 L17.1421815,41.5669604 L17.1421815,10.0326329 L5.71428571,10.0326329 L5.71428571,41.5669604 Z M39.2852076,0 L0.71479242,0 C0.286457454,0 0,0.286743493 0,0.715841912 L0,42.2834802 C0,42.7132565 0.286457454,43 0.71479242,43 L5.0001689,43 L17.8576496,43 L30.713779,43 L39.2852076,43 C39.7142182,43 40,42.7132565 40,42.2834802 L40,0.715841912 C40,0.286743493 39.7142182,0 39.2852076,0 Z"
            id="Fill-1"
          />
          <path
            d="M20.9373374,5.66687687 L14.0626626,5.66687687 C13.6503902,5.66687687 13.3753251,5.40012612 13.3753251,4.99968469 C13.3753251,4.59987388 13.6503902,4.33375374 14.0626626,4.33375374 L20.9373374,4.33375374 C21.3502601,4.33375374 21.6253251,4.59987388 21.6253251,4.99968469 C21.6253251,5.40012612 21.3502601,5.66687687 20.9373374,5.66687687 M20.9373374,3 L14.0626626,3 C12.8941239,3 12,3.86709759 12,4.99968469 C12,6.13290241 12.8941239,7 14.0626626,7 L20.9373374,7 C22.1058761,7 23,6.13290241 23,4.99968469 C23,3.86709759 22.1058761,3 20.9373374,3"
            id="Fill-1"
          />
          <path
            d="M34.1329471,25.7749734 L35.0000788,24.7996808 L35.86658,25.7749734 C36.0002759,25.9246365 36.1333412,25.9991134 36.3332545,25.9991134 C36.5337984,25.9991134 36.6668637,25.9246365 36.7999291,25.7749734 C37.0666903,25.4742286 37.0666903,25.0245301 36.7999291,24.7252039 L35.4667534,23.2250266 C35.1993615,22.9249911 34.8001655,22.9249911 34.5334043,23.2250266 L33.199598,24.7252039 C32.9334673,25.0245301 32.9334673,25.4742286 33.199598,25.7749734 C33.4663592,26.0750089 33.8661858,26.0750089 34.1329471,25.7749734"
            id="Fill-1"
          />
          <path
            d="M35.8666929,29.2255852 L35.0001577,30.2002838 L34.1329917,29.2255852 C33.8668506,28.9255143 33.4663776,28.9255143 33.1996058,29.2255852 C32.9334647,29.5256562 32.9334647,29.9754079 33.1996058,30.2747694 L34.5334647,31.7751241 C34.6665353,31.9255143 34.8002365,32 35.0001577,32 C35.1994482,32 35.3331494,31.9255143 35.4668506,31.7751241 L36.7994482,30.2747694 C37.0668506,29.9754079 37.0668506,29.5256562 36.7994482,29.2255852 C36.5339377,28.9248049 36.1334647,28.9248049 35.8666929,29.2255852"
            id="Fill-1"
          />
        </g>
      </g>
    </svg>
  );
};

const Suite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="50px"
      height="45px"
      viewBox="0 0 50 45"
      version="1.1"
    >
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="建物類型/icon-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M48.4366748,38.4872701 L1.56332524,38.4872701 L1.56332524,29.784383 C1.56332524,28.5308807 2.61434457,27.5144354 3.90655259,27.5144354 L46.0934474,27.5144354 C47.3856554,27.5144354 48.4366748,28.5308807 48.4366748,29.784383 L48.4366748,38.4872701 Z M46.0934474,26 L3.90655259,26 C1.75521989,26 0,27.6986235 0,29.784383 L0,39.2427823 C0,39.6657327 0.35386078,40 0.783423119,40 L49.2165769,40 C49.6478997,40 50,39.6657327 50,39.2427823 L50,29.784383 C50,27.6986235 48.2465406,26 46.0934474,26 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M41.6711383,20 L7.29734868,20 C7.0579205,20 6.83257632,20.1048331 6.6864547,20.2900381 L1.39497773,27.0727373 L3.22002746,27.0727373 L7.67409598,21.5515293 L41.294391,21.5515293 L45.7484596,27.0727373 L46.968487,26.1047787 L42.2820323,20.2900381 C42.1359107,20.1048331 41.9105665,20 41.6711383,20 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M41.1534128,2.5480349 C40.7321777,2.5480349 40.3854821,2.89398401 40.3854821,3.32379957 L40.3854821,5.46239406 C38.2307692,3.7798234 33.3891658,1 25.0008667,1 C17.3596966,1 11.9512459,4.0366644 9.61625135,5.64759914 L9.61625135,3.32379957 C9.61625135,2.89398401 9.2695558,2.5480349 8.84658722,2.5480349 L5.76966414,2.5480349 C5.34669556,2.5480349 5,2.89398401 5,3.32379957 L5,23.1386681 C5,23.5684837 5.34669556,23.4849449 5.76966414,23.4849449 C6.19263272,23.4849449 6.5375948,22.7572054 6.5375948,22.3273899 L6.5375948,4.10131146 L8.07692308,4.10131146 L8.07692308,7.19912848 C8.07692308,7.49964993 8.25373781,7.77571033 8.52936078,7.91024609 C8.80325027,8.0290569 9.12394366,7.98537645 9.35102925,7.78619363 C9.41343445,7.73552432 15.4320693,2.5480349 25.0008667,2.5480349 C35.9945829,2.5480349 40.5293608,7.65689952 40.5726977,7.7040744 C40.784182,7.9504321 41.1204767,8.0395402 41.4238353,7.92597105 C41.7219935,7.81065468 41.9230769,7.52760541 41.9230769,7.19912848 L41.9230769,4.10131146 L43.4624052,4.10131146 L43.4624052,23.4849449 C43.4624052,23.9147604 43.8073673,24.2589623 44.2303359,24.2589623 C44.6533044,24.2589623 45,23.9147604 45,23.4849449 L45,3.32379957 C45,2.89398401 44.6533044,2.5480349 44.2303359,2.5480349 L41.1534128,2.5480349 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M20.9854935,19.2008757 L11.0738706,19.2008757 C10.8696525,18.681952 10.5615647,17.7664099 10.5615647,16.8770761 C10.5615647,15.9825007 10.8696525,15.0669586 11.0738706,14.5497821 L20.9854935,14.5497821 C21.1914721,15.0669586 21.4977994,15.9825007 21.4977994,16.8770761 C21.4977994,17.7664099 21.1914721,18.681952 20.9854935,19.2008757 Z M21.4977994,13 L10.5615647,13 C10.26404,13 9.99468329,13.1642385 9.86440618,13.4298156 C9.82919616,13.4979571 9,15.1595611 9,16.8770761 C9,18.5893495 9.82919616,20.2509535 9.86440618,20.319095 C9.99468329,20.5864194 10.26404,20.752405 10.5615647,20.752405 L21.4977994,20.752405 C21.7970846,20.752405 22.0646808,20.5864194 22.1984789,20.319095 C22.230168,20.2509535 23.0611246,18.5893495 23.0611246,16.8770761 C23.0611246,15.1595611 22.230168,13.4979571 22.1984789,13.4298156 C22.0646808,13.1642385 21.7970846,13 21.4977994,13 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M37.987254,19.2008757 L28.0756311,19.2008757 C27.871413,18.681952 27.5633252,17.7664099 27.5633252,16.8770761 C27.5633252,15.9825007 27.871413,15.0669586 28.0756311,14.5497821 L37.987254,14.5497821 C38.1914721,15.0669586 38.4995599,15.9825007 38.4995599,16.8770761 C38.4995599,17.7664099 38.1914721,18.681952 37.987254,19.2008757 Z M39.1967184,13.4298156 C39.0664413,13.1642385 38.7988451,13 38.4995599,13 L27.5633252,13 C27.26404,13 26.9964438,13.1642385 26.8661667,13.4298156 C26.8309567,13.4979571 26,15.1595611 26,16.8770761 C26,18.5893495 26.8309567,20.2509535 26.8661667,20.319095 C26.9964438,20.5864194 27.26404,20.752405 27.5633252,20.752405 L38.4995599,20.752405 C38.7988451,20.752405 39.0664413,20.5864194 39.1967184,20.319095 C39.2319285,20.2509535 40.0628851,18.5893495 40.0628851,16.8770761 C40.0628851,15.1595611 39.2319285,13.4979571 39.1967184,13.4298156 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M6.90655259,42.6973911 C6.90655259,43.2587253 6.37840217,43.7160521 5.73229816,43.7160521 C5.08795465,43.7160521 4.56156473,43.2587253 4.56156473,42.6973911 L4.56156473,39.9824911 L6.90655259,39.9824911 L6.90655259,42.6973911 Z M7.6864547,38.6258059 L3.77990212,38.6258059 C3.35033978,38.6258059 3,38.9271215 3,39.3033837 L3,42.6973911 C3,44.0097202 4.22530897,45.0727373 5.73229816,45.0727373 C7.24280835,45.0727373 8.46811732,44.0097202 8.46811732,42.6973911 L8.46811732,39.3033837 C8.46811732,38.9271215 8.11601704,38.6258059 7.6864547,38.6258059 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M44.9065526,42.6973911 C44.9065526,43.2587253 44.3801627,43.7160521 43.7358192,43.7160521 C43.0897152,43.7160521 42.5633252,43.2587253 42.5633252,42.6973911 L42.5633252,39.9824911 L44.9065526,39.9824911 L44.9065526,42.6973911 Z M45.6882152,38.6258059 L41.7816626,38.6258059 C41.3521003,38.6258059 41,38.9271215 41,39.3033837 L41,42.6973911 C41,44.0097202 42.225309,45.0727373 43.7358192,45.0727373 C45.2428084,45.0727373 46.4681173,44.0097202 46.4681173,42.6973911 L46.4681173,39.3033837 C46.4681173,38.9271215 46.1177775,38.6258059 45.6882152,38.6258059 Z"
          id="Fill-1"
          fill="#A17D58"
        />
      </g>
    </svg>
  );
};

const Villa = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="45px"
      viewBox="0 0 65 45"
      version="1.1"
    >
      <g
        id="icon-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M39.1923659,21.586583 L39.1877179,19.3047192 C39.1877179,19.075258 39.0808127,18.8633251 38.8948907,18.7310663 L22.391211,6.74968789 C22.2750097,6.660453 22.1324695,6.62220947 21.9961266,6.62220947 C21.8582344,6.62220947 21.7172436,6.66523344 21.5948449,6.74968789 L5.10046128,18.833049 C4.92073665,18.9605274 4.81228212,19.1772408 4.81228212,19.4067019 L4.81228212,21.5818025 L1.39596465,21.5818025 L1.37582309,17.1089033 L21.0804606,2.69268705 C21.5359696,2.35646271 22.3354343,2.26882129 22.9148914,2.69746749 L42.6210782,17.1136838 L42.6257263,21.5818025 L39.1923659,21.586583 Z M43.9953519,16.7455898 C43.9953519,16.5161287 43.8884468,16.3041958 43.7087221,16.1719369 L23.7190042,1.54378775 C22.8002394,0.868152104 21.3268073,0.770949806 20.2809958,1.54378775 L0.288179161,16.1719369 C0.106905173,16.3041958 0,16.5209091 0,16.7503703 L0.0263389556,22.2877143 C0.0263389556,22.6765235 0.334659671,22.9952196 0.712701151,22.9952196 L5.50019367,22.9952196 C5.87823515,22.9952196 6.18655586,22.6765235 6.18655586,22.2877143 L6.18655586,19.7700154 L21.9961266,8.19497453 L37.8118948,19.6712197 L37.8165428,22.2924947 C37.8165428,22.6813039 38.1264129,23 38.5060037,23 L43.3136378,22.9952196 C43.6916793,22.9952196 44,22.6765235 44,22.2877143 L43.9953519,16.7455898 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M35.5459266,2 L30.4320026,2.01328658 C29.6686141,2.01328658 29.0571243,2.68794981 29,3.53976748 L30.1308017,4.45211294 L30.1308017,3.66672818 C30.1308017,3.47776342 30.2632262,3.32275328 30.4320026,3.32275328 L35.5459266,3.31094298 C35.7121065,3.31094298 35.8471276,3.46742942 35.8471276,3.65344159 L35.8471276,9.07879683 L36.9857189,10 C36.9896138,9.97195054 37,9.9424248 37,9.91585163 L37,3.65344159 C37,2.74109614 36.3482636,2 35.5459266,2"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M38.5583531,22 L38.5583531,43.0540054 C38.5583531,43.1971947 38.4869247,43.2938044 38.4463404,43.340384 C38.3700419,43.4266427 38.2677694,43.469772 38.1541334,43.469772 L5.8279174,43.469772 C5.60713874,43.469772 5.42207428,43.2679268 5.42207428,43.0436544 L5.42207428,22 L4,22 L4,43.0384788 C4,44.120162 4.81006286,45 5.8279174,45 L38.1541334,45 C38.6541321,45 39.1184166,44.7947045 39.4690651,44.4168917 C39.8164668,44.0477048 40.004778,43.5612061 39.9999078,43.0488299 L40,22 L38.5583531,22"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M63.253458,26.3606233 C62.9430626,28.9181302 62.3476103,31.1397069 61.5779565,32.6165324 C60.8890689,33.9539152 59.7440901,34.7462036 58.5246797,34.7462036 L58.4724193,34.7462036 C57.2498417,34.7462036 56.1096138,33.9539152 55.4175589,32.6165324 C54.6447378,31.131784 54.0524528,28.9070382 53.7468084,26.3606233 C52.7887002,18.368018 53.7499757,9.55460188 56.248975,4.28429949 C56.8665984,2.97702364 57.9498149,1.69510102 58.4945904,1.41938466 C59.0472842,1.69510102 60.128917,2.97702364 60.7512913,4.28905322 C63.2518743,9.55777103 64.2083988,18.3711871 63.253458,26.3606233 Z M62.000791,3.69325234 C61.5906257,2.829658 60.092493,0.295919715 58.6260333,0.0154496237 C58.5436835,-0.00514987455 58.4502482,-0.00514987455 58.3678984,0.0154496237 C56.9030224,0.295919715 55.4048897,2.829658 54.996308,3.69325234 C52.3896205,9.18698006 51.3871702,18.2903737 52.3722004,26.5254193 C52.6952649,29.2350456 53.3429777,31.6261719 54.1902303,33.2551169 C55.0058099,34.8317708 56.3329084,35.8443153 57.8342084,36.0788327 L57.8342084,45 L59.2167347,45 L59.2167347,36.0693252 C60.689529,35.8237158 61.9960401,34.8127558 62.8068687,33.2551169 C63.8900852,31.1777367 64.3952695,28.4475109 64.6264823,26.5254193 C65.6146798,18.2903737 64.6090622,9.18698006 62.000791,3.69325234 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M53.9623524,34.5363307 C53.7161618,34.5985959 53.4535585,34.6328418 53.1836607,34.623502 C52.3630255,34.6328418 51.1958999,34.2109949 50.4646227,33.0077197 C49.7570528,31.8433602 49.2117863,30.1014908 48.9309467,28.0965509 C48.0391898,21.787528 48.9291231,14.7266527 51.2378435,10.5704496 C51.5533321,10.0038362 51.9891806,9.45434564 52.3940273,9.04339522 C52.6037452,8.33824167 52.8444648,7.66110746 53.0961263,7.00109619 C53.0742427,7.00265282 53.0560064,6.99798292 53.0359464,7.00109619 C51.613512,7.23614737 50.1874304,9.28622956 49.7971728,9.98982648 C47.3644453,14.3624012 46.4270975,21.7143664 47.3480326,28.2568838 C47.6489321,30.4221566 48.2562022,32.3305855 49.0513065,33.6319285 C49.9120617,35.0562452 51.2487853,35.7178131 52.4177345,35.9123919 L52.4177345,45 L54.0134141,45 L54.0134141,35.9030522 C54.3489627,35.8423436 54.6845114,35.7582855 55,35.6259719 C54.6206842,35.3021928 54.2705465,34.9410546 53.9623524,34.5363307"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M18.7257399,27.5385305 C18.7257399,27.7292115 18.5506726,27.8860215 18.3268161,27.8860215 L13.6717489,27.8860215 C13.4493274,27.8860215 13.2742601,27.7292115 13.2742601,27.5385305 L13.2742601,23.4602151 C13.2742601,23.2657706 13.4493274,23.112724 13.6717489,23.112724 L18.3268161,23.112724 C18.5506726,23.112724 18.7257399,23.2657706 18.7257399,23.4602151 L18.7257399,27.5385305 Z M18.3268161,22 L13.6717489,22 C12.7490583,22 12,22.6560932 12,23.4602151 L12,27.5385305 C12,28.3413978 12.7490583,29 13.6717489,29 L18.3268161,29 C19.2509417,29 20,28.3413978 20,27.5385305 L20,23.4602151 C20,22.6560932 19.2509417,22 18.3268161,22 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M30.7255113,27.5385305 C30.7255113,27.7292115 30.5504126,27.8860215 30.3279512,27.8860215 L25.6706136,27.8860215 C25.4481521,27.8860215 25.2730535,27.7292115 25.2730535,27.5385305 L25.2730535,23.4602151 C25.2730535,23.2657706 25.4481521,23.112724 25.6706136,23.112724 L30.3279512,23.112724 C30.5504126,23.112724 30.7255113,23.2657706 30.7255113,23.4602151 L30.7255113,27.5385305 Z M30.3279512,22 L25.6706136,22 C24.7463222,22 24,22.6560932 24,23.4602151 L24,27.5385305 C24,28.3413978 24.7463222,29 25.6706136,29 L30.3279512,29 C31.2522426,29 32,28.3413978 32,27.5385305 L32,23.4602151 C32,22.6560932 31.2522426,22 30.3279512,22 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M18.7257399,37.3287868 C18.7257399,37.5513281 18.5506726,37.7264896 18.3268161,37.7264896 L13.6717489,37.7264896 C13.4493274,37.7264896 13.2742601,37.5513281 13.2742601,37.3287868 L13.2742601,32.672649 C13.2742601,32.4458004 13.4493274,32.2749462 13.6717489,32.2749462 L18.3268161,32.2749462 C18.5506726,32.2749462 18.7257399,32.4458004 18.7257399,32.672649 L18.7257399,37.3287868 Z M18.3268161,31 L13.6717489,31 C12.7490583,31 12,31.7494616 12,32.672649 L12,37.3287868 C12,38.2491027 12.7490583,39 13.6717489,39 L18.3268161,39 C19.2509417,39 20,38.2491027 20,37.3287868 L20,32.672649 C20,31.7494616 19.2509417,31 18.3268161,31 Z"
          id="Fill-1"
          fill="#A17D58"
        />
        <path
          d="M30.7255113,37.3287868 C30.7255113,37.5513281 30.5504126,37.7264896 30.3279512,37.7264896 L25.6706136,37.7264896 C25.4481521,37.7264896 25.2730535,37.5513281 25.2730535,37.3287868 L25.2730535,32.672649 C25.2730535,32.4458004 25.4481521,32.2749462 25.6706136,32.2749462 L30.3279512,32.2749462 C30.5504126,32.2749462 30.7255113,32.4458004 30.7255113,32.672649 L30.7255113,37.3287868 Z M30.3279512,31 L25.6706136,31 C24.7463222,31 24,31.7494616 24,32.672649 L24,37.3287868 C24,38.2491027 24.7463222,39 25.6706136,39 L30.3279512,39 C31.2522426,39 32,38.2491027 32,37.3287868 L32,32.672649 C32,31.7494616 31.2522426,31 30.3279512,31 Z"
          id="Fill-1"
          fill="#A17D58"
        />
      </g>
    </svg>
  );
};

const Store = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="41px"
      height="45px"
      viewBox="0 0 41 38"
      version="1.1"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group-4"
          transform="translate(-1.000000, -1.000000)"
          fill="#A17D58"
        >
          <path
            d="M5.26136486,16.4208778 C5.26136486,17.0303677 4.69373345,17.5269276 3.99933103,17.5269276 C3.30682071,17.5269276 2.74108141,17.0303677 2.74108141,16.4208778 L2.74108141,13.4730724 L5.26136486,13.4730724 L5.26136486,16.4208778 Z M6.09956724,12 L1.90098692,12 C1.43931338,12 1.06278454,12.3271649 1.06278454,12.7357058 L1.06278454,16.4208778 C1.06278454,17.8457888 2.37968941,19 3.99933103,19 C5.62275686,19 6.93966172,17.8457888 6.93966172,16.4208778 L6.93966172,12.7357058 C6.93966172,12.3271649 6.56124078,12 6.09956724,12 Z"
            id="Fill-1"
          />
          <path
            d="M10.2105512,16.4208778 C10.2105512,17.0303677 9.64291983,17.5269276 8.94851741,17.5269276 C8.25600709,17.5269276 7.69026779,17.0303677 7.69026779,16.4208778 L7.69026779,13.4730724 L10.2105512,13.4730724 L10.2105512,16.4208778 Z M11.0487536,12 L6.85017331,12 C6.38849976,12 6.01197093,12.3271649 6.01197093,12.7357058 L6.01197093,16.4208778 C6.01197093,17.8457888 7.32887579,19 8.94851741,19 C10.5719432,19 11.8888481,17.8457888 11.8888481,16.4208778 L11.8888481,12.7357058 C11.8888481,12.3271649 11.5104272,12 11.0487536,12 Z"
            id="Fill-1"
          />
          <path
            d="M15.1597376,16.4208778 C15.1597376,17.0303677 14.5921062,17.5269276 13.8977038,17.5269276 C13.2051935,17.5269276 12.6394542,17.0303677 12.6394542,16.4208778 L12.6394542,13.4730724 L15.1597376,13.4730724 L15.1597376,16.4208778 Z M15.99794,12 L11.7993597,12 C11.3376861,12 10.9611573,12.3271649 10.9611573,12.7357058 L10.9611573,16.4208778 C10.9611573,17.8457888 12.2780622,19 13.8977038,19 C15.5211296,19 16.8380345,17.8457888 16.8380345,16.4208778 L16.8380345,12.7357058 C16.8380345,12.3271649 16.4596136,12 15.99794,12 Z"
            id="Fill-1"
          />
          <path
            d="M20.108924,16.4208778 C20.108924,17.0303677 19.5412926,17.5269276 18.8468902,17.5269276 C18.1543799,17.5269276 17.5886406,17.0303677 17.5886406,16.4208778 L17.5886406,13.4730724 L20.108924,13.4730724 L20.108924,16.4208778 Z M20.9471264,12 L16.7485461,12 C16.2868725,12 15.9103437,12.3271649 15.9103437,12.7357058 L15.9103437,16.4208778 C15.9103437,17.8457888 17.2272486,19 18.8468902,19 C20.470316,19 21.7872209,17.8457888 21.7872209,16.4208778 L21.7872209,12.7357058 C21.7872209,12.3271649 21.4087999,12 20.9471264,12 Z"
            id="Fill-1"
          />
          <path
            d="M25.0581104,16.4208778 C25.0581104,17.0303677 24.490479,17.5269276 23.7960766,17.5269276 C23.1035662,17.5269276 22.5378269,17.0303677 22.5378269,16.4208778 L22.5378269,13.4730724 L25.0581104,13.4730724 L25.0581104,16.4208778 Z M25.8963128,12 L21.6977325,12 C21.2360589,12 20.8595301,12.3271649 20.8595301,12.7357058 L20.8595301,16.4208778 C20.8595301,17.8457888 22.1764349,19 23.7960766,19 C25.4195024,19 26.7364073,17.8457888 26.7364073,16.4208778 L26.7364073,12.7357058 C26.7364073,12.3271649 26.3579863,12 25.8963128,12 Z"
            id="Fill-1"
          />
          <path
            d="M30.0072968,16.4208778 C30.0072968,17.0303677 29.4396654,17.5269276 28.7452629,17.5269276 C28.0527526,17.5269276 27.4870133,17.0303677 27.4870133,16.4208778 L27.4870133,13.4730724 L30.0072968,13.4730724 L30.0072968,16.4208778 Z M30.8454992,12 L26.6469188,12 C26.1852453,12 25.8087165,12.3271649 25.8087165,12.7357058 L25.8087165,16.4208778 C25.8087165,17.8457888 27.1256213,19 28.7452629,19 C30.3686888,19 31.6855936,17.8457888 31.6855936,16.4208778 L31.6855936,12.7357058 C31.6855936,12.3271649 31.3071727,12 30.8454992,12 Z"
            id="Fill-1"
          />
          <path
            d="M34.9564832,16.4208778 C34.9564832,17.0303677 34.3888518,17.5269276 33.6944493,17.5269276 C33.001939,17.5269276 32.4361997,17.0303677 32.4361997,16.4208778 L32.4361997,13.4730724 L34.9564832,13.4730724 L34.9564832,16.4208778 Z M35.7946855,12 L31.5961052,12 C31.1344317,12 30.7579028,12.3271649 30.7579028,12.7357058 L30.7579028,16.4208778 C30.7579028,17.8457888 32.0748077,19 33.6944493,19 C35.3178752,19 36.63478,17.8457888 36.63478,16.4208778 L36.63478,12.7357058 C36.63478,12.3271649 36.2563591,12 35.7946855,12 Z"
            id="Fill-1"
          />
          <path
            d="M39.9056695,16.4208778 C39.9056695,17.0303677 39.3380381,17.5269276 38.6436357,17.5269276 C37.9511254,17.5269276 37.3853861,17.0303677 37.3853861,16.4208778 L37.3853861,13.4730724 L39.9056695,13.4730724 L39.9056695,16.4208778 Z M40.7438719,12 L36.5452916,12 C36.0836181,12 35.7070892,12.3271649 35.7070892,12.7357058 L35.7070892,16.4208778 C35.7070892,17.8457888 37.0239941,19 38.6436357,19 C40.2670615,19 41.5839664,17.8457888 41.5839664,16.4208778 L41.5839664,12.7357058 C41.5839664,12.3271649 41.2055455,12 40.7438719,12 Z"
            id="Fill-1"
          />
          <path
            d="M21.400811,-13.2888841 L21.5186476,-13.3430781 C21.7663729,-13.457009 22.0595532,-13.3485471 22.1734841,-13.1008218 C22.2032407,-13.0361206 22.2186476,-12.9657476 22.2186476,-12.8945317 L22.2186476,16.0883611 C22.2186476,16.4749604 21.9052469,16.7883611 21.5186476,16.7883611 L21.5186476,16.7883611 C21.1320483,16.7883611 20.8186476,16.4749604 20.8186476,16.0883611 L20.8186476,-12.3803619 C20.8186476,-12.7708996 21.0459988,-13.1257031 21.400811,-13.2888841 Z"
            id="Fill-1"
            transform="translate(21.518648, 1.700000) rotate(90.000000) translate(-21.518648, -1.700000) "
          />
          <path
            d="M20.7341288,-6.95158165 L20.7440626,-6.95481991 C21.0246588,-7.04629032 21.3262784,-6.89297357 21.4177488,-6.61237735 C21.435182,-6.55889902 21.4440626,-6.50300362 21.4440626,-6.44675552 L21.4440626,31.8075476 C21.4440626,32.1244102 21.1871947,32.381278 20.8703322,32.381278 C20.8278513,32.381278 20.7855018,32.37656 20.7440626,32.3672105 L20.7440626,32.3672105 C20.3347641,32.2748658 20.0440626,31.9112701 20.0440626,31.4916836 L20.0440626,-6.00082349 C20.0440626,-6.43369449 20.3225732,-6.81742031 20.7341288,-6.95158165 Z"
            id="Fill-1"
            transform="translate(20.744063, 12.700000) rotate(90.000000) translate(-20.744063, -12.700000) "
          />
          <rect
            id="Fill-1"
            transform="translate(12.810291, 5.827372) rotate(25.000000) translate(-12.810291, -5.827372) "
            x="12.1102913"
            y="0.827371713"
            width="1.39999998"
            height="10"
            rx="0.699999988"
          />
          <path
            d="M4.41564699,0.664948877 L4.41564699,0.664948877 C4.8022463,0.664948877 5.11564697,0.978349547 5.11564697,1.36494887 L5.11564697,12.9146382 C5.11564697,13.3499046 4.80612173,13.7236602 4.37848043,13.8047761 L4.37848043,13.8047761 C4.04323617,13.8683659 3.71991728,13.6481465 3.65632748,13.3129022 C3.64891239,13.27381 3.64529098,13.234093 3.6455137,13.1943044 L3.7117288,1.3649379 C3.7138963,0.977712454 4.02841547,0.664948877 4.41564699,0.664948877 Z"
            id="Fill-1"
            transform="translate(4.380575, 7.240330) rotate(25.000000) translate(-4.380575, -7.240330) "
          />
          <rect
            id="Fill-1"
            transform="translate(38.444219, 7.186833) scale(-1, 1) rotate(25.000000) translate(-38.444219, -7.186833) "
            x="37.7442187"
            y="0.686833394"
            width="1.39999998"
            height="13"
            rx="0.699999988"
          />
          <rect
            id="Fill-1"
            transform="translate(28.810291, 5.827372) scale(-1, 1) rotate(25.000000) translate(-28.810291, -5.827372) "
            x="28.1102913"
            y="0.827371713"
            width="1.39999998"
            height="10"
            rx="0.699999988"
          />
          <rect
            id="Fill-1"
            x="20.0627845"
            y="2"
            width="1.39999998"
            height="8"
            rx="0.699999988"
          />
          <g id="Group-7" transform="translate(2.000000, 20.000000)">
            <rect id="Fill-1" x="10" y="5" width="1.5" height="4" rx="0.75" />
            <rect
              id="Fill-1"
              transform="translate(26.750000, 9.500000) rotate(45.000000) translate(-26.750000, -9.500000) "
              x="26"
              y="4"
              width="1.5"
              height="11"
              rx="0.75"
            />
            <rect
              id="Fill-1"
              x="2"
              y="0"
              width="1.39999998"
              height="18"
              rx="0.699999988"
            />
            <rect
              id="Fill-1"
              x="14"
              y="0"
              width="1.39999998"
              height="18"
              rx="0.699999988"
            />
            <rect
              id="Fill-1"
              x="36"
              y="0"
              width="1.39999998"
              height="18"
              rx="0.699999988"
            />
            <rect
              id="Fill-1"
              transform="translate(20.000000, 17.700000) rotate(90.000000) translate(-20.000000, -17.700000) "
              x="19.3"
              y="-2.30000001"
              width="1.39999998"
              height="40"
              rx="0.699999988"
            />
            <rect
              id="Fill-1"
              transform="translate(30.298097, 12.298097) rotate(45.000000) translate(-30.298097, -12.298097) "
              x="29.548097"
              y="9.79809704"
              width="1.5"
              height="5"
              rx="0.75"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const OfficeBuilding = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="39px"
      height="44px"
      viewBox="0 0 39 44"
      version="1.1"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-8" fill="#A17D58">
          <path
            d="M10.7493986,41.0832684 L3.8279174,41.0832684 C3.60713874,41.0832684 3.42207428,40.8814232 3.42207428,40.6571507 L3.42207428,18 L2,18 L2,40.6519752 C2,41.7336584 2.81006286,42.6134964 3.8279174,42.6134964 L10.7493986,42.6134964 L10.7493986,41.0832684 Z"
            id="Fill-1"
            transform="translate(6.374699, 30.306748) scale(1, -1) translate(-6.374699, -30.306748) "
          />
          <path
            d="M19.7493986,6.53022802 L12.8279174,6.53022802 C12.6071387,6.53022802 12.4220743,6.73207321 12.4220743,6.95634563 L12.4220743,42.4107553 L11,42.4107553 L11,6.96152115 C11,5.87983798 11.8100629,5 12.8279174,5 L19.7493986,5 L34.9214813,5 C35.9393358,5 36.7493986,5.87983798 36.7493986,6.96152115 L36.7493986,42.4107553 L35.3273244,42.4107553 L35.3273244,6.95634563 C35.3273244,6.73207321 35.1422599,6.53022802 34.9214813,6.53022802 L19.7493986,6.53022802 Z"
            id="Fill-1"
          />
          <path
            d="M10.3761302,17.5977438 L6.8279174,17.5977438 C6.60713874,17.5977438 6.42207428,17.3958986 6.42207428,17.1716262 L6.42207428,13 L5,13 L5,17.1664506 C5,18.2481338 5.81006286,19.1279718 6.8279174,19.1279718 L10.3761302,19.1279718 L10.3761302,17.5977438 Z"
            id="Fill-1"
            transform="translate(7.688065, 16.063986) scale(1, -1) translate(-7.688065, -16.063986) "
          />
          <path
            d="M25.3761302,35.530228 L21.8279174,35.530228 C21.6071387,35.530228 21.4220743,35.7320732 21.4220743,35.9563456 L21.4220743,42.1078323 L20,42.1078323 L20,35.9615212 C20,34.879838 20.8100629,34 21.8279174,34 L25.3761302,34 L26.5482128,34 C27.5660673,34 28.3761302,34.879838 28.3761302,35.9615212 L28.3761302,42.1078323 L26.9540559,42.1078323 L26.9540559,35.9563456 C26.9540559,35.7320732 26.7689915,35.530228 26.5482128,35.530228 L25.3761302,35.530228 Z"
            id="Fill-1"
          />
          <path
            d="M27.7537478,1.53022802 L17.8279174,1.53022802 C17.6071387,1.53022802 17.4220743,1.73207321 17.4220743,1.95634563 L17.4220743,6.12797179 L16,6.12797179 L16,1.96152115 C16,0.879837984 16.8100629,5.32907052e-15 17.8279174,5.32907052e-15 L27.7537478,5.32907052e-15 L30.5482128,5.32907052e-15 C31.5660673,5.32907052e-15 32.3761302,0.879837984 32.3761302,1.96152115 L32.3761302,6.12797179 L30.9540559,6.12797179 L30.9540559,1.95634563 C30.9540559,1.73207321 30.7689915,1.53022802 30.5482128,1.53022802 L27.7537478,1.53022802 Z"
            id="Fill-1"
          />
          <path
            d="M23.7395393,42.0000007 L37.669603,42.0000007 C38.0714681,41.999467 38.3980836,42.3260824 38.3980836,42.7274138 C38.3975499,42.9286132 38.3164297,43.1111336 38.1846094,43.2429539 C38.0527891,43.3747742 37.8702687,43.4558944 37.6701367,43.456428 L23.7395393,43.456428 L0.727946855,43.456428 C0.527814838,43.4558944 0.345294439,43.3747742 0.213474151,43.2429539 C0.0816538627,43.1111336 0.000533685377,42.9286132 -1.77635684e-15,42.7274138 C-3.55271368e-15,42.3260824 0.326615451,41.999467 0.72848054,42.0000007 L23.7395393,42.0000007 Z"
            id="Fill-1"
          />
          <path
            d="M15.7,9 L18.6255759,9 C19.0121752,9 19.3255759,9.31340067 19.3255759,9.69999999 C19.3255759,9.88565153 19.2518261,10.0636993 19.1205507,10.1949747 L16.1949747,13.1205507 C15.9216077,13.3939177 15.4783922,13.3939177 15.2050252,13.1205507 C15.0737498,12.9892752 15,12.8112275 15,12.6255759 L15,9.69999999 C15,9.31340067 15.3134007,9 15.7,9 Z"
            id="Fill-1"
          />
          <path
            d="M15.7,16 L18.6255759,16 C19.0121752,16 19.3255759,16.3134007 19.3255759,16.7 C19.3255759,16.8856515 19.2518261,17.0636993 19.1205507,17.1949747 L16.1949747,20.1205507 C15.9216077,20.3939177 15.4783922,20.3939177 15.2050252,20.1205507 C15.0737498,19.9892752 15,19.8112275 15,19.6255759 L15,16.7 C15,16.3134007 15.3134007,16 15.7,16 Z"
            id="Fill-1"
          />
          <path
            d="M15.7,23 L18.6255759,23 C19.0121752,23 19.3255759,23.3134007 19.3255759,23.7 C19.3255759,23.8856515 19.2518261,24.0636993 19.1205507,24.1949747 L16.1949747,27.1205507 C15.9216077,27.3939177 15.4783922,27.3939177 15.2050252,27.1205507 C15.0737498,26.9892752 15,26.8112275 15,26.6255759 L15,23.7 C15,23.3134007 15.3134007,23 15.7,23 Z"
            id="Fill-1"
          />
          <path
            d="M15.7,29 L18.6255759,29 C19.0121752,29 19.3255759,29.3134007 19.3255759,29.7 C19.3255759,29.8856515 19.2518261,30.0636993 19.1205507,30.1949747 L16.1949747,33.1205507 C15.9216077,33.3939177 15.4783922,33.3939177 15.2050252,33.1205507 C15.0737498,32.9892752 15,32.8112275 15,32.6255759 L15,29.7 C15,29.3134007 15.3134007,29 15.7,29 Z"
            id="Fill-1"
          />
          <path
            d="M29.7,9 L32.6255759,9 C33.0121752,9 33.3255759,9.31340067 33.3255759,9.69999999 C33.3255759,9.88565153 33.2518261,10.0636993 33.1205507,10.1949747 L30.1949747,13.1205507 C29.9216077,13.3939177 29.4783922,13.3939177 29.2050252,13.1205507 C29.0737498,12.9892752 29,12.8112275 29,12.6255759 L29,9.69999999 C29,9.31340067 29.3134007,9 29.7,9 Z"
            id="Fill-1"
          />
          <path
            d="M29.7,16 L32.6255759,16 C33.0121752,16 33.3255759,16.3134007 33.3255759,16.7 C33.3255759,16.8856515 33.2518261,17.0636993 33.1205507,17.1949747 L30.1949747,20.1205507 C29.9216077,20.3939177 29.4783922,20.3939177 29.2050252,20.1205507 C29.0737498,19.9892752 29,19.8112275 29,19.6255759 L29,16.7 C29,16.3134007 29.3134007,16 29.7,16 Z"
            id="Fill-1"
          />
          <path
            d="M29.7,23 L32.6255759,23 C33.0121752,23 33.3255759,23.3134007 33.3255759,23.7 C33.3255759,23.8856515 33.2518261,24.0636993 33.1205507,24.1949747 L30.1949747,27.1205507 C29.9216077,27.3939177 29.4783922,27.3939177 29.2050252,27.1205507 C29.0737498,26.9892752 29,26.8112275 29,26.6255759 L29,23.7 C29,23.3134007 29.3134007,23 29.7,23 Z"
            id="Fill-1"
          />
          <path
            d="M29.7,29 L32.6255759,29 C33.0121752,29 33.3255759,29.3134007 33.3255759,29.7 C33.3255759,29.8856515 33.2518261,30.0636993 33.1205507,30.1949747 L30.1949747,33.1205507 C29.9216077,33.3939177 29.4783922,33.3939177 29.2050252,33.1205507 C29.0737498,32.9892752 29,32.8112275 29,32.6255759 L29,29.7 C29,29.3134007 29.3134007,29 29.7,29 Z"
            id="Fill-1"
          />
          <path
            d="M22.7,9 L25.6255759,9 C26.0121752,9 26.3255759,9.31340067 26.3255759,9.69999999 C26.3255759,9.88565153 26.2518261,10.0636993 26.1205507,10.1949747 L23.1949747,13.1205507 C22.9216077,13.3939177 22.4783922,13.3939177 22.2050252,13.1205507 C22.0737498,12.9892752 22,12.8112275 22,12.6255759 L22,9.69999999 C22,9.31340067 22.3134007,9 22.7,9 Z"
            id="Fill-1"
          />
          <path
            d="M22.7,16 L25.6255759,16 C26.0121752,16 26.3255759,16.3134007 26.3255759,16.7 C26.3255759,16.8856515 26.2518261,17.0636993 26.1205507,17.1949747 L23.1949747,20.1205507 C22.9216077,20.3939177 22.4783922,20.3939177 22.2050252,20.1205507 C22.0737498,19.9892752 22,19.8112275 22,19.6255759 L22,16.7 C22,16.3134007 22.3134007,16 22.7,16 Z"
            id="Fill-1"
          />
          <path
            d="M22.7,23 L25.6255759,23 C26.0121752,23 26.3255759,23.3134007 26.3255759,23.7 C26.3255759,23.8856515 26.2518261,24.0636993 26.1205507,24.1949747 L23.1949747,27.1205507 C22.9216077,27.3939177 22.4783922,27.3939177 22.2050252,27.1205507 C22.0737498,26.9892752 22,26.8112275 22,26.6255759 L22,23.7 C22,23.3134007 22.3134007,23 22.7,23 Z"
            id="Fill-1"
          />
        </g>
      </g>
    </svg>
  );
};

export { Landmark, Apartment, Suite, Villa, Store, OfficeBuilding };
