async function getLatLng(data, callBack, warning) {
  if (window.google) {
    var geocoder = new window.google.maps.Geocoder();

    return geocoder.geocode(
      { address: data.county + data.district + data.addr },
      function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          const center = results[0].geometry.location;
          callBack(center);
        } else {
          console.log("轉換失敗");
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
          if (warning) warning();
          return false;
        }
      }
    );
  } else {
    if (warning) warning();
    callBack();
    return null;
  }
}

export default getLatLng;
