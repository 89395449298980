import React, { useState } from "react";
import ProductSet from "./ProductSet";
import { service } from "../../StaticResource";
import LineShareButton from "./LineShareButton";
import FBShareButton from "./FBShareButton";


const openNewConsole = (e, targetPage) => {
  window.open(
    targetPage,
    "線上客服",
    "width=400px,height=640px,toolbar=no,location=no,menubar=no,status=no"
  );
};

const NavDeskTop = ({
  applyOpenHandler,
  contactOpenHandler,
  fbObj
}) => {
  const [openMenu, setOpen] = useState(false);

  const openHandler = () =>{
    setOpen(!openMenu)
  }

  return (
    <>
      <a href="//#endregion" className="menu-btn" onClick={(e)=>{e.preventDefault();openHandler();}}>
        <i className={openMenu?"fas fa-bars fa-times":"fas fa-bars"}></i>
      </a>
      <ul className={openMenu?"nav-menu nav-menu-open":"nav-menu"}>
        <li>
          <a
            href="//#endregion"
            onClick={(e) => {
              e.preventDefault();
              applyOpenHandler();
            }}
          >
            線上申請
          </a>
        </li>
        <li>
          <a
            href="//#endregion"
            onClick={(e) => {
              e.preventDefault();
              contactOpenHandler();
            }}
          >
            專人聯繫
          </a>
        </li>
        <li>
          <a
            href="//#endregion"
            onClick={(e) => {
              e.preventDefault();
              openNewConsole(e, service.url);
            }}
          >
            線上客服
          </a>
        </li>
        <ProductSet openMenuHandler={openHandler}/>
        <FBShareButton obj={fbObj} type={"web"} />
        <LineShareButton obj={fbObj} type={"web"} />
      </ul>
    </>
  );
};

export default NavDeskTop;
