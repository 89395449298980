import React from "react";
import { footer } from "../../../StaticResource";
import {scrollTop} from "../../Utils/Scroll/ScrollUtils"
const FootNote = ({ children, applyHandler, contactOpenHandler }) => {
  const footerList = footer.map((info, index) => (
    <li key={index}>
      <a href={info.href} title={info.name} target="_blank" rel="noopener noreferrer">
        <span>{info.name}</span>
      </a>
    </li>
  ));

  return (
    <>
      <footer>
        {children}
        <div className="mobile-fuction row">
          <div className="mfgroup">
            <a
              href="//#endregion"
              className="mficon-01"
              onClick={(e) => {
                e.preventDefault();
                applyHandler();
              }}

            >
              <span>線上申請</span>
            </a>
            <a
              href="//#endregion"
              className="mficon-02"
              onClick={(e) => {
                e.preventDefault();
                contactOpenHandler();
              }}
            >
              <span>專人聯繫</span>
            </a>
            <a href="tel:0800016168" className="mficon-03">
              <span>服務專線</span>
            </a>
          </div>
        </div>
        <div className="footer-wrap">
          <ul>{footerList}</ul>
		  <div className="businessInfo">
		    <span>營業人：兆豐國際商業銀行股份有限公司</span>
		    <span>營利事業統一編號：03705903</span>
		    <span>兆豐國際商業銀行股份有限公司 版權所有</span>
		  </div>
        </div>
      </footer>
      <a href="//#endregion" className="scroll-to-top" onClick={(e)=>{e.preventDefault(); scrollTop()}}>
        <i className="fas fa-chevron-up"></i>
      </a>
    </>
  );
};

export default FootNote;
