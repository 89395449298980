import React, { useState } from "react";
import Photos from "../../../Photo";
import Rating from "../Rating/Rating";
import { Link } from "react-router-dom";
import { getItemFromSessionStorage } from "../../Utils/BrowserStorageUtils";
import { validateTimes } from "../../../StaticResource";
const BottomLink = ({ queryOpenHandler }) => {
  const [ratingOpen, setRatingOpen] = useState(false);
  const ratingHandler = () => {
    setRatingOpen(!ratingOpen);
  };
  const onClickHandler = (e) => {
    const queryTimes = getItemFromSessionStorage("queryTimes");
    if (queryTimes >= validateTimes) {
      e.preventDefault();
      queryOpenHandler();
    }
  };
  return (
    <div className="count-fuction">
      <Link to="/CalculationTable" className="cf">
        <img src={Photos.cficon2} alt="" />
        <span>試算紀錄查詢</span>
        <strong>
          為您呈現
          <br />
          一週的試算結果
        </strong>
      </Link>
      <Link
        to="/mortgage"
        className="cf"
        onClick={(e) => {
          onClickHandler(e);
        }}
      >
        <img src={Photos.cficon1} alt="" />
        <span>額度利率試算</span>
        <strong>
          提供專屬額度
          <br />
          利率方案
        </strong>
      </Link>
      <a
        className="cf"
        onClick={(e) => {
          e.preventDefault();
          ratingHandler();
        }}
        style={{ cursor: "pointer" }}
      >
        <img src={Photos.cficon3} alt="" />
        <span>網站使用調查</span>
        <strong>
          誠摯邀請您
          <br />
          參與線上問卷
        </strong>
      </a>
      {ratingOpen && <Rating status="2-3" setOpen={ratingHandler} />}
    </div>
  );
};

export default BottomLink;
