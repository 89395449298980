import React from "react";

const ConditionUnit = ({ obj, setObj, data, item }) => {
  const objHandler = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const value = Number(e.target.value);

    const thisobj = { ...obj };
    const oriValue = Number(thisobj[name]);
	
    let finalValue = typeof Number;
    if (type === "button") {
	  finalValue = Number(oriValue + value).toFixed(item.fixed);
    } else {
      finalValue = value;
      if (item.fixed === "2") {
        finalValue = Math.round(value * 100) / 100;
	  //202208 Vulnerability Project Modify -- begin        
      }else if (item.fixed === "3"){
		finalValue = (Math.round(value * 1000) / 1000).toFixed(3);
	  }
	  //202208 Vulnerability Project Modify -- end
    }

    thisobj[name] =
      Number(finalValue) < item.min || Number(finalValue) > Number(item.max)
        ? item.base
        : finalValue;

    setObj(thisobj);
  };

  return (
    <div className="col-lg-3 col-md-6 col-sm-12 nbrown">
      <h6>{item.title}</h6>
      <div className="plus">
        <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
          <span className="input-group-btn input-group-prepend">
            <button
              className="btn btn-primary bootstrap-touchspin-down"
              name={item.name}
              type="button"
              value={"-" + item.step}
              onClick={(e) => {
                objHandler(e);
              }}
            >
              -
            </button>
          </span>
          <input
            type="number"
            name={item.name}
            value={item.base}
            className="form-control"
            onChange={(e) => {
              objHandler(e);
            }}
          />
          <span className="input-group-btn input-group-append">
            <button
              className="btn btn-primary bootstrap-touchspin-up"
              name={item.name}
              type="button"
              value={item.step}
              onClick={(e) => {
                objHandler(e);
              }}
            >
              +
            </button>
          </span>
        </div>
      </div>
      <span className="unit">{item.unit}</span>
    </div>
  );
};

export default ConditionUnit;
