import React, { useState, useEffect } from "react";
import MarkDown from "../../../Utils/MarkDownUtils/MarkDown";
import { fetchItem } from "../../Fetch/fetchWhenStart";

const Recommend = () => {
  const [data, setData] = useState();

  useEffect(() => {
    fetchItem("recommend", fetchHandler);
  }, []);

  const fetchHandler = (returnObj) => {
    setData(returnObj);
  };

  if (!data) return <></>;

  return (
    <div className="product-info row">
      <div className="container">
        <div className="row justify-content-center">
          <h2 className="mobile-title h2">最新優惠活動</h2>
        </div>
      </div>
      <div className="col-md-12">
        <RecommendItem item={{ ...data[1] }} />
        <RecommendItem item={{ ...data[2] }} />
        <RecommendItem item={{ ...data[3] }} />
      </div>
    </div>
  );
};

export default Recommend;

const RecommendItem = ({ item }) => {
  if (item.photo === "" || (item.txt === "" && item.url === "")) return <></>;
  return (
    <section className="product-block">
      {item.url === "" ? (
        <Content item={item} />
      ) : (
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          <Content item={item} />
          <div className="more-info">
            了解更多<i className="fas fa-chevron-right"></i>
          </div>
        </a>
      )}
    </section>
  );
};

const Content = ({ item }) => {
  return (
    <>
      <div className="img-wrap">
        <img src={item.photo} alt="" />
      </div>
      <div className="product-txt-block">
        <h2>活動說明</h2>
        <div className="product-txt">
          {item.txt !== "" && <MarkDown content={item.txt}>{item.txt}</MarkDown>}
        </div>
      </div>
    </>
  );
};
