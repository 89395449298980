import React, { useState, useLayoutEffect, useEffect } from "react";
import fakeMobileBanner from "./banner-mobile-01.png";
import { fetchItem } from "../../Fetch/fetchWhenStart";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Banner = () => {
  const [width, height] = useWindowSize();
  const [photoObjArray, setPhotoObjArray] = useState([]);
  const [pointer, setPointer] = useState(0);
  const [mobileBanner, setMobileBanner] = useState();

  useEffect(() => {
    fetchItem("banner",bannerFormatter);
  }, []);

  const bannerFormatter = (data) => {
    const thisData = { ...data };
    const bannerArray = [];
    const bannerNumber = 4;
    var index = 1;
    while (index <= bannerNumber) {
      if (thisData[`photo${index}`] !== "") {
        bannerArray.push({
          photo: thisData[`photo${index}`],
          url: thisData[`url${index}`] ? thisData[`url${index}`] : "",
        });
      }
      index++;
    }
    setPhotoObjArray(bannerArray);
    if (thisData[`photo${5}`]) {
      setMobileBanner(thisData[`photo${5}`]);
    } else {
      setMobileBanner(fakeMobileBanner);
    }
  };

  const bannerHandler = (e, value) => {
    const name = e.target.name;
    const photoNumber = photoObjArray.length;
    let thisPointer = Number(pointer);
    if (name === "arrow") {
      thisPointer = thisPointer + Number(value);
      if (thisPointer < 0) {
        thisPointer = photoNumber - 1;
      } else if (thisPointer >= photoNumber) {
        thisPointer = 0;
      }
    } else {
      thisPointer = Number(value);
    }
    setPointer(thisPointer);
  };

  const Point = () => {
    const arrayLength = photoObjArray.length;
    const array = [];
    var i;
    for (i = 0; i < arrayLength; i++) {
      array.push(i);
    }

    return array.map((item, i) => {
      return (
        <div className="bx-pager-item" key={i}>
          <a
            className={
              item === pointer ? "bx-pager-link active" : "bx-pager-link"
            }
            name="select"
            onClick={(e) => {
              e.preventDefault();
              bannerHandler(e, item);
            }}
          />
        </div>
      );
    });
  };
  if (mobileBanner === undefined || photoObjArray.length === 0) return <></>;

  return (
    <>
      <div className="bx-wrapper" style={{ maxWidth: "100%" }}>
        <div
          className="bx-viewport"
          aria-live="polite"
          style={{
            width: "100%",
            overflow: "hidden",
            position: "relative",
            height: "relative",
          }}
        >
          <div
            className="slider desktop"
            style={{
              width: "2215%",
              position: "relative",
              transitionDuration: "0s",
              transform: `translate3d(0px, 0px, 0px)`,
            }}
          >
            <div
              style={{
                float: "left",
                listStyle: "none",
                position: "relative",
                width: width + "px",
              }}
              aria-hidden="true"
            >
              {{ ...photoObjArray[pointer] }.url ? (
                <a target="_blank" href={{ ...photoObjArray[pointer] }.url}>
                  <img src={{ ...photoObjArray[pointer] }.photo} alt="" />
                </a>
              ) : (
                <img src={{ ...photoObjArray[pointer] }.photo} alt="" />
              )}
            </div>
          </div>
        </div>
        <div className="bx-controls bx-has-controls-direction bx-has-pager">
          <div className="bx-controls-direction">
            <a
              className="bx-prev"
              href="//#endregion"
              name="arrow"
              onClick={(e) => {
                e.preventDefault();
                bannerHandler(e, -1);
              }}
            >
              Prev
            </a>
            <a
              className="bx-next"
              href="//#endregion"
              name="arrow"
              onClick={(e) => {
                e.preventDefault();
                bannerHandler(e, 1);
              }}
            >
              Next
            </a>
          </div>
          <div className="bx-pager bx-default-pager">
            <Point />
          </div>
        </div>
      </div>
      <div className="slider-mobile">
        <div>
          <img src={mobileBanner} alt="" />
        </div>
      </div>
    </>
  );
};

export default Banner;
