import React from "react";
import { Link } from "react-router-dom";

const Card = ({product}) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 mb-5 mt-4">
      <div className="card">
        <img
          className="card-img-top"
          src={product.photo}
          alt={product.title + "-" + product.ad}
        />
        <div className="card-body card-height">
          <h5 className="h5 line brown mt-3">{product.title}</h5>
          <ul className="card-text">
            <li className="Ellipsis-4 card-c">{product.ad}</li>
          </ul>
          <Link
            className="btn btn-search sm"
            to={`/productPage/${product.order}`}
            style={{paddingTop:"5px"}}
          >
            了解更多
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
