import React, { useState, useEffect } from "react";
import { zipCodeData } from "../Utils/AddTwZipCode/zipCodeData";
import { fetchContact, fetchPuw } from "../MainSwitch/Fetch/fetchBackend";
import InfoAlert from "./AlertContainer/AlertContainer";
import BranchSelector from "./BranchSelector/BranchSelector";
import { data } from "./BranchSelector/BranchData";
import { addrTemplate } from "../../App";
import { getItemFromSessionStorage } from "../Utils/BrowserStorageUtils";
import { validateData } from "../Utils/DefaultSetting/validater";
import { getPlaceHolder } from "../Utils/DefaultSetting/PlaceHolder";
import { ObjFilterHandler } from "../Utils/Filter/Filter";
import { pdf } from "../../pdf/index";

const Contact = ({ setClose, addrData, setAddr }) => {
  const [puw, setPuw] = useState();
  const [alertType, setAlertType] = useState("");
  const [contactData, setContactData] = useState({});
  const [BranchData, setBranchData] = useState();
  useEffect(() => {
    fetchPuw(setPuw);
    setBranchData(data);
    setData();
  }, []);

  const setData = () => {
    const loan = getItemFromSessionStorage("loan");
    const thisTemplate = { ...template };
    if (loan) {
      thisTemplate.loan_balance = loan;
    }
    setContactData(thisTemplate);
  };

  const [readingClick, setReadingClick] = useState(false);
  if (puw === undefined) return <></>;
  const confirmReading = puw.confirmReading;
  const clickHandler = () => {
    setReadingClick(!readingClick);
  };

  const addrHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let theCounty = addrData.county;
    let theDist = addrData.district;
    let theZipCode = addrData.zipcode;
    let theAddr = addrData.addr;
    if (name === "county") {
      theCounty = value;
      let countyObj = zipCodeData[theCounty];
      theDist = Object.keys(countyObj)[0];
      theZipCode = countyObj[Object.keys(countyObj)[0]];
    } else if (name === "district") {
      theDist = value;
      theZipCode = zipCodeData[theCounty][value];
    } else if (name === "addr") {
      theAddr = value;
    }

    const newData = {
      county: theCounty,
      district: theDist,
      zipcode: theZipCode,
      addr: theAddr,
    };

    setAddr(newData);
  };

  const dataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const thisContactData = { ...contactData };
    thisContactData[name] = value;

    if (name === "branch_county" && value === "不選擇城市") {
      thisContactData.branch_district = "不選擇區域";
      thisContactData.branch_name = "不設定分行";
    } else if (name === "branch_county") {
      const district = Object.keys(BranchData[value])[0];
      thisContactData.branch_district = district;
      thisContactData.branch_name = Object.keys(
        { ...BranchData[value] }[district]
      )[0];
    } else if (name === "branch_district") {
      thisContactData.branch_name = Object.keys(
        { ...BranchData[contactData.branch_county] }[value]
      )[0];
    }

    setContactData(ObjFilterHandler(thisContactData));
  };

  const countyOptions = Object.keys(zipCodeData).map((county, i) => {
    return (
      <option value={county} key={i}>
        {county}
      </option>
    );
  });

  const districtOptions = Object.keys(
    zipCodeData[addrData.county]
  ).map((district, index) => <option key={index}>{district}</option>);

  const doubleClose = () => {
    setClose();
    setAlertType("");
  };

  const submitHandler = () => {
    const validatePhone = validateData(contactData.c_mobile, "phone");

    const datafilled =
      contactData.c_name && addrData.district !== "房屋區域" && addrData.addr;

    if (readingClick && datafilled && validatePhone) {
      const submitObj = getSubmitObj(addrData, contactData);
      fetchContact(submitObj, setAlertType);
    } else if (!datafilled) {
      alertHandler("fillForm");
    } else if (!validatePhone) {
      alertHandler("phoneFormat");
    } else {
      alertHandler("readingConfirm");
    }
  };
  const alertHandler = (event) => {
    setAlertType(event);
  };
  const cleanHandler = () => {
    setReadingClick(false);
    setContactData(template);
    setAddr(addrTemplate);
  };

  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden", zIndex: 99999, marginTop: "65px" }}
      >
        <div className="contact-form" style={{ top: "10px" }}>
          <a
            href="//#endregion"
            className="close-btn"
            onClick={(e) => {
              e.preventDefault();
              setClose();
            }}
          >
            <i className="fas fa-times"></i>
          </a>
          <div className="title-contact">
            <h2>專人聯繫</h2>
          </div>
          <div className="form-block">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-row">
                    <input
                      type="text"
                      id="name"
                      name="c_name"
                      value={contactData.c_name}
                      onChange={(e) => dataHandler(e)}
                    />
                    <label
                      htmlFor="name"
                      style={
                        contactData.c_name ? { left: 0, top: "-25px" } : {}
                      }
                    >
                      {contactData.c_name === ""
                        ? getPlaceHolder("name")
                        : getPlaceHolder("name_fly")}
                    </label>
                  </div>
                  <div className="form-row">
                    <input
                      type="text"
                      id="mobilephone"
                      name="c_mobile"
                      value={contactData.c_mobile}
                      onChange={(e) => dataHandler(e)}
                    />
                    <label
                      htmlFor="mobilephone"
                      style={
                        contactData.c_mobile ? { left: 0, top: "-25px" } : {}
                      }
                    >
                      行動電話
                    </label>
                  </div>
                  <div className="form-row">
                    <h4>貸款用途</h4>
                    <div className="s-row">
                      <input
                        type="radio"
                        name="loan_purpose"
                        id="u1"
                        checked={contactData.loan_purpose === "我要購買房屋"}
                        value="我要購買房屋"
                        onChange={(e) => {
                          dataHandler(e);
                        }}
                      />
                      <label htmlFor="u1">
                        <span>我要購買房屋</span>
                      </label>
                    </div>
                    <div className="s-row">
                      <input
                        type="radio"
                        name="loan_purpose"
                        id="u2"
                        checked={contactData.loan_purpose === "我要投資週轉"}
                        value="我要投資週轉"
                        onChange={(e) => {
                          dataHandler(e);
                        }}
                      />
                      <label htmlFor="u2">
                        <span>我要投資週轉</span>
                      </label>
                    </div>
                    <div className="s-row">
                      <input
                        type="radio"
                        name="loan_purpose"
                        id="u3"
                        checked={contactData.loan_purpose === "我要以房養老"}
                        value="我要以房養老"
                        onChange={(e) => {
                          dataHandler(e);
                        }}
                      />
                      <label htmlFor="u3">
                        <span>我要以房養老</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-row">
                    <input
                      type="text"
                      className="w2"
                      id="balance"
                      name="loan_balance"
                      value={contactData.loan_balance}
                      onChange={(e) => {
                        dataHandler(e);
                      }}
                      style={{ textAlign: "right", paddingTop: "0px" }}
                    />
                    <label
                      htmlFor="balance"
                      style={
                        contactData.loan_balance
                          ? { left: 0, top: "-25px" }
                          : {}
                      }
                    >
                      {contactData.loan_balance === ""
                        ? getPlaceHolder("loan")
                        : getPlaceHolder("loan_fly")}
                    </label>
                    萬元
                  </div>
                  <div className="form-row">
                    <select
                      name="county"
                      id=""
                      className="half"
                      value={addrData.county}
                      onChange={(e) => {
                        addrHandler(e);
                      }}
                      style={
                        addrData.county === "房屋縣市" ? {} : { color: "black" }
                      }
                    >
                      {countyOptions}
                    </select>
                    <select
                      name="district"
                      id=""
                      className="half"
                      onChange={(e) => {
                        addrHandler(e);
                      }}
                      value={addrData.district}
                      style={
                        addrData.district === "房屋區域"
                          ? {}
                          : { color: "black" }
                      }
                    >
                      {districtOptions}
                    </select>
                    <input
                      type="text"
                      name="addr"
                      placeholder="房屋路名"
                      id="addr"
                      value={addrData.addr}
                      onChange={(e) => {
                        addrHandler(e);
                      }}
                      style={{ lineHeight: "0px" }}
                    />
                  </div>
                  <div className="form-row">
                    <select
                      name="call_back_time"
                      id=""
                      value={contactData.call_back_time}
                      onChange={(e) => dataHandler(e)}
                      style={
                        contactData.call_back_time === "希望回電時段"
                          ? {}
                          : { color: "black" }
                      }
                    >
                      <option value="">希望回電時段</option>
                      <option value="09:00~12:00">09:00~12:00</option>
                      <option value="13:00~17:00">13:00~17:00</option>
                      <option value="17:00過後">17:00過後</option>
                      <option value="全天皆可">全天皆可</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "-20px" }}>
                  <BranchSelector
                    contactData={contactData}
                    dataHandler={dataHandler}
                    BranchData={BranchData}
                  />
                </div>
                <div className="col-md-12">
                  <div className="s-row center mmfix read-protect-block">
                    <div className="read-protect-title">
                      兆豐國際商業銀行履行個人資料保護法告知義務
                    </div>
                    <div className="read-protect">
                      <p>
                        親愛的客戶您好,由於個人資料之蒐集,涉及
                        臺端的隱私權益,兆豐國際商業銀行股份有限公司(以下稱本行)向
                        臺端蒐集個人資料時,依據個人資料保護法(以下稱個資法)第八條第一項規定,應明確告知
                        臺端下列事項:(一)蒐集者名稱(即兆豐國際商業銀行)(二)蒐集之目的(三)個人資料之類別(四)個人資料利用之期間、地區、對象及方式(五)當事人依個資法第三條規定得行使之權利及方式(六)當事人得自由選擇提供個人資料時,不提供將對其權益之影響。
                      </p>
                      <p>
                        一、有關本行蒐集
                        臺端個人資料之目的、個人資料類別及個人資料利用之期間、地區、對象及方式等內容,請
                        臺端詳閱如後
                        <a href={pdf} target="_blank" rel="noopener noreferrer">
                          附表(請點選)
                        </a>
                        或本行網站。
                      </p>
                      <p>
                        二、依據個資法第三條規定, 臺端就本行保有
                        臺端之個人資料得行使下列權利:
                        <br />
                        (一)除有個資法第十條所規定之例外情形外,得向本行查詢、請求閱覽或請求製給複製本,惟本行依個資法第十四條規定得酌收必要成本費用。{" "}
                        <br />
                        (二)得向本行請求補充或更正,惟依個資法施行細則第十九條規定,臺端應適當釋明其原因及事實。
                        <br />
                        (三)本行如有違反個資法規定蒐集、處理或利用
                        臺端之個人資料,依個資法第十一條第四項規定,臺端得向本行請求停止蒐集。
                        <br />
                        (四)依個資法第十一條第二項規定,個人資料正確性有爭議者,得向本行請求停止處理或利用
                        臺端之個人資料。惟依該項但書
                        規定,本行因執行業務所必須並註明其爭議或經
                        臺端書面同意者,不在此限。
                        <br />
                        (五)依個資法第十一條第三項規定,個人資料蒐集之特定目的消失或期限屆滿時,得向本行請求刪除、停止處理或利用
                        臺端之
                        個人資料。惟依該項但書規定,本行因執行業務所必須或經
                        臺端書面同意者,不在此限。
                      </p>
                      <p>
                        三、臺端如欲行使上述個資法第三條規定之各項權利,有關如何行使之方式,得向本行客服(0800-016168)詢問或於本行網站(網址:{" "}
                        <a href="https://www.megabank.com.tw/">
                          https://www.megabank.com.tw/
                        </a>
                        )查詢。
                      </p>
                      <p>
                        四、臺端得自由選擇是否提供相關個人資料及類別,惟
                        臺端所拒絕提供之個人資料及類別,如果是辦理業務審核或作業所需之資料,
                        本行可能無法進行必要之業務審核或作業而無法提供
                        臺端相關服務或無法提供較佳之服務,敬請見
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={readingClick === true}
                      onChange={clickHandler}
                    />{" "}
                    {confirmReading}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="s-row center mmfix" style={{marginBottom:"100px"}}>
                    <button
                      type="button"
                      className="btn btn-clear"
                      onClick={cleanHandler}
                      style={{padding:"0px 25px"}}
                    >
                      清除重填
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={submitHandler}
                      style={{padding:"0px 25px"}}
                    >
                      確定送出
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InfoAlert
          alertType={alertType}
          setClose={alertHandler}
          setDoubleClose={doubleClose}
        />
      </div>
    </>
  );
};

export default Contact;

const getSubmitObj = (addrData, contactData) => {
  return {
    cName: contactData.c_name,
    cMobile: contactData.c_mobile,
    loanPurpose: contactData.loan_purpose,
    loanBalance: contactData.loan_balance,
    cAddress: addrData.county + addrData.district + addrData.addr,
    callBackTime:
      contactData.call_back_time === "希望回電時段"
        ? "全天皆可"
        : contactData.call_back_time,

    county: addrData.county,
    district: addrData.district,
    addr: addrData.addr,

    branch_county: contactData.branch_county,
    branch_district: contactData.branch_district,
    wishBranch: contactData.branch_name,
  };
};

const template = {
  c_name: "",
  c_mobile: "",
  loan_purpose: "我要購買房屋",
  loan_balance: "",
  c_address: "",
  call_back_time: "希望回電時段",
  branch_county: "不選擇城市",
  branch_district: "不選擇區域",
  branch_name: "不設定分行",
};
