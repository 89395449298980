
const createInfoWindow = (map, marker, infoContent) => {
  const infowindow = new window.google.maps.InfoWindow({
    content: infoContent
  });

  marker.addListener("mouseover", function() {
    infowindow.open(map, marker); //設定點選 marker 打開資訊視窗事件
  });

  marker.addListener("mouseout", function() {
    infowindow.close(); //設定點選 marker 打開資訊視窗事件
  });

  marker.addListener("click", function() {
    infowindow.open(map, marker); //設定點選 marker 打開資訊視窗事件
  });

  return null;
};


export default createInfoWindow;
