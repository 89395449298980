import React, { useState, useEffect } from "react";
import DefaultPage from "./DefaultPage/DefaultPage";
import Estimate from "./Estimate/Estimate";
import Product from "./Product/Product";
import Catelog from "./Catelog/Catelog";
import AdvanceEstimate from "./AdvanceEstimate/AdvanceEstimate";
import Mortgage from "./Mortgage/Mortgage";
import { Switch, Route } from "react-router-dom";
import CalculationTable from "./Calculation/CalculationTable";
import { skipVerify } from "../../StaticResource";
import {fetchToken, fetchItem } from "./Fetch/fetchWhenStart";

const MainSwitch = ({
  applyHandler,
  contactOpenHandler,
  addrData,
  setAddr,
}) => {
  const [products, setProducts] = useState();
  const [category, setCategory] = useState();
  useEffect(() => {
    fetchToken();
    fetchItem("products",productHandler);
    fetchItem("category",categoryHandler);
  }, []);

  const productHandler = (returnObj) =>{
    setProducts({ ...returnObj })
  }
  const categoryHandler = (returnObj) =>{
    setCategory({ ...returnObj })
  }

  const [status, setStatus] = useState(skipVerify ? "3" : "1"); //1: 發送驗證碼 2: 接收驗證碼 3: 資料蒐集


  const addrHandler = (e) => {
    const addrData = {};
    const value = e.target.value;
    addrData.addr = value.addr;
    addrData.district = value.district;
    addrData.county = value.county;
    addrData.zipcode = value.zipcode;
    setAddr(addrData);
  };

  return (
    <Switch>
      <Route
        exact
        path="/CalculationTable"
        render={() => (
          <CalculationTable
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
          />
        )}
      />
      <Route
        exact
        path="/estimate"
        render={() => (
          <Estimate
            data={addrData}
            dataHandler={addrHandler}
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
          />
        )}
      />
      <Route
        exact
        path="/products/:id"
        render={(rprops) => (
          <Catelog
            match={rprops.match}
            products={products}
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
            catelog={category}
            status={status}
            setStatus={setStatus}
          />
        )}
      />
      <Route
        exact
        path="/productPage/:id"
        render={(rprops) => (
          <Product
            match={rprops.match}
            products={products}
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
            status={status}
            setStatus={setStatus}
          />
        )}
      />
      <Route
        exact
        path="/advance"
        render={() => (
          <AdvanceEstimate
            addrData={addrData}
            setAddr={addrHandler}
            status={status}
            setStatus={setStatus}
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
          />
        )}
      />
      <Route
        exact
        path="/mortgage"
        render={() => (
          <Mortgage
            setAddr={addrHandler}
            addrData={addrData}
            setStatus={setStatus}
            status={status}
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
          />
        )}
      />
      {/* <Route
        exact
        path="/main"
        render={() => (
          <DefaultPage
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
          />
        )}
      />
      } /> */}
      <Route
        path="/"
        render={() => (
          <DefaultPage
            applyHandler={applyHandler}
            contactOpenHandler={contactOpenHandler}
            status={status}
            setStatus={setStatus}
          />
        )}
      />
    </Switch>
  );
};

export default MainSwitch;

  // deprecated
  // const [data, setData] = useState({
  //   B_TYPE_INT: "6", //房型 6: 電梯大樓 / 華廈,  1: 無電梯公寓, 7: 套房, 2: 別墅 / 透天厝, 3: 店面 (店鋪), 4: 辦公商業大樓
  //   B_AGE: "", //屋齡
  //   B_AREA_P: "", //總面積
  //   FLOORS: "", //總樓層數
  //   LEVEL_SELECT: "1", //1: 地上，2:地下
  //   LEVEL: "", //居住樓層
  //   PARKING: "1", //1: 無車位 2: 機械車位 3: 非機械車位
  //   parking_qty: "", //車位數量
  //   parking_p: "", //車位總面積
  //   house: "1", //1. 我要購買房屋 2. 我要周轉投資
  //   buyhouse: "1", //1. 專案合作房仲業者 2. 其他房仲 或 自行洽購
  //   user_age: "", //年齡
  //   user_children: "", //子女數
  //   user_job: "", //職業別
  //   user_title: "", //職稱
  //   user_income: "", //年收入
  //   estimate_total_price_av: "",
  //   total_price: "", //成交價
  //   creditcard: "0", //信用卡 0:無 1:有
  //   pay: "1", //按時繳清 0:無 1:有
  //   borrow: "0", //信用卡借貸 0:無 1:有
  //   cashcard: "0", //現金卡 0:無 1:有
  //   cashcardbalance: "0", //現金卡結餘 0:無 1:有
  //   balance: "0", //信用貸款  0:無 1:有
  //   priority: "A",
  // });