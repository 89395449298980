import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./css/bxslider.css";
import "./css/fontawesome.min.css";
import "./css/bootstrap.min.css";
import "./css/magnific-popup.css";
import "./css/animate.min.css";
import "./css/checkbox.css";
import "./css/style.css";
import "./css/all.css";
import "./css/style-new.css";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { matomoHost, matomoPhp, matomoTraceUrl } from "./StaticResource";

const instance = createInstance({
  urlBase: matomoHost,
  siteId: 1, // optional, default value: `1`
  trackerUrl: matomoPhp, // optional, default value: `${urlBase}matomo.php`
  srcUrl: matomoTraceUrl, // optional, default value: `${urlBase}matomo.js`
});
// 202401 vulnerability fix --begin
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
);
// 202401 vulnerability fix --end

// 202401 vulnerability fix --begin
/*
ReactDOM.render(
  <MatomoProvider value={instance}>
    <App />
  </MatomoProvider>,
  document.getElementById("root")
);
*/
// 202401 vulnerability fix --end

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
