import React from "react";
import ReactMarkdown from "react-markdown";
import remarkSubSuper from "remark-sub-super";
const MarkDown = ({content}) => {
  return (
    <ReactMarkdown
      children={content}
      plugins={[remarkSubSuper]}
      components={{
        sub: "sub",
        sup: "sup",
      }}
    />
  );
};

export default MarkDown;
