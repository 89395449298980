import React, { useEffect, useState } from "react";
import ValidationContainer from "../Verify/ValidationContainer";
import MortgageBody from "./MortgageBody";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";
import { validateTimes } from "../../../StaticResource";
import { addrTemplate } from "../../../App";
import { eventDataFiltedHandler } from "../../Utils/Filter/Filter";

const Mortgage = ({
  addrData,
  setAddr,
  status,
  setStatus,
  applyHandler,
  contactOpenHandler,
}) => {
  const [data, setData] = useState();

  useEffect(() => {
    const queryTimes = getItemFromSessionStorage("queryTimes");
    if (queryTimes) {
      if (validateTimes <= queryTimes) {
        setStatus("1");
      }
    }
    setEstimatePrice();
  }, []);

  const setEstimatePrice = () => {
    const thisTemplate = { ...template };

    const estimate_total_price_av = getItemFromSessionStorage(
      "estimateTotalPriceAv"
    );

    if (estimate_total_price_av) {
      thisTemplate.total_price = estimate_total_price_av;
      storeItemInSessionStorage("estimateTotalPriceAv", 0);
    }
    setData(thisTemplate);
  };

  const dataHandler = (e) => {
    const itemName = e.target.name;
    const value = e.target.value;
    const formatedObj = eventDataFiltedHandler(itemName, value);
    let updateData = { ...data };

    updateData[itemName] = formatedObj[itemName];
    setData(updateData);
  };

  const cleanData = () => {
    setData(template);
    const obj = { target: { value: addrTemplate } };
    setAddr(obj);
  };

  if (data === undefined || addrData === undefined) return <></>;
  return (
    <>
      {status === "3" ? (
        <MortgageBody
          addrData={addrData}
          setAddr={setAddr}
          data={data}
          dataHandler={dataHandler}
          status={status}
          setStatus={setStatus}
          applyHandler={applyHandler}
          contactOpenHandler={contactOpenHandler}
          cleanData={cleanData}
        />
      ) : (
        <ValidationContainer status={status} setStatus={setStatus} />
      )}
    </>
  );
};

export default Mortgage;

const template = {
  house: "1", //1. 我要購買房屋 2. 我要周轉投資
  buyhouse: "1", //1. 專案合作房仲業者 2. 其他房仲 或 自行洽購
  user_age: "", //年齡
  user_children: "", //子女數
  user_job: "", //職業別
  user_title: "", //職稱
  user_income: "", //年收入
  estimate_total_price_av: "",
  total_price: "", //成交價
  creditcard: "0", //信用卡 0:無 1:有
  pay: "1", //按時繳清 0:無 1:有
  borrow: "0", //信用卡借貸 0:無 1:有
  cashcard: "0", //現金卡 0:無 1:有
  cashcardbalance: "0", //現金卡結餘 0:無 1:有
  balance: "0", //信用貸款  0:無 1:有
  priority: "A",
  loan_purpose:0,
};
