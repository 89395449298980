import React, { useState, useEffect } from "react";
import { BuyHouse, Rent } from "./Svg";
import { getLocation } from "../Fetch/GetLocation";
import { fetchMortgageResult } from "../Fetch/fetchBackend";
import AddrTwZipCode from "../../Utils/AddTwZipCode/AddrTwZipCode";
import Photos from "../../../Photo";
import { getPlaceHolder } from "../../Utils/DefaultSetting/PlaceHolder";
import { formatFilter, ObjFilterHandler } from "../../Utils/Filter/Filter";
import { fetchItem } from "../Fetch/fetchWhenStart";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";
const MortgageForm = ({
  addrData,
  setAddr,
  data,
  dataHandler,
  resultOpen,
  resultOpenHandler,
  setMortgageData,
  setStatus,
  setLoading,
}) => {
  const [mouseOn, setMouseOn] = useState(false);
  const [pws, setPws] = useState();
  const [alert, setAlert] = useState({
    zipcode: false,
    addr: false,
    user_age: false,
    user_children: false,
    user_job: false,
    user_title: false,
    user_income: false,
    total_price: false,
  });
  const { trackPageView } = useMatomo();
  const onHover = (event) => {
    setMouseOn(event);
  };

  useEffect(() => {
    if (data.estimate_total_price_av) {
      const object = {
        target: {
          name: "total_price",
          value: formatFilter(data.estimate_total_price_av, "totalPrice"),
        },
      };
      dataHandler(object);
    }
    fetchItem("pws", setPws);
    trackPageView({
      documentTitle: "填寫額度利率試算表格", // optional
      href: `${hashReplacer(window.document.location.href)}/form`, // optional
    })
  }, []);

  const middleHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const thisAlert = { ...alert };

    if (value === "" || value === "請選擇") {
      thisAlert[name] = true;
    } else {
      thisAlert[name] = false;
    }
    setAlert(thisAlert);

    dataHandler(e);
  };

  const alertChecked = () => {
    const thisAlert = { ...alert };
    const alertArray = Object.keys(alert);

    let result = false;
    alertArray.map((item) => {
      thisAlert[item] =
        data[item] === "" || data[item] === "請選擇" ? true : false;
      if (thisAlert[item] === true) result = true;
      return null;
    });

    if (addrData.addr === "") {
      thisAlert.addr = true;
      result = true;
    }
    if (addrData.zipcode === "") {
      thisAlert.zipcode = true;
      result = true;
    }

    setAlert(thisAlert);
    return result;
  };

  const submitHandler = () => {
    const isAlerted = alertChecked();

    if (isAlerted) {
      return;
    } else {
      setLoading(true);

      fetchMortgageResult(
        getSubmitObj(addrData, data),
        setMortgageData,
        resultOpenHandler,
        setStatus
      );
    }
  };

  const addrHandler = (data) => {
    const thisAlert = { ...alert };

    if (data.addr === "") {
      thisAlert.addr = true;
    } else if (data.zipcode === "") {
      thisAlert.zipcode = true;
    } else {
      thisAlert.addr = false;
      thisAlert.zipcode = false;
    }

    const e = { target: { name: "addr", value: { ...data } } };
    setAddr(e);
    setAlert(thisAlert);
  };
  const locationHandler = () => {
    getLocation(addrHandler);
  };

  if (!pws) return <></>;
  return (
    <>
      <div
        className={
          resultOpen
            ? "ng-search-more ng-form nbrown disable"
            : "ng-search-more ng-form nbrown"
        }
      >
        <h4 className="h4">
          <span className="f-xs">*所有欄位均為必填</span>
        </h4>
        <div className="form-group" name="form">
          <label>請選擇您的貸款用途</label>
          <div className="abgne-menu">
            <input
              type="radio"
              id="a"
              name="house"
              value="1"
              checked={data.house === "1"}
              onChange={(e) => {
                dataHandler(e);
              }}
            />
            <label htmlFor="a">
              <BuyHouse />
              <p>我要購置房屋</p>
            </label>
            <input
              type="radio"
              id="b"
              name="house"
              value="2"
              checked={data.house === "2"}
              onChange={(e) => {
                dataHandler(e);
              }}
            />
            <label htmlFor="b">
              <Rent />
              <p>我要投資週轉</p>
            </label>
          </div>
        </div>
        {data.house === "1" && (
          <div className="row-fix" id="why">
            <div className="col-md-12">
              <label>本次購屋是透過</label>
              <div className="el-radio el-radio-yellow through">
                <input
                  type="radio"
                  name="buyhouse"
                  id="sales"
                  value="1"
                  checked={data.buyhouse === "1"}
                  onChange={(e) => {
                    dataHandler(e);
                  }}
                />
                <label className="el-radio-style lbtop" htmlFor="sales"></label>
                <span>專案合作房仲業者</span>
                <span className="info">
                  <a
                    className="poppover-body"
                    onMouseEnter={() => onHover(true)}
                    onMouseLeave={() => onHover(false)}
                    href="//#endregion"
                  >
                    <img src={Photos.info} alt="" />
                  </a>
                </span>
                {mouseOn && (
                  <div
                    className="popover fade bs-popover-bottom show"
                    style={{
                      position: "absolute",
                      transform: "translate3d(190px, 600px, Opx)",
                      top: "20px",
                      left: "40px",
                      width: "270px",
                      willchange: "transform",
                    }}
                  >
                    <div className="arrow" style={{ left: "124px" }} />
                    <div className="popover-body">{pws.collaboration}</div>
                  </div>
                )}
                <span className="loan-wrap"></span>
                <input
                  type="radio"
                  name="buyhouse"
                  id="other"
                  value="2"
                  checked={data.buyhouse === "2"}
                  onChange={(e) => {
                    dataHandler(e);
                  }}
                />
                <label className="el-radio-style lbtop" htmlFor="other"></label>
                <span>其他房仲 或 自行洽購</span>
              </div>
            </div>
          </div>
        )}

        {/* <hr className="hr" /> */}
        <div className="row">
          <div className="col-md-12">
            <h4 className="h4">請點選我的位置 或 輸入地址</h4>
            <div className="form-group">
              <button
                type="button"
                className="btn nbtn-myAddress"
                onClick={locationHandler}
              >
                <i className="fas fa-map-marker-alt"></i> 我的位置
              </button>
              <AddrTwZipCode
                setData={addrHandler}
                data={addrData}
                alert={alert}
              />
            </div>
          </div>
        </div>
        {/* <hr className="hr" /> */}
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label>年齡</label>
              <div className="search-input">
                <input
                  type="number"
                  name="user_age"
                  className={
                    alert.user_age ? "form-control empty-note" : "form-control"
                  }
                  placeholder={getPlaceHolder("age")}
                  value={data.user_age}
                  onChange={(e) => {
                    middleHandler(e);
                  }}
                />
                <span>歲</span>
              </div>
              {alert.user_age && (
                <span className="keyin-txt" style={{ color: "red" }}>
                  請輸入正確資訊
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label>子女數</label>
              <div className="search-input">
                <input
                  type="number"
                  name="user_children"
                  className={
                    alert.user_children
                      ? "form-control empty-note"
                      : "form-control"
                  }
                  placeholder={getPlaceHolder("childNum")}
                  value={data.user_children}
                  onChange={(e) => {
                    middleHandler(e);
                  }}
                />
                <span>人</span>
              </div>
              {alert.user_children && (
                <span className="keyin-txt" style={{ color: "red" }}>
                  請輸入正確資訊
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group drop-width-40">
              <label>職業別</label>
              <select
                name="user_job"
                className={
                  alert.user_job ? "form-control empty-note" : "form-control"
                }
                value={data.user_job}
                onChange={(e) => {
                  middleHandler(e);
                }}
              >
                {JobOptions}
              </select>
              {alert.user_job && (
                <span className="keyin-txt" style={{ color: "red" }}>
                  請輸入正確資訊
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group drop-width-40">
              <label>職稱</label>
              <select
                id="inputState2"
                name="user_title"
                className={
                  alert.user_title ? "form-control empty-note" : "form-control"
                }
                value={data.user_title}
                onChange={(e) => {
                  middleHandler(e);
                }}
              >
                {TitleOptions}
              </select>
              {alert.user_title && (
                <span className="keyin-txt" style={{ color: "red" }}>
                  請輸入正確資訊
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>年收入</label>
              <div className="search-input">
                <input
                  type="number"
                  name="user_income"
                  className={
                    alert.user_income
                      ? "form-control empty-note"
                      : "form-control"
                  }
                  placeholder={getPlaceHolder("yearSalary")}
                  id="year-income"
                  value={data.user_income}
                  onChange={(e) => {
                    middleHandler(e);
                  }}
                />
                <span>萬</span>
              </div>
              {alert.user_income && (
                <span className="keyin-txt" style={{ color: "red" }}>
                  請輸入正確資訊
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label>購屋成交總價</label>
              <div className="search-input">
                <input
                  type="number"
                  className={
                    alert.total_price
                      ? "form-control empty-note"
                      : "form-control"
                  }
                  placeholder={getPlaceHolder("housePrice")}
                  value={data.total_price}
                  id="total_price"
                  name="total_price"
                  onChange={(e) => {
                    middleHandler(e);
                  }}
                />
                <label id="total_price-error" style={{ display: "none" }}>
                  請輸入正確資訊
                </label>
                <span>萬</span>
              </div>
              {alert.total_price && (
                <span className="keyin-txt" style={{ color: "red" }}>
                  請輸入正確資訊
                </span>
              )}
            </div>
          </div>
        </div>

        {/* <hr className="hr" /> */}
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-4">
            <label>是否持有信用卡</label>
            <div className="el-radio el-radio-yellow through">
              <input
                type="radio"
                name="creditcard"
                id="creditcard1"
                value="0"
                checked={data.creditcard === "0"}
                onChange={(e) => {
                  dataHandler(e);
                }}
              />
              <label className="el-radio-style" htmlFor="creditcard1"></label>
              <span className="radiospan">否</span>
              <input
                type="radio"
                name="creditcard"
                id="creditcard2"
                onChange={(e) => {
                  dataHandler(e);
                }}
                checked={data.creditcard === "1"}
                value="1"
              />
              <label className="el-radio-style" htmlFor="creditcard2"></label>
              <span className="radiospan">是</span>
            </div>
          </div>
          {data.creditcard === "1" && (
            <div
              className="col-lg-4 col-md-6 mt-4 credit"
              style={{ display: "block" }}
            >
              <label>是否按時全額繳清</label>
              <div className="el-radio el-radio-yellow through">
                <input
                  type="radio"
                  name="pay"
                  id="pay1"
                  checked={data.pay === "0"}
                  value="0"
                  onChange={(e) => dataHandler(e)}
                />
                <label className="el-radio-style" htmlFor="pay1"></label>
                <span className="radiospan">否</span>
                <input
                  type="radio"
                  name="pay"
                  id="pay2"
                  checked={data.pay === "1"}
                  value="1"
                  onChange={(e) => dataHandler(e)}
                />
                <label className="el-radio-style" htmlFor="pay2"></label>
                <span className="radiospan">是</span>
              </div>
            </div>
          )}
          {data.creditcard === "1" && (
            <div
              className="col-lg-4 col-md-6 mt-4 credit"
              style={{ display: "block" }}
            >
              <label>是否有預借現金</label>
              <div className="el-radio el-radio-yellow through">
                <input
                  type="radio"
                  name="borrow"
                  id="borrow1"
                  checked={data.borrow === "0"}
                  value="0"
                  onChange={(e) => dataHandler(e)}
                />
                <label className="el-radio-style" htmlFor="borrow1"></label>
                <span className="radiospan">否</span>
                <input
                  type="radio"
                  name="borrow"
                  id="borrow2"
                  value="1"
                  checked={data.borrow === "1"}
                  onChange={(e) => dataHandler(e)}
                />
                <label className="el-radio-style" htmlFor="borrow2"></label>
                <span className="radiospan">是</span>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-4">
            <label>是否持有現金卡</label>
            <div className="el-radio el-radio-yellow through">
              <input
                type="radio"
                name="cashcard"
                onChange={(e) => {
                  middleHandler(e);
                }}
                value="0"
                id="cashcard1"
                checked={data.cashcard === "0"}
              />
              <label className="el-radio-style" htmlFor="cashcard1"></label>
              <span className="radiospan">否</span>
              <input
                type="radio"
                name="cashcard"
                onChange={(e) => {
                  middleHandler(e);
                }}
                value="1"
                id="cashcard2"
                checked={data.cashcard === "1"}
              />
              <label className="el-radio-style" htmlFor="cashcard2"></label>
              <span className="radiospan">是</span>
            </div>
          </div>
          {data.cashcard === "1" && (
            <div
              className="col-lg-4 col-md-6 mt-4"
              id="cashpay"
              style={{ display: "block" }}
            >
              <label>是否有現金卡餘額</label>
              <div className="el-radio el-radio-yellow through">
                <input
                  type="radio"
                  name="cashcardbalance"
                  id="cashcardbalance1"
                  value="0"
                  checked={data.cashcardbalance === "0"}
                  onChange={(e) => {
                    middleHandler(e);
                  }}
                />
                <label
                  className="el-radio-style"
                  htmlFor="cashcardbalance1"
                ></label>
                <span className="radiospan">否</span>
                <input
                  type="radio"
                  name="cashcardbalance"
                  id="cashcardbalance2"
                  value="1"
                  checked={data.cashcardbalance === "1"}
                  onChange={(e) => {
                    middleHandler(e);
                  }}
                />
                <label
                  className="el-radio-style"
                  htmlFor="cashcardbalance2"
                ></label>
                <span className="radiospan">是</span>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-lg-12 mt-4">
            <label>是否有信用貸款餘額</label>
            <div className="el-radio el-radio-yellow through">
              <input
                type="radio"
                name="balance"
                id="balance1"
                value="0"
                checked={data.balance === "0"}
                onChange={(e) => {
                  middleHandler(e);
                }}
              />
              <label className="el-radio-style" htmlFor="balance1"></label>
              <span className="radiospan">否</span>
              <input
                type="radio"
                name="balance"
                id="balance2"
                value="1"
                checked={data.balance === "1"}
                onChange={(e) => {
                  middleHandler(e);
                }}
              />
              <label className="el-radio-style" htmlFor="balance2"></label>
              <span className="radiospan">是</span>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-group">
        {resultOpen ? (
          <a
            className="btn btn-return"
            style={{ lineHeight: "35px" }}
            onClick={(e) => {
              e.preventDefault();
              resultOpenHandler(false);
            }}
            href="//#endregion"
          >
            重新查詢
          </a>
        ) : (
          <a
            id=""
            className="btn btn-search a-btn btn-track"
            data-title="查詢"
            onClick={(e) => {
              e.preventDefault();
              submitHandler();
            }}
            href="//#endregion"
          >
            查詢
          </a>
        )}
      </div>
    </>
  );
};

export default MortgageForm;

const titles = [
  "請選擇",
  "一般職員",
  "公務人員",
  "公立學校教師/正式職員",
  "會計師、律師、建築師",
  "醫師",
  "上市/櫃負責人",
  "上市/櫃總經理、副總、執行長",
  "上市/櫃董監事級主管級人員",
  "非上市/櫃負責人",
  "非上市/櫃總經理、副總、執行長",
  "非上市/櫃董監事級主管級人員",
  "服務人員",
  "退休、家管",
  "其他",
];

const TitleOptions = titles.map((title, i) => {
  return (
    <option key={i} value={title}>
      {title}
    </option>
  );
});

const jobs = [
  "請選擇",
  "政府/公共事業",
  "教育業",
  "軍/警/消防",
  "醫療保健業",
  "金融保險業",
  "資訊及通訊業",
  "貿易/自營",
  "服務業",
  "製造業",
  "營造業",
  "運輸倉儲業",
  "自由業",
  "專業事務所",
  "農、林、漁、牧、礦業",
];
const JobOptions = jobs.map((job, i) => {
  return (
    <option key={i} value={job}>
      {job}
    </option>
  );
});

const getSubmitObj = (addrData, data) => {
  //使用者從房屋估價來，才會有預估總價(estimate_total_price_av)
  const estimate_total_price_av = data.estimate_total_price_av
    ? formatFilter(data.estimate_total_price_av, "totalPrice")
    : 0;

  return ObjFilterHandler({
    county: addrData.county,
    district: addrData.district,
    addr: addrData.addr,
    estimateTotalPriceAv: estimate_total_price_av,
    level: 0,
    levelSelect: 0,
    bAreaP: 0,
    bTypeInt: 0,
    house: data.house,
    buyhouse: data.buyhouse,
    userAge: data.user_age,
    userChildren: data.user_children,
    userJob: data.user_job,
    userTitle: data.user_title,
    userIncome: data.user_income,
    totalPrice: data.total_price,
    creditcard: data.creditcard,
    pay: data.pay,
    borrow: data.borrow,
    cashcard: data.cashcard,
    cashcardbalance: data.cashcardbalance,
    balance: data.balance,
    priority: CalcuPriority(data),
  });
};

const CalcuPriority = (data) => {
  //point 判斷
  let point = 0;
  const creditcard = data.creditcard === "1" ? true : false; //1: 有 0:無
  const cashcard = data.cashcard === "1" ? true : false; //1: 有 0:無
  //信用卡point

  if (creditcard && data.pay !== "1") {
    //按時繳費? 1:有 0:無
    point = point + 2;
  }

  if (creditcard && data.borrow === "1") {
    //有預借
    //預借? 1:有 0:無
    point = point + 2;
  }

  //現金卡 1:有 0:無
  if (cashcard && data.cashcardbalance === "1") {
    //餘額 1:有 0:無
    point = point + 2;
  } else if (cashcard) {
    //有現金卡
    point = point + 1;
  }

  //貸款 point
  if (data.balance === "1") {
    // 貸款 1:有 0:無
    point = point + 2;
  }

  //group判斷

  const groupA = [
    "政府/公共事業",
    "政府/公共事業",
    "教育業",
    "醫療保健業",
    "金融保險業",
    "資訊及通訊業",
    "自由業",
    "專業事務所",
  ];
  let group = groupA.includes(data.user_job) ? 1 : 2;

  //Ａ 特Ａ 級別判斷

  let priority = "A";
  const income = data.user_income;

  if (creditcard) {
    if (group === 1) {
      priority = (income > 40 && point < 2) || point === 0 ? "AA" : "A";
    } else if (group === 2) {
      priority =
        (income > 90 && point < 2) || (income > 40 && point === 0) ? "AA" : "A";
    }
  }

  return priority;
};
