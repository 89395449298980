import { SpringBootServer, point, TrueMap } from "../../../StaticResource";
import { getItemFromSessionStorage } from "../../Utils/BrowserStorageUtils";

const fetchEstimateJson = (data, callBack) => {
  const _token = getItemFromSessionStorage("_token")
  const url = TrueMap
    ? `${SpringBootServer}/estimateJson?_token=${_token}&lat=${data.lat}&lng=${data.lng}&county=${data.county}&district=${data.district}&addr=${data.addr}`
    : `${SpringBootServer}/estimateJson?_token=${_token}&lat=${point.lat}&lng=${point.lng}&county=臺北市&district=中正區&addr=中正國中`;
  fetch(encodeURI(url))
    .then(res => {
      return res.json();
    })
    .then(datas => callBack(datas));
};

const fetchEstimateData = (mapCenter, callBackMethod) => {
  const url = TrueMap
    ? `${SpringBootServer}/estimatedata?lat=${mapCenter.lat()}&lon=${mapCenter.lng()}`
    : `${SpringBootServer}/estimatedata?lat=${point.lat}&lon=${point.lng}`;
  fetch(encodeURI(url))
    .then(res => res.json())
    .then(data => callBackMethod(data))
    .catch(err => alert(err));

  return null;
};


export { fetchEstimateData, fetchEstimateJson };
