import { SpringBootServer, point, TrueMap } from "../../../StaticResource";
export { fetchEstimateData, fetchEstimateJson } from "./fetchEstimate";
export {
  fetchAdvanceEstimateData,
  fetchAdvancePoi,
  fetchAdvancePrice,
  fetchUserRecord,
} from "./fetchAdvance";
export { fetchMortgageResult } from "./fetchMortgage";
export { sendSurvey } from "./fetchSurvey";
export { fetchCaptcha, validateCaptchaCode } from "./fetchCaptcha";
export { fetchPuw } from "./fetchPuw";
export { getCode, validateCode } from "./fetchValidate";
export { fetchToken } from "./fetchWhenStart";
export { fetchContact } from "./fetchContact";

const fetchEstimatemegabranch = (mapCenter, callBackMethod) => {
  const url = TrueMap
    ? `${SpringBootServer}/estimatemegabranch?lat=${mapCenter.lat()}&lon=${mapCenter.lng()}`
    : `${SpringBootServer}/estimatemegabranch?lat=${point.lat}&lon=${point.lng}`;
  fetch(url)
    .then((res) => res.json())
    .then((data) => callBackMethod(data))
    // .catch((err) => alert(err));

  return null;
};

export { fetchEstimatemegabranch };
