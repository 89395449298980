import { SpringBootServer, testMode } from "../../../StaticResource";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";

const fetchCaptcha = (setCaptcha) => {
  const _token = getItemFromSessionStorage("_token");
  const url = `${SpringBootServer}/captcha`;

 fetch(url, {
   method: "POST",
    body: JSON.stringify({
      _token: _token,
    }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      setCaptcha(data.captchaImg);

      storeItemInSessionStorage("_token", data._token);
    });
};

const validateCaptchaCode = async (code, setCaptcha) => {
  const _token = getItemFromSessionStorage("_token");
  const url = `${SpringBootServer}/captcha/verify`;
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      _token: _token,
      inputCaptcha: code,
    }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      //換新圖片
      setCaptcha(data.captchaImg);

      //確認有無更換 token
      const tokenStatus = _token === data._token;
      if (!tokenStatus) {
        storeItemInSessionStorage("_token", data._token);
      }

      if (testMode) {
        console.log("validate: " + data.validate);
      }
      //確認驗證碼是否正確
      var result = data.validate;

      return result;
    });
};

export { fetchCaptcha, validateCaptchaCode };
