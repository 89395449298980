import React from "react";
import { formatFilter } from "../../Utils/Filter/Filter";

const DataRow = ({ datas }) => {
  return datas.map((item, i) => {
    const oriDate = item.updatedAt.split(" ")[0];
    const date = oriDate.split("-").join("/");
    return (
      <tr key={i}>
        <td>{date}</td>
        <td>{getBTypeIntTitle(item.bTypeInt)}</td>
        <td>{item.addr}</td>
        <td>{formatFilter(item.bAreaP, "thousand")}</td>
        <td>{item.av500 ? formatFilter(item.av500, "housePrice") : "*"}</td>
        <td>{item.av1000 ? formatFilter(item.av1000, "housePrice") : "*"}</td>
        <td>{formatFilter(item.av750, "housePrice")}</td>
        <td>
          {formatFilter(
            (Number(item.bAreaP) * Number(item.av750)).toFixed(2),
            "thousand"
          )}
        </td>
      </tr>
    );
  });
};

export default DataRow;

export const getBTypeIntTitle = (bTypeInt) => {
  switch (bTypeInt) {
    case "1":
      return "無電梯公寓";
    case "2":
      return "別墅 / 透天厝";
    case "3":
      return "店面 (店鋪)";
    case "4":
      return "辦公商業大樓";
    case "6":
      return "電梯大樓 / 華廈";
    case "7":
      return "套房";
    default:
  }
};
