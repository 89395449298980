import React from "react";
import { getPlaceHolder } from "./DefaultSetting/PlaceHolder";

const Captcha = ({ captcha, captchaHandler, code, setCode, alert }) => {
  return (
    <div className="col-md-12">
      <h4 className="h4 mb-3">圖形驗證碼</h4>
      <div className="form-group g-code">
        <span className="imgnumber">
          <input
            id="captcha"
            className={alert.code ? "form-control empty-note" : "form-control"}
            name="captcha"
            type="text"
            value={code}
            onChange={e => {
              setCode(e);
            }}
            placeholder={getPlaceHolder("captcha")}
            autoComplete="false"
            aria-invalid="false"
          />
          {alert.code && (
            <div
              className="is-invalid small"
              style={{ display: "block", color: "red" }}
            >
              請輸入正確資訊
            </div>
          )}
        </span>
        <img src={captcha} />
        <div
          className="btn btn_refresh"
          title="換一張"
          onClick={captchaHandler}
        />
      </div>
    </div>
  );
};

export default Captcha;
