import React, { useState, useEffect } from "react";
import { marqueeTime } from "../../../StaticResource";
import { fetchItem } from "../Fetch/fetchWhenStart";
const Marquee = ({ setOpen }) => {
  const [marquees, setMarquee] = useState();
  const [index, setIndex] = useState(1);
  const [runtimes, setRuntimes] = useState(0);
  useEffect(() => {
    fetchItem("marquee", marqueeHandler);
  }, []);

  const marqueeHandler = (returnObj) => {
    setMarquee(returnObj);
  };

  if (marquees === undefined) return <></>;

  if(checkMarqueeBlank(marquees)) return <></>
  const timer = () => {
    setTimeout(() => {
      let value = index;
      value++;
      if (value > 5) {
        setIndex(0);
      } else {
        while (marquees[`message${value}`] === "") {
          value++;
          if (value > 5) {
            value = 1;
            const thisRuntimes = runtimes + 1;
            setRuntimes(thisRuntimes);
          }
        }
        setIndex(value);
      }
      if (runtimes > 10) {
        clearInterval(timer);
      }
    }, marqueeTime);
  };

  timer();

  return (
    <div className="hint" style={{ zIndex: 2 }}>
      <div className="nwn">
        {marquees[`url${index}`] === "" ? (
          marquees[`message${index}`]
        ) : (
          <a
            href={marquees[`url${index}`] ? marquees[`url${index}`] : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            {marquees[`message${index}`]}
          </a>
        )}
        <a href="" className="close-btn" onClick={(e) => e.preventDefault()}>
          <i className="fas fa-times-circle" onClick={() => setOpen(false)} />
        </a>
      </div>
    </div>
  );
};

export default Marquee;

const checkMarqueeBlank = (marquees) => {
  return marquees[`message1`] === "" &&
    marquees[`message2`] === "" &&
    marquees[`message3`] === "" &&
    marquees[`message4`] === "" &&
    marquees[`message5`] === ""
    ? true
    : false;
};
