import React from "react";
import MarkDown from "../../Utils/MarkDownUtils/MarkDown";

const footnote = ({ note }) => {
  const Footnotes = ({content}) => <MarkDown content={content}>{content}</MarkDown>;
  if (note.length === 0) return <></>;
  return (
    <div className="col-lg-12 mb-4">
      <div className="msg-note-group">
        <div className="msg-note">
          附註：
          <br />
          <Footnotes content={note} />
        </div>
      </div>
    </div>
  );
};

export default footnote;
