import React from "react";
import { version } from "../../../StaticResource";

import FunctionArea from "./FunctionArea/";
import Recommend from "./Recommend/";
const Content = () => {
  return (
    <div className="container">
        <FunctionArea />
        <Recommend />
    </div>
  );
};

export default Content;
