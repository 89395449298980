import React, { useState, useEffect } from "react";
import { fetchPuw } from "../Fetch/fetchPuw";
import { contactEmail } from "../../../StaticResource";

const AddrWarning = ({ closeWarning }) => {
  const [puw, setPuw] = useState();

  useEffect(() => {
    fetchPuw(setPuw);
  },[]);

  if (!puw) return <></>;
  const addrError = puw.addrError;

  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden", marginTop:"65px" }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div
              id="popup-2-1-1"
              className="g-popup-group smscode-group mfp-with-zoom"
              style={{ marginTop:"65px" }}
            >
              <div className="icon mg-25"></div>
              <h3 className="h3 mg-25">抱歉!該地址無法完成定位</h3>
              <p className="text mg-25">
                {addrError.addrString1}
              </p>
              <div className="msg-note">
                {addrError.addrString2}
                <a
                  href="https://wwwfile.megabank.com.tw/about/about04.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  服務據點
                </a>
              </div>
              <div className="msg-note">
                或填寫
                <a
                  href={contactEmail}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  聯絡資料
                </a>
                {addrError.addrString3}
              </div>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={closeWarning}
                style={{ color: "black" }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddrWarning;
