import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchItem } from "../../Fetch/fetchWhenStart";
// import datas from "./data"

const FunctionArea = () => {
  const [datas, setDatas] = useState();

  useEffect(() => {
    fetchItem("functionButton", dataHandler);
  }, []);

  const dataHandler = (returnObj) => {
    setDatas(returnObj);
  };

  if (datas === undefined) return <></>;

  const items = Object.keys(datas);
  const data = items.map((order, i) => {
    const item = { ...datas }[order];
    return (
      <div className="col-lg-4 col-md12" key={i}>
        <section className="mf">
          <Link to={item.link}>
            <h2>{item.title}</h2>
            <div className="mf-inner">
              <div className="mask-dark"></div>
              <div className="img-wrap">
                <img src={item.photo} alt="" />
              </div>
              <div className="mf-txt">{item.body}</div>
              <div className="goto">
                前往頁面<i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </section>
      </div>
    );
  });

  return <div className="main-function row">{data}</div>;
};

export default FunctionArea;
