import React, { useEffect, useState } from "react";
import AdvanceBody from "./AdvanceBody";
import ValidationContainer from "../Verify/ValidationContainer";
import { getItemFromSessionStorage } from "../../Utils/BrowserStorageUtils";
import { validateTimes } from "../../../StaticResource";
import { addrTemplate } from "../../../App";
import { eventDataFiltedHandler } from "../../Utils/Filter/Filter";

const AdvanceEstimate = ({
  status,
  setStatus,
  applyHandler,
  contactOpenHandler,
  addrData,
  setAddr,
}) => {
  const [data, setData] = useState();

  const dataHandler = (e) => {
    const itemName = e.target.name;
    const value = e.target.value;

    const newdata = (itemName, value) => {
      const obj = eventDataFiltedHandler(itemName, value);

      let updateData = { ...data };
      switch (itemName) {
        case "LEVEL_SELECT":
          updateData.LEVEL_SELECT = value;
          if (value === "2") {
            updateData.LEVEL = "";
          }
          return updateData;
        case "PARKING":
          updateData.PARKING = value;
          if (value === "1") {
            updateData.parking_p = "";
            updateData.parking_qty = "";
          }
          return updateData;
        default:
          updateData[itemName] = obj[itemName];
          return updateData;
      }
    };
    setData(newdata(itemName, value));
  };

  const cleanData = () => {
    setData(template);
    const obj = { target: { value: addrTemplate } };
    setAddr(obj);
  };

  useEffect(() => {
    const queryTimes = getItemFromSessionStorage("queryTimes");
    if (queryTimes) {
      if (validateTimes <= queryTimes) {
        setStatus("1");
      }
    }
    setData(template);
  }, []);
  if (data === undefined ||addrData===undefined) return <></>;
  return (
    <>
      {status === "3" ? (
        <AdvanceBody
          data={data}
          dataHandler={dataHandler}
          addrData={addrData}
          setAddr={setAddr}
          status={status}
          setStatus={setStatus}
          applyHandler={applyHandler}
          contactOpenHandler={contactOpenHandler}
          cleanData={cleanData}
        />
      ) : (
        <ValidationContainer status={status} setStatus={setStatus} />
      )}
    </>
  );
};

export default AdvanceEstimate;

const template = {
  B_TYPE_INT: "6", //房型 6: 電梯大樓 / 華廈,  1: 無電梯公寓, 7: 套房, 2: 別墅 / 透天厝, 3: 店面 (店鋪), 4: 辦公商業大樓
  B_AGE: "", //屋齡
  B_AREA_P: "", //總面積
  FLOORS: "", //總樓層數
  LEVEL_SELECT: "1", //1: 地上，2:地下
  LEVEL: "", //居住樓層
  PARKING: "1", //1: 無車位 2: 機械車位 3: 非機械車位
  parking_qty: "", //車位數量
  parking_p: "", //車位總面積
};
