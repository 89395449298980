import React from "react";

const Checkbox = ({
  spanClass,
  name,
  defaultCheck,
  functionHandler,
  id,
  dataId,
  category
}) => {
  const checkedHandler = category => {
    functionHandler(category);
  };

  return (
    <li>
      <label className="g-checkbox mg-0" htmlFor={id}>
        <p>{name}</p>
        <span className={spanClass}/>
        <input
          type="checkbox"
          defaultChecked={defaultCheck}
          onChange={()=>checkedHandler(category)}
        />
        <span className="checkmark"></span>
      </label>
    </li>
  );
};

export default Checkbox;
