import React, { useState, useEffect } from "react";
// import catelog from "../../../ProductSet";
import Card from "../Product/Card";
import Photos from "../../../Photo";
import Banner from "../Banner";
import FootNote from "../FootNote/FootNote";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";

const Catelog = ({
  match,
  products,
  applyHandler,
  contactOpenHandler,
  catelog,
  status,
  setStatus,
}) => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    if (status === "2") setStatus("1");
  }, []);

  if (!catelog || !products) return <></>;
  const params = { ...match.params };
  const catelogTitle = Object.keys(catelog).map((title, i) => {
    let dressClass = title === params.id ? "nav-link active" : "nav-link";
    return (
      <li className="nav-item" key={i}>
        <Link to={`/products/${title}`} className={dressClass}>
          {title}
        </Link>
      </li>
    );
  });

  const miniPage = params.id;

  const items = catelog[miniPage]
    ? catelog[miniPage].map((productObj, index) => {
        return <Card product={{ ...products[productObj.order] }} key={index} />;
      })
    : "";

  return (
    <>
      <div
        id="valuation"
        className="g-grid none result"
        style={{ paddingTop: "66px" }}
      >
        <Banner text={"房貸產品"} photo={Photos.banner1} />
        <div className="bg-gray">
          <div className="container position-relative">
            <ul className="nav nav-pills tab-style" id="myTab" role="tablist">
              {catelogTitle}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row">{items}</div>
                {trackPageView({
                  documentTitle: "房貸產品目錄", // optional
                  //202208 Vulnerability Project Modify -- begin
                  //因非必須資料且為高風險資料，因此mark
                  // href: hashReplacer(window.document.location.href), // optional
                  //202208 Vulnerability Project Modify -- end
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FootNote
        applyHandler={applyHandler}
        contactOpenHandler={contactOpenHandler}
      />
    </>
  );
};

export default Catelog;
