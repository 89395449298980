import { SpringBootServer } from "../../../StaticResource";
import {
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";
import { formatFilter } from "../../Utils/Filter/Filter";
import getLatLng from "./GetLatLng";
const fetchMortgageResult = (
  submitObj,
  setMortgageData,
  resultOpenHandler,
  setStatus
) => {
  const callBack = (mapCenter) => {
    submitObj.lat = mapCenter.lat() ? mapCenter.lat():0;
    submitObj.lng = mapCenter.lng() ? mapCenter.lng():0;

    fetchData(submitObj, setMortgageData, resultOpenHandler, setStatus);
  };

  getLatLng(submitObj, callBack);
};

const fetchData = (
  submitObj,
  setMortgageData,
  resultOpenHandler,
  setStatus
) => {
  const _token = getItemFromSessionStorage("_token");
  const url = `${SpringBootServer}/estimate/mortgage/result`;

  submitObj._token = _token;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(submitObj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res.json();
      }
    })
    .then((data) => {
      const rate = data.rate;
      const top_loan_credit = data.topLoanCredit;
      const recommendProducts = data.recommendProducts;
      const estimateCount = data.estimateCount;
      const obj = {
        rate: rate,
        top_loan_credit: top_loan_credit,
        loan_time: 20,
        recommend: recommendProducts,
        estimateCount: estimateCount,
        priority: data.priority,
        house: data.house,
      };

      setMortgageData(obj);
      storeItemInSessionStorage("loan", formatFilter(top_loan_credit, "loan"));
      resultOpenHandler(true);
    })
    .catch((error) => {
      console.log(error);
      alert("驗證過期，請重新領取驗證碼並再次驗證");
      setStatus("1");
    });
};

export { fetchMortgageResult };
