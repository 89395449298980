import { SpringBootServer } from "../../../StaticResource";
import {
  getItemFromStorage,
  storeItemToStorage,
  getItemFromSessionStorage,
  storeItemInSessionStorage,
} from "../../Utils/BrowserStorageUtils";

const fetchToken = () => {
  var _token = getItemFromSessionStorage("_token");

  if (!_token) {
    const url = `${SpringBootServer}/token/get`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => storeItemInSessionStorage("_token", data._token))
      .catch((err) => {
        alert("伺服器忙線中，請稍後再試");
      });
  }

  return null;
};

const fetchItem = (itemName, callBack) => {
  const item = getItemFromStorage(itemName);

  if (item) {
    console.log(`get-${itemName}-FromLocalStorage`);
    callBack(item);
  } else {
    console.log(`get-${itemName}-FromInternet`);
    const url = `${SpringBootServer}${getUrl(itemName)}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        callBack(data);
        storeItemToStorage(itemName, data);
      });
  }
};

const getUrl = (itemName) => {
  switch (itemName) {
    case "banner":
      return "/banner/get";
    case "socialMedia":
      return "/message/socialmedia/get";
    case "category":
      return "/category";
    case "products":
      return "/products";
    case "recommend":
      return "/pageproducts/get";
    case "functionButton":
      return "/functionButton/get";
    case "marquee":
      return "/blackad/get";
    case "pws":
      return "/pws/get";
    default:
  }
};

export { fetchToken, fetchItem };
