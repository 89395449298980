import icon1 from "../../../img/product-icon1.svg";
import icon2 from "../../../img/product-icon2.svg";
import icon3 from "../../../img/product-icon3.svg";
import icon4 from "../../../img/product-icon4.svg";
import icon5 from "../../../img/product-icon5.svg";
import icon6 from "../../../img/product-icon6.svg";
import icon7 from "../../../img/product-icon7.svg";
import icon8 from "../../../img/product-icon8.svg";
import icon9 from "../../../img/product-icon9.svg";

export const getIcons = name => {
  switch (name) {
    case "Photos.icon1" || "icon1":
      return icon1;
    case "Photos.icon2" || "icon2":
      return icon2;
    case "Photos.icon3" || "icon3":
      return icon3;
    case "Photos.icon4" || "icon4":
      return icon4;
    case "Photos.icon5" || "icon5":
      return icon5;
    case "Photos.icon6" || "icon6":
      return icon6;
    case "Photos.icon7" || "icon7":
      return icon7;
    case "Photos.icon8" || "icon8":
      return icon8;
    case "Photos.icon9" || "icon9":
      return icon9;
    default:
      return;
  }
};
