import React, { useEffect } from "react";
import RateResult from "./RateResult";
import Counting from "./Counting";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";

const MortgageResult = ({ data }) => {
  const { trackPageView } = useMatomo();
  useEffect(()=>{
    trackPageView({
      documentTitle: "觀看額度利率試算結果", // optional
      href: `${hashReplacer(window.document.location.href)}/result`, // optional
    })
  })
  return (
    <>
      <RateResult data={data} />
      <Counting data={data} />
    </>
  );
};

export default MortgageResult;
