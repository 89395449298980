import React, { useState, useEffect } from "react";
import Marker from "./Marker";
import InfoWindow from "./InfoWindow";
import { point, TrueMap } from "../../../StaticResource";

const Map = ({
  id,
  children,
  gmarkers,
  options,
  mapCenter,
  showCenter,
  showInfoWindow,
  style,
  addr,
}) => {
  const [map, setMap] = useState();

  useEffect(() => {
    onScriptLoad();
  }, [mapCenter]);

  const onScriptLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(id),
      options
    );
    setMap(map);
  };

  const renderChildren = () => {
    if (!children) return;
    return React.Children.map(children, (c) => {
      //children 可包含很多子children，用 map 逐一取出，若遇到子childre===null，避開不處理
      if (c === null) return;
      return React.cloneElement(c, {
        //如果子children!==null，則將map, markers元素複製給子children
        map: map,
        gmarkers: gmarkers,
      });
    });
  };

  return (
    <div style={style} id={id}>
      {showCenter && (
        <Marker
          lat={TrueMap ? mapCenter.lat() : point.lat}
          lng={TrueMap ? mapCenter.lng() : point.lng}
          visible={showCenter}
          setInfowindow={showInfoWindow}
          infoContent={addr}
          map={map}
        />
      )}
      {renderChildren()}
    </div>
  );
};

export default Map;
export { Marker, InfoWindow };
