import React, { useState, useEffect } from "react";
import NavItem from "./NavItem";
import NavItem2 from "./NavItem2";
import Photos from "../../../../../Photo";
import { show_other_building } from "../../../../../StaticResource";
const NavList = ({ gmarkers, data, counts }) => {
  const [actived, setActived] = useState(true);
  const [navClassName, setNavClassName] = useState("nav");
  const [type1, setType1] = useState(Number(data.B_TYPE_INT) === 1);
  const [type2, setType2] = useState(Number(data.B_TYPE_INT) === 2);
  const [type3, setType3] = useState(Number(data.B_TYPE_INT) === 3);
  const [type4, setType4] = useState(Number(data.B_TYPE_INT) === 4);
  const [type6, setType6] = useState(Number(data.B_TYPE_INT) === 6);
  const [type7, setType7] = useState(Number(data.B_TYPE_INT) === 7);
  const [type9, setType9] = useState(true);
  const [type11, setType11] = useState(true);
  const [type18, setType18] = useState(true);
  const [houseNumber, setHouseNumber] = useState(0);
  const [range, setRange] = useState("1");
  const [areaCounts, setAreaCounts] = useState();
  useEffect(() => {
    setAreaCountingNumber();
    if (areaCounts === undefined) {
      initCounter(counts);
    } else {
      normalCounter();
      markerHandler();
    }
  }, [
    counts,
    type1,
    type2,
    type3,
    type4,
    type6,
    type7,
    type9,
    type11,
    type18,
    range,
  ]);

  const setAreaCountingNumber = () => {
    const thisCounts = {};
    if (counts === undefined) return;
    thisCounts.countDis500TypeIs1 = counts.countDis500TypeIs1;
    thisCounts.countDis500TypeIs2 = counts.countDis500TypeIs2;
    thisCounts.countDis500TypeIs3 = counts.countDis500TypeIs3;
    thisCounts.countDis500TypeIs4 = counts.countDis500TypeIs4;
    thisCounts.countDis500TypeIs6 =
      counts.countDis500TypeIs6 + counts.countDis500TypeIs5;
    thisCounts.countDis500TypeIs7 = counts.countDis500TypeIs7;
    thisCounts.countDis1kTypeIs1 = counts.countDis1kTypeIs1;
    thisCounts.countDis1kTypeIs2 = counts.countDis1kTypeIs2;
    thisCounts.countDis1kTypeIs3 = counts.countDis1kTypeIs3;
    thisCounts.countDis1kTypeIs4 = counts.countDis1kTypeIs4;
    thisCounts.countDis1kTypeIs6 =
      counts.countDis1kTypeIs6 + counts.countDis1kTypeIs5;
    thisCounts.countDis1kTypeIs7 = counts.countDis1kTypeIs7;
    setAreaCounts(thisCounts);
  };

  const initCounter = (counts) => {
    if (counts === undefined) return;
    var number = 0;
    switch (data.B_TYPE_INT) {
      case "1":
        number = counts.countDis1kTypeIs1;
        break;
      case "2":
        number = counts.countDis1kTypeIs2;
        break;
      case "3":
        number = counts.countDis1kTypeIs3;
        break;
      case "4":
        number = counts.countDis1kTypeIs4;
        break;
      case "6":
        number = counts.countDis1kTypeIs5 + counts.countDis1kTypeIs6;
        break;
      case "7":
        number = counts.countDis1kTypeIs7;
        break;
      default:
        // alert("initCounterErr");
    }

    setHouseNumber(number);
  };

  const normalCounter = () => {
    if (areaCounts === undefined) return;
    var number = 0;
    if (type1)
      number +=
        range === "1"
          ? areaCounts.countDis1kTypeIs1
          : areaCounts.countDis500TypeIs1;
    if (type2)
      number +=
        range === "1"
          ? areaCounts.countDis1kTypeIs2
          : areaCounts.countDis500TypeIs2;
    if (type3)
      number +=
        range === "1"
          ? areaCounts.countDis1kTypeIs3
          : areaCounts.countDis500TypeIs3;
    if (type4)
      number +=
        range === "1"
          ? areaCounts.countDis1kTypeIs4
          : areaCounts.countDis500TypeIs4;
    if (type6)
      number +=
        range === "1"
          ? areaCounts.countDis1kTypeIs6
          : areaCounts.countDis500TypeIs6;
    if (type7)
      number +=
        range === "1"
          ? areaCounts.countDis1kTypeIs7
          : areaCounts.countDis500TypeIs7;
    setHouseNumber(number);
  };

  function changeHandler(e) {
    setActived(!actived);
    if (actived) {
      setNavClassName("nav actived");
    } else {
      setNavClassName("nav");
    }
  }

  const markerHandler = () => {
    gmarkers.map((marker) => {
      if (
        showMarkerCategory(marker.category) &&
        Number(marker.distance) <= Number(range)
      ) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
      return null;
    });
  };

  const showMarkerCategory = (category) => {
    switch (category) {
      case 1:
        return type1;
      case 2:
        return type2;
      case 3:
        return type3;
      case 4:
        return type4;
      case 6:
        return type6;
      case 7:
        return type7;
      case 9:
        return type9;
      case 11:
        return type11;
      case 18:
        return type18;
      default:
        return true;
    }
  };

  const boxClicked = (category) => {
    setCategories(category);
  };

  const setCategories = (category) => {
    switch (category) {
      case "1":
        return setType1(!type1);
      case "2":
        return setType2(!type2);
      case "3":
        return setType3(!type3);
      case "4":
        return setType4(!type4);
      case "6":
        return setType6(!type6);
      case "7":
        return setType7(!type7);
      case "9":
        return setType9(!type9);
      case "11":
        return setType11(!type11);
      case "18":
        return setType18(!type18);
      default:
    }
  };

  return (
    <>
      <nav className={navClassName} style={{ top: "20%" }}>
        <a
          href="#"
          title="篩選"
          className="btn map-btn"
          onClick={(e) => {
            e.preventDefault();
            changeHandler(e);
          }}
        />
        <div>
          <ul className="reset">
            <NavItem
              className="g-checkbox mg-0 filter f-1"
              defaultCheck={data.B_TYPE_INT === "6"}
              name={"電梯大樓 / 華廈"}
              functionHandler={boxClicked}
              category="6"
            />
            <NavItem
              className="g-checkbox mg-0 filter f-2"
              defaultCheck={data.B_TYPE_INT === "1"}
              name={"無電梯公寓"}
              functionHandler={boxClicked}
              category="1"
            />
            <NavItem
              className="g-checkbox mg-0 filter f-3"
              defaultCheck={data.B_TYPE_INT === "7"}
              name={"套房"}
              functionHandler={boxClicked}
              category="7"
            />
            <NavItem
              className="g-checkbox mg-0 filter f-4"
              defaultCheck={data.B_TYPE_INT === "2"}
              name={"別墅 / 透天厝"}
              functionHandler={boxClicked}
              category="2"
            />
            <NavItem
              className="g-checkbox mg-0 filter f-5"
              defaultCheck={data.B_TYPE_INT === "3"}
              name={"店面 (店鋪)"}
              functionHandler={boxClicked}
              category="3"
            />
            <NavItem
              className="g-checkbox mg-0 filter f-6"
              defaultCheck={data.B_TYPE_INT === "4"}
              name={"辦公商業大樓"}
              functionHandler={boxClicked}
              category="4"
            />
          </ul>

          <ul className="reset">
            <NavItem2
              spanClass="filter f-7"
              defaultCheck={show_other_building}
              name={"市"}
              functionHandler={boxClicked}
              category="11"
            />
            <NavItem2
              spanClass="filter f-9"
              defaultCheck={show_other_building}
              name={"幼"}
              functionHandler={boxClicked}
              category="9"
            />
            <NavItem2
              spanClass="filter f-18"
              defaultCheck={show_other_building}
              name={"特"}
              functionHandler={boxClicked}
              category="18"
            />
            <li>
              <label className="g-checkbox mg-0">
                <p>銀</p>
                <span className="filter f-16"></span>
              </label>
            </li>
          </ul>
        </div>
      </nav>
      <div className="search-range" style={{ marginTop: "0px" }}>
        <div className="a-range">
          <a
            href="//#endregion"
            className={range === "0.5" ? "current" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setRange("0.5");
            }}
          >
            500公尺
          </a>
          <a
            href="//#endregion"
            className={range === "1" ? "current" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setRange("1");
            }}
          >
            1000公尺
          </a>
        </div>
        <div className="range-txt">
          <img src={Photos.IconHouse} alt="" />
          <span style={{fontSize:"16px"}}>
            相似物件於{range === "1" ? "1000" : "500"}公尺內實登件數：
            {houseNumber}件
          </span>
        </div>
      </div>
    </>
  );
};

export default NavList;
