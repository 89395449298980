import React from "react";

const ThankWindow = () => {
  return (
    <>
      <h3 className="h3 mg-25">您的資訊已送出</h3>
      <p className="text mg-25">感謝您使用本網站，將由專人盡速為您服務</p>
    </>
  );
};

export default ThankWindow;
