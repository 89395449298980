import React, { useState, useEffect } from "react";
import { fetchPuw } from "../Fetch/fetchBackend";
const QueryOverAlert = ({ setClose, setStatus }) => {
  const [puw, setPuw] = useState();

  useEffect(() => {
    fetchPuw(setPuw);
  }, []);

  if (!puw) return <></>;
  const reSearch = { ...puw }.reSearch;
  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready" />
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden"}}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div
              id="popup-2-3-1-search"
              className="g-popup-group smscode-group mfp-with-zoom popup-c"
              style={{ marginTop:"65px" }}
            >
              <div className="icon mg-25"></div>
              <h3 className="h3 mg-25">請確認是否重新查詢？</h3>
              <p className="text mg-25">{reSearch.reSearchString1}</p>
              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-cancel sm cancel popup-modal-dismiss"
                  onClick={() => setClose()}
                >
                  取消
                </button>
              </div>
              <div className="mb-4">
                <button
                  type="button"
                  className="btn btn-return sm"
                  onClick={() => {
                    setStatus("1");
                  }}
                >
                  重新查詢
                </button>
              </div>
              <div className="msg-note">{reSearch.reSearchString2}</div>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={() => setClose()}
                style={{ color: "black" }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryOverAlert;
