import React from "react";

const PhoneConfirm = () => {
  return (
    <>
      <h3 className="h3 mg-25">請填入必要資訊</h3>
      <p className="text mg-25">請填入正確的行動電話</p>
    </>
  );
};

export default PhoneConfirm;
