import React, { useState, useEffect } from "react";
import PriceBlock from "./Container2-UI/PriceBlock";
import MapContainer from "./Container2-UI/MapContainer";
import { fetchAdvancePrice } from "../Fetch/fetchBackend";
import Warn from "./Warning";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { hashReplacer } from "../../Utils/Filter/Replacer";

const Container2 = ({
  data,
  addrData,
  mapCenter,
  resultOpen,
  setMapCenter,
  resultOpenHandler,
  setQueryTimeHandler,
  setStatus,
  setLoading,
}) => {
  const [Warning, setWarning] = useState(false);
  const [advanceEstimateData, setAdvanceEstimate] = useState([]);
  const [counts, setCounts] = useState();
  const [price, setPrice] = useState({
    av500: "",
    av750: "",
    av1000: "",
    min500: "",
    min750: "",
    min1000: "",
    max500: "",
    max750: "",
    max1000: "",
    estimate_total_price_av: "",
    estimate_total_price_min: "",
    estimate_total_price_max: "",
  });
  const { trackPageView } = useMatomo();
  useEffect(() => {
    fetchAdvancePrice(
      getSubmitObj(addrData, data, mapCenter),
      callBackMethod,
      setStatus
    );
  }, [mapCenter]);

  const callBackMethod = (returnObj) => {
    setAdvanceEstimate({ ...returnObj }.data);
    setCounts({ ...returnObj }.count);
    priceHandler(returnObj);
  };

  const priceHandler = (result) => {
    const thisPrice = { ...price };
    setQueryTimeHandler({ ...result }.estimateCount);
    // if (result["min750"]) {
    const priceKey = Object.keys(price);
    priceKey.map((item) => {
      thisPrice[item] = result[item];
      return null;
    });
    thisPrice.estimate_total_price_av =
      Number(data.B_AREA_P) * Number(result.av750);
    thisPrice.estimate_total_price_min =
      Number(data.B_AREA_P) * Number(result.min750);
    thisPrice.estimate_total_price_max =
      Number(data.B_AREA_P) * Number(result.max750);
    setPrice(thisPrice);
    resultOpenHandler(true);
    // } else {
    //   setWarning(true);
    // }
    setLoading(false);
  };

  const closeWarning = () => {
    setWarning(false);
    setMapCenter("");
  };

  return (
    <>
      {resultOpen ? (
        <>
          <hr className="hr" name="hr" />
          <h4 className="h4">
            {price.av1000 === 0 ? "" : "所選位置附近實價登錄單價"}
          </h4>
          <div className="container">
            <PriceBlock price={price} />
            <MapContainer
              mapCenter={mapCenter}
              data={data}
              addrData={addrData}
              advanceEstimateData={advanceEstimateData}
              counts={counts}
            />
          </div>
          {trackPageView({
            documentTitle: "查看房屋估價結果", // optional
            //202208 Vulnerability Project Modify -- begin
            //因非必須資料且為高風險資料，因此mark
            // href: hashReplacer(window.document.location.href), // optional
            //202208 Vulnerability Project Modify -- end
          })}
        </>
      ) : Warning ? (
        <Warn closeWarning={closeWarning} />
      ) : (
        ""
      )}
    </>
  );
};

export default Container2;

const getBype = (data) => {
  switch (data.B_TYPE_INT) {
    case "6":
      return 1;
    case "1":
      return 2;

    case "7":
      return 3;

    case "2":
      return 4;

    case "3":
      return 5;

    case "4":
      return 6;
    default:
  }
};

const getSubmitObj = (addrData, data, mapCenter) => {
  return {
    county: addrData.county,
    district: addrData.district,
    addr: addrData.addr,
    estimateTotalPriceAv: 0,
    level: data.LEVEL ? data.LEVEL : 0,
    levelSelect: data.LEVEL_SELECT,
    bAreaP: data.B_AREA_P,
    bTypeInt: data.B_TYPE_INT,
    lat: mapCenter.lat(),
    lng: mapCenter.lng(),
    parking: data.PARKING,
    parkingQty: data.parking_qty,
    parkingP: data.parking_p,
    floors: data.FLOORS,
    bAge: data.B_AGE,
    bType: getBype(data),
  };
};
