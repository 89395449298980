import React, { useEffect } from "react";
import { scrollToDestMore } from "../../../Utils/Scroll/ScrollUtils";
import { formatFilter } from "../../../Utils/Filter/Filter";

const PriceBlock = ({ price }) => {
  useEffect(() => {
    scrollToDestMore("hr", 1300);
  }, [price]);
  // if (price.min1000 <= 0) return <></>;
  return (
    <>
      <div className="row">
        <div id="valuation" className="result">
          <div className="item-group">
            {Number(price.min500) > 0 && (
              <PriceUnit price={price} range={"500"} />
            )}
            {Number(price.min1000) > 0 && (
              <PriceUnit price={price} range={"1000"} />
            )}
          </div>
        </div>
      </div>
      <h4 className="h4">預估行情區間</h4>
      <b className="text f-xs brown">
        *本網站預估價格係採平均估計值，僅為參考價格，實際貸款成數與金額仍以本行實地勘估所作之估價報告書及最終審核結果為準。
      </b>

      <div className="row">
        <div id="valuation" className="result">
          <div className="item-group mg-10 c-row refont">
            <PriceUnit price={price} range="750" />
            <PriceUnit price={price} range="estimate_total_price" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceBlock;

const PriceUnit = ({ price, range }) => {
  const unit =
    range === "estimate_total_price" ? "cleanThousand" : "housePrice";
  return (
    <div className="item" style={getStyle(range)}>
      <div>
      <div className="h5 line" >
        <h5 className="h5 brown">{getBigTitle(range)}</h5>
        <h5 className="h5 brown">{getBigTitleNextLine(range)}</h5>
      </div>
        <p className="big mgt-10b-30">
        <span className="red priceSpan1">
          {formatFilter(getPrice(price, range, "min"), unit)}
        </span>
        <span className="priceSpan2">
        至
        </span>
        <span className="red priceSpan3">
          {formatFilter(getPrice(price, range, "max"), unit)}
        </span>
        </p>
        <p className="text f-sm mg-0 lineH-18">{getSmallTitme(range)}</p>
        <p className="text f-sm mg-0 lineH-18">
          <span className="red">
              {formatFilter(getPrice(price, range, "av"), unit)}
          </span>
        </p>
      </div>
    </div>
  );
};

const getPrice = (price, valueName, range) => {
  return valueName === "estimate_total_price"
    ? price[`${valueName}_${range}`]
    : price[`${range}${valueName}`];
};

const getBigTitle = (range) => {
  switch (range) {
    case "500":
      return "500公尺內實價登錄(萬)";
    case "750":
      return "預估每坪單價(萬)";
    case "1000":
      return "1000公尺內實價登錄(萬)";
    default:
      return "預估總價(萬)";
  }
};  

const getBigTitleNextLine = (range) => {
  switch (range) {
    case "750":
    case "estimate_total_price":
      return "不含車位";
    default:
      return "";
  }
};
const getSmallTitme = (range) => {
  switch (range) {
    case "estimate_total_price":
      return "平均總價";
    default:
      return "平均每坪單價";
  }
};

const getStyle = (range) => {
  switch (range) {
    case "750":
    case "estimate_total_price":
      return { background: "#F7F1DF" };
    default:
      return {};
  }
};
