import React, { useState, useEffect } from "react";
import { fetchPuw } from "../../MainSwitch/Fetch/fetchBackend";
import ReadingConfirm from "./ReadingConfirm";
import FillForm from "./FillForm";
import PhoneConfirm from "./PhoneConfirm";
import ThankWindow from "./ThankWindow";
const InfoAlert = ({ setClose, alertType, setDoubleClose }) => {
  const [puw, setPuw] = useState();
  useEffect(() => {
    fetchPuw(setPuw);
  }, []);
  if (!alertType) return <></>;
  if (!puw) return <></>;
  const confirmReading = puw.confirmReading;

  const closeHandler = () => {
    if (alertType === "thankWindow") {
      setDoubleClose();
    } else {
      setClose();
    }
  };

  return (
    <>
      <div className="mfp-bg mfp-with-zoom mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-with-zoom mfp-ready"
        tabIndex="-1"
        style={{ overflowX: "hidden", marginTop:"65px" }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div className="popup g-popup-group smscode-group mfp-with-zoom" style={{ marginTop:"65px" }}>
              {!(alertType === "thankWindow") && <div className="icon mg-25" />}
              {alertType === "readingConfirm" && (
                <ReadingConfirm confirmReading={confirmReading} />
              )}
              {alertType === "fillForm" && <FillForm />}
              {alertType === "phoneFormat" && <PhoneConfirm />}
              {alertType === "thankWindow" && <ThankWindow />}
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={(e) => {
                  e.preventDefault();
                  closeHandler();
                }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoAlert;
