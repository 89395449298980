import React from "react";
const Banner = ({photo, text}) => {
  return (
    <div className="g-banner ban">
      <div>
        <img src={photo} alt={text} />
        <div id="bannerFills"></div>
        <h2 className="h2 c">{text}</h2>
      </div>
    </div>
  );
};

export default Banner;
