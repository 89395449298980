import React from "react";

const Rent = () =>{
    return                 <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="42px"
    height="42px"
    viewBox="0 0 44 45"
    version="1.1"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-20" fill="#A17D58">
        <path
          d="M20.4552263,19.457037 L20.4552263,19.5653413 L15.7826707,19.5653413 C15.3502017,19.5653413 15,19.2151396 15,18.7826707 C15,18.3502017 15.3502017,18 15.7826707,18 L18.8916956,18 L18.8916956,14.890975 C18.8916956,14.4585061 19.2418973,14.1083044 19.6743663,14.1083044 C20.1068352,14.1083044 20.457037,14.4585061 20.457037,14.890975 L20.457037,19.457037 L20.4552263,19.457037 Z"
          id="Fill-1"
        />
        <path
          d="M40.4643145,11.4145338 L40.4643145,11.5653413 L33.7441626,11.5653413 C33.3116936,11.5653413 32.9614919,11.2151396 32.9614919,10.7826707 C32.9614919,10.3502017 33.3116936,10 33.7441626,10 L38.9614919,10 L38.9614919,4.78267067 C38.9614919,4.35020173 39.3116936,4 39.7441626,4 C40.1766315,4 40.5268332,4.35020173 40.5268332,4.78267067 L40.5268332,11.4145338 L40.4643145,11.4145338 Z"
          id="Fill-1"
        />
        <path
          d="M9.50282264,39.7946538 L9.50282264,39.9454614 L2.78267067,39.9454614 C2.35020173,39.9454614 2,39.5952596 2,39.1627907 C2,38.7303218 2.35020173,38.38012 2.78267067,38.38012 L8,38.38012 L8,33.1627907 C8,32.7303218 8.35020173,32.38012 8.78267067,32.38012 C9.21513961,32.38012 9.56534133,32.7303218 9.56534133,33.1627907 L9.56534133,39.7946538 L9.50282264,39.7946538 Z"
          id="Fill-1"
          transform="translate(5.782671, 36.162791) scale(-1, -1) translate(-5.782671, -36.162791) "
        />
        <path
          d="M17.1025141,12.530228 L27.5908931,12.530228 C27.8116718,12.530228 27.9967362,12.7320732 27.9967362,12.9563456 L27.9967362,33.3017227 L29.4188105,33.3017227 L29.4188105,12.9615212 C29.4188105,11.879838 28.6087477,11 27.5908931,11 L16.7501699,11 L17.1025141,12.530228 Z"
          id="Fill-1"
        />
        <path
          d="M12,12.5266349 L27.5926497,12.5266349 C27.8134283,12.5266349 27.9984928,12.7284801 27.9984928,12.9527525 L27.9984928,27.8954526 L24.0402386,31.7829514 L24.6678425,33.3017227 L29.4205671,28.4537145 L29.4205671,12.957928 C29.4205671,11.8762449 28.6105042,10.9964069 27.5926497,10.9964069 L12,10.9964069 L12,12.5266349 Z"
          id="Fill-1"
          transform="translate(20.710284, 22.149065) scale(-1, -1) translate(-20.710284, -22.149065) "
        />
        <path
          d="M38.4189047,10.7054587 C34.8541332,5.12979594 28.6087442,1.43333333 21.5,1.43333333 C10.417486,1.43333333 1.43333333,10.417486 1.43333333,21.5 C1.43333333,24.1770127 1.95753863,26.7315907 2.9089004,29.0666851 L2.8538506,29.0951748 L1.57086576,29.5823304 C0.523621921,26.0999377 0,23.4058276 0,21.5 C0,9.62587788 9.62587788,0 21.5,0 C29.4396356,0 36.3740964,4.3036651 40.0978458,10.7054587 L38.4189047,10.7054587 Z"
          id="Fill-1"
        />
        <path
          d="M41.4189047,25.7054587 C37.8541332,20.1297959 31.6087442,16.4333333 24.5,16.4333333 C13.417486,16.4333333 4.43333333,25.417486 4.43333333,36.5 C4.43333333,39.1770127 4.95753863,41.7315907 5.9089004,44.0666851 L5.8538506,44.0951748 L4.57086576,44.5823304 C3.52362192,41.0999377 3,38.4058276 3,36.5 C3,24.6258779 12.6258779,15 24.5,15 C32.4396356,15 39.3740964,19.3036651 43.0978458,25.7054587 L41.4189047,25.7054587 Z"
          id="Fill-1"
          transform="translate(23.048923, 29.791165) scale(-1, -1) translate(-23.048923, -29.791165) "
        />
      </g>
    </g>
  </svg>
}

const BuyHouse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
    >
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon-1"
          transform="translate(-303.000000, -442.000000)"
          fill="#A17D58"
        >
          <g id="Group-15" transform="translate(225.000000, 356.000000)">
            <g id="Group-2" transform="translate(30.000000, 67.000000)">
              <g id="Group" transform="translate(48.000000, 19.000000)">
                <path
                  d="M36.6354414,18.710131 L36.6310966,16.6362 C36.6310966,16.4290966 36.5311655,16.2350276 36.3588207,16.1148207 L20.9303379,5.22523448 C20.8217172,5.14557931 20.6884759,5.10937241 20.5610276,5.10937241 C20.432131,5.10937241 20.3003379,5.14992414 20.1873724,5.22523448 L4.76757931,16.2075103 C4.59957931,16.3248207 4.49964828,16.5203379 4.49964828,16.7303379 L4.49964828,18.7057862 L1.30475172,18.7057862 L1.28592414,14.6404759 L19.7065448,1.53937241 C20.1308897,1.23378621 20.8782,1.15268276 21.4198552,1.54226897 L39.8404759,14.6448207 L39.8448207,18.7057862 L36.6354414,18.710131 Z M41.1265448,14.3117172 C41.1265448,14.1017172 41.0251655,13.9105448 40.8571655,13.7903379 L22.1715103,0.495165517 C21.3126828,-0.120351724 19.9353724,-0.208696552 18.9577862,0.495165517 L0.269234483,13.7903379 C0.0997862069,13.9105448 -0.000144827586,14.1060621 -0.000144827586,14.3160621 L0.0244758621,19.3473724 C0.0244758621,19.7022 0.312682759,19.9918552 0.666062069,19.9918552 L5.14123448,19.9918552 C5.49461379,19.9918552 5.78282069,19.7022 5.78282069,19.3473724 L5.78282069,17.0590966 L20.5610276,6.54026897 L35.3450276,16.9707517 L35.3493724,19.3517172 C35.3493724,19.7050966 35.6404759,19.9962 35.9938552,19.9962 L40.4878552,19.9918552 C40.8412345,19.9918552 41.1308897,19.7022 41.1308897,19.3473724 L41.1265448,14.3117172 Z"
                  id="Fill-1"
                />
                <path
                  d="M35.0090276,0.5586 L29.3057172,0.571634483 C28.454131,0.571634483 27.7705448,1.23349655 27.7068207,2.06915172 L28.968269,2.96418621 L28.968269,2.19370345 C28.968269,2.00832414 29.1174414,1.85625517 29.3057172,1.85625517 L35.0090276,1.84466897 C35.1944069,1.84466897 35.3450276,1.99673793 35.3450276,2.18066897 L35.3450276,7.50308276 L36.6151655,8.4068069 C36.6195103,8.37784138 36.6310966,8.35032414 36.6310966,8.3228069 L36.6310966,2.18066897 C36.6310966,1.28563448 35.9040621,0.5586 35.0090276,0.5586"
                  id="Fill-1"
                />
                <path
                  d="M35.3456069,21.8230552 L35.3456069,39.4978138 C35.3456069,39.6180207 35.2818828,39.6991241 35.2456759,39.7382276 C35.1776069,39.8106414 35.0849172,39.8468483 34.9849862,39.8468483 L6.14546897,39.8468483 C5.94850345,39.8468483 5.7834,39.6774 5.7834,39.4891241 L5.7834,21.8230552 L4.51471034,21.8230552 L4.51471034,39.4847793 C4.51471034,40.3928483 5.2374,41.131469 6.14546897,41.131469 L34.9849862,41.131469 C35.4310552,41.131469 35.8452621,40.9591241 36.1580897,40.6419517 C36.4665724,40.3320207 36.6360207,39.9236069 36.6316759,39.493469 L36.6317574,21.8230552 L35.3456069,21.8230552 Z"
                  id="Fill-1"
                />
                <path
                  d="M18.3551586,24.9077379 C18.3551586,25.1278759 18.178469,25.3089103 17.9539862,25.3089103 L13.2557793,25.3089103 C13.0298483,25.3089103 12.8531586,25.1278759 12.8531586,24.9077379 L12.8531586,20.1993931 C12.8531586,19.9749103 13.0298483,19.7982207 13.2557793,19.7982207 L17.9539862,19.7982207 C18.178469,19.7982207 18.3551586,19.9749103 18.3551586,20.1993931 L18.3551586,24.9077379 Z M17.9539862,18.5136 L13.2557793,18.5136 C12.3230897,18.5136 11.5670897,19.2710483 11.5670897,20.1993931 L11.5670897,24.9077379 C11.5670897,25.8360828 12.3230897,26.5949793 13.2557793,26.5949793 L17.9539862,26.5949793 C18.8852276,26.5949793 19.6412276,25.8360828 19.6412276,24.9077379 L19.6412276,20.1993931 C19.6412276,19.2710483 18.8852276,18.5136 17.9539862,18.5136 Z"
                  id="Fill-1"
                />
                <path
                  d="M28.2767172,24.9077379 C28.2767172,25.1278759 28.1000276,25.3089103 27.8755448,25.3089103 L23.1758897,25.3089103 C22.9499586,25.3089103 22.773269,25.1278759 22.773269,24.9077379 L22.773269,20.1993931 C22.773269,19.9749103 22.9499586,19.7982207 23.1758897,19.7982207 L27.8755448,19.7982207 C28.1000276,19.7982207 28.2767172,19.9749103 28.2767172,20.1993931 L28.2767172,24.9077379 Z M27.8755448,18.5136 L23.1758897,18.5136 C22.2432,18.5136 21.4886483,19.2710483 21.4886483,20.1993931 L21.4886483,24.9077379 C21.4886483,25.8360828 22.2432,26.5949793 23.1758897,26.5949793 L27.8755448,26.5949793 C28.8067862,26.5949793 29.5627862,25.8360828 29.5627862,24.9077379 L29.5627862,20.1993931 C29.5627862,19.2710483 28.8067862,18.5136 27.8755448,18.5136 Z"
                  id="Fill-1"
                />
                <path
                  d="M18.3551586,34.3044414 C18.3551586,34.5289241 18.178469,34.7056138 17.9539862,34.7056138 L13.2557793,34.7056138 C13.0298483,34.7056138 12.8531586,34.5289241 12.8531586,34.3044414 L12.8531586,29.6076828 C12.8531586,29.3788552 13.0298483,29.2050621 13.2557793,29.2050621 L17.9539862,29.2050621 C18.178469,29.2050621 18.3551586,29.3788552 18.3551586,29.6076828 L18.3551586,34.3044414 Z M17.9539862,27.9204414 L13.2557793,27.9204414 C12.3230897,27.9204414 11.5670897,28.6764414 11.5670897,29.6076828 L11.5670897,34.3044414 C11.5670897,35.2327862 12.3230897,35.9902345 13.2557793,35.9902345 L17.9539862,35.9902345 C18.8852276,35.9902345 19.6412276,35.2327862 19.6412276,34.3044414 L19.6412276,29.6076828 C19.6412276,28.6764414 18.8852276,27.9204414 17.9539862,27.9204414 Z"
                  id="Fill-1"
                />
                <path
                  d="M28.2767172,34.3044414 C28.2767172,34.5289241 28.1000276,34.7056138 27.8755448,34.7056138 L23.1758897,34.7056138 C22.9499586,34.7056138 22.773269,34.5289241 22.773269,34.3044414 L22.773269,29.6076828 C22.773269,29.3788552 22.9499586,29.2050621 23.1758897,29.2050621 L27.8755448,29.2050621 C28.1000276,29.2050621 28.2767172,29.3788552 28.2767172,29.6076828 L28.2767172,34.3044414 Z M27.8755448,27.9204414 L23.1758897,27.9204414 C22.2432,27.9204414 21.4886483,28.6764414 21.4886483,29.6076828 L21.4886483,34.3044414 C21.4886483,35.2327862 22.2432,35.9902345 23.1758897,35.9902345 L27.8755448,35.9902345 C28.8067862,35.9902345 29.5627862,35.2327862 29.5627862,34.3044414 L29.5627862,29.6076828 C29.5627862,28.6764414 28.8067862,27.9204414 27.8755448,27.9204414 Z"
                  id="Fill-1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { BuyHouse, Rent };
