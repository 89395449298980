import { SpringBootServer } from "../../../StaticResource";
import { getItemFromSessionStorage } from "../../Utils/BrowserStorageUtils";
function sendSurvey(data) {
  const _token = getItemFromSessionStorage("_token");
  const url = `${SpringBootServer}/survey/add`;

  const submitObj = {
    ...data,
    _token: _token,
  };

  fetch(url, {
    method: "POST",
    body: JSON.stringify(submitObj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (res.OK) {
        console.log("傳遞成功");
      }
    })
    .catch((err) => {
      console.log(err);
      console.log("Error at sendSurvey");
    });
}

export { sendSurvey };
