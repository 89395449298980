import React from "react";

const BranchSelector = ({ contactData, BranchData, dataHandler }) => {
  if (BranchData === undefined) return <></>;
  const cityOption = Object.keys(BranchData).map((cityName, index) => {
    return <option key={index}>{cityName}</option>;
  });

  const districtOptions = Object.keys(
    BranchData[contactData.branch_county]
  ).map((district, index) => <option key={index}>{district}</option>);



  const branchOptions = Object.keys(
    { ...BranchData[contactData.branch_county] }[contactData.branch_district]
  ).map((district, index) => <option key={index}>{district}</option>);

  return (
    <div className="col-md-12" style={{ marginTop: "20px" }}>
      <div className="form-row">
        <h4>方便往來分行</h4>
        <select
          name="branch_county"
          id=""
          className="onethird"
          value={contactData.branch_county}
          onChange={(e) => {
            dataHandler(e);
          }}
          style={
            contactData.branch_county === "不選擇城市" ? {} : { color: "black" }
          }
        >
          {cityOption}
        </select>
        <select
          name="branch_district"
          id=""
          className="onethird"
          value={contactData.branch_district}
          onChange={(e) => {
            dataHandler(e);
          }}
          style={
            contactData.branch_district === "不選擇區域"
              ? {}
              : { color: "black" }
          }
        >
          {districtOptions}
        </select>
        <select
          name="branch_name"
          id=""
          className="onethird"
          value={contactData.branch_name}
          onChange={(e) => {
            dataHandler(e);
          }}
          style={{ color: "black" }}
        >
          {branchOptions}
        </select>
        <br/>
        <div style={{color:"#A17D59"}}>地址: {getAddr(BranchData, contactData)}</div>
      </div>
    </div>
  );
};

export default BranchSelector;


const getAddr = (database, contactData)=>{
  const allDistrictObj = database[contactData.branch_county];
  const allBranchObj = allDistrictObj[contactData.branch_district];

  return  allBranchObj[contactData.branch_name]
}