import React, { useState, useEffect } from "react";
import Verify from "./Verify";
import Validation from "./Validation";

const Container = ({ status, setStatus, setToken }) => {
  const [data, setData] = useState({
    type: "SMS",
    phone: "",
    email: "",
    isValidAlert: false,
  });
  return (
    <>
      {status === "1" && (
        <Verify
          setStatus={setStatus}
          data={data}
          setData={setData}
          setToken={setToken}
        />
      )}
      {status === "2" && <Validation setStatus={setStatus} data={data} />}
    </>
  );
};

export default Container;
